import { actionsNotifications } from 'src/app/providers/NotificationsProvider/_BLL/notifications/slice';
import { CriticalErrorModalContent } from 'src/app/providers/NotificationsProvider/ui/CriticalErrorModalContent/CriticalErrorModalContent';
import { Notifications } from 'src/app/providers/NotificationsProvider/ui/notifications/Notification/Notifications';
import { useActions, useAppSelector } from 'src/app/redux/createAction';
import { Modal } from 'src/shared/ui/_modals/Modal';

export const NotificationsProvider = () => {
	// * Selectors
	const { error, notifications } = useAppSelector(state => state.notifications);

	// * Actions
	const { clearError } = useActions(actionsNotifications);

	// * Render
	return (
		<>
			<Modal
				isOpen={!!error}
				onClose={clearError}
			>
				<CriticalErrorModalContent error={error} />
			</Modal>
			<Notifications notifications={notifications} />
		</>
	);
};
