import { MetaEntity, PriorityTag, SingleMenuItem, StatusType } from '../types/types';
import { Company } from '../../company/types/types';
import { SinglePerson } from '../../people/types';

export const generateMenu = (companyMeta: MetaEntity, currentCompany: Company, people: SinglePerson[]) => {
	return companyMeta.tabs.map(tab => {
		// Initial menuItem
		const menuItem: SingleMenuItem = {
			name: tab.name,
			filled: 0,
			unknown: 0,
			totalValue: 0,
			priority: {
				Mandatory: {
					filled: 0,
					unknown: 0,
					total: 0,
				},
				'Highest Priority': {
					filled: 0,
					unknown: 0,
					total: 0,
				},
				'Second Highest Priority': {
					filled: 0,
					unknown: 0,
					total: 0,
				},
				'Third Highest Priority': {
					filled: 0,
					unknown: 0,
					total: 0,
				},
				'Lowest Priority': {
					filled: 0,
					unknown: 0,
					total: 0,
				},
			},
		};

		if (tab.name === 'Cover') {
			// ! Cover priority is not needed
			menuItem.filled = null;
			menuItem.unknown = null;
			menuItem.totalValue = null;

			// ! People do not have priority and unknown value.
		} else if (tab.name === 'Executives' || tab.name === 'Board of Directors') {
			menuItem.unknown = 0;
			menuItem.totalValue = null;

			if (tab.name === 'Executives') {
				menuItem.filled = people.reduce((peopleTotal: number, person: SinglePerson) => {
					const roleType = person.properties.roleType.dataPoint.value;
					return roleType === '1' || roleType === '3' ? peopleTotal + 1 : peopleTotal;
				}, 0);
			}

			if (tab.name === 'Board of Directors') {
				menuItem.filled = people.reduce((peopleTotal: number, person: SinglePerson) => {
					const roleType = person.properties.roleType.dataPoint.value;
					return roleType === '2' || roleType === '3' ? peopleTotal + 1 : peopleTotal;
				}, 0);
			}
		} else {
			const properties = tab.groups.map(group => group.properties).flat();

			// @ts-ignore
			const totalPropertyStatuses = properties.map(property => currentCompany.properties[property.propertyName][property.dataPoints[0].internalName].status);

			// calculate value
			menuItem.filled = totalPropertyStatuses.reduce(
				(completedDataPoints: number, status: StatusType) => (status === StatusType.FILLED ? completedDataPoints + 1 : completedDataPoints),
				0,
			);
			// calculate unknownValue
			menuItem.unknown = totalPropertyStatuses.reduce(
				(completedDataPoints: number, status: StatusType) => (status === StatusType.UNKNOWN ? completedDataPoints + 1 : completedDataPoints),
				0,
			);

			// calculate totalValue
			menuItem.totalValue = properties.length;

			// calculate priority`s total & filled
			properties.forEach(property => {
				// @ts-ignore
				const propertyStatus = currentCompany.properties[property.propertyName][property.dataPoints[0].internalName].status;
				property.tags?.forEach(tag => {
					const fixedTag = tag.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) as PriorityTag;

					if (menuItem.priority !== null) {
						menuItem.priority[fixedTag] = {
							filled: propertyStatus === StatusType.FILLED ? menuItem.priority[fixedTag].filled + 1 : menuItem.priority[fixedTag].filled,
							unknown: propertyStatus === StatusType.UNKNOWN ? menuItem.priority[fixedTag].unknown + 1 : menuItem.priority[fixedTag].unknown,
							total: menuItem.priority[fixedTag].total + 1,
						};
					}
				});
			});
		}

		return menuItem;
	});
};
