import { createColumnHelper } from '@tanstack/react-table';

const splitCamelCase = (str: string) => {
	return str.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
};

const createHeader = (str: string) => {
	return splitCamelCase(str)
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
};

export const generateColumns = (searchResults: any) => {
	const accessors = searchResults ? Object.keys(searchResults[0]) : [];
	const columnHelper = createColumnHelper<any>();

	return accessors.map((accessor: string) => {
		return columnHelper.accessor(accessor, {
			header: createHeader(accessor),
		});
	});
};
