import cn from 'classnames';
import { FC } from 'react';
import { useAppSelector } from 'src/app/redux/createAction';
import { ReportingPeriod } from '../ReportingPeriods/ReportingPeriods';
import s from './DiffInfo.module.scss';
import { NO_COMPANIES_SELECTED_MESSAGE, NO_CONFLICTS_MESSAGE } from './consts';
import { TargetIcon } from '../../../SilverMode/TargetIcon/TargetIcon';
import { SourceIcon } from '../../../SilverMode/SourceIcon/SourceIcon';
import { Tooltip } from 'src/shared/ui/_tooltips/Tooltip';
import { CompanyBaseInfo } from 'src/pages/MainPage/_BLL/main_search/types';

const getGDPR = (companyInfo: CompanyBaseInfo | null) => {
	return (
		companyInfo?.isSubjectToGDPR && (
			<Tooltip
				className={s.tooltip}
				title="Company is subject to regulations could affect merge process"
			>
				<strong style={{ color: 'red' }}>GDPR</strong>
			</Tooltip>
		)
	);
};

export const DiffInfo: FC = () => {
	// * Selectors
	const companyConflicts = useAppSelector(state => state.mergeToolDataPoints.companyConflicts);
	const peopleConflicts = useAppSelector(state => state.mergeToolDataPoints.peopleConflicts);
	const sourceCompany = useAppSelector(state => state.mergeToolCompanySearch.sourceCompany);
	const targetCompany = useAppSelector(state => state.mergeToolCompanySearch.targetCompany);

	const companyConflictsNumber = companyConflicts?.length ?? null;
	const peopleConflictsNumber = peopleConflicts?.filter(conflict => conflict.conflicts.length > 0).length ?? null;

	// * Render
	return (
		<div className={s.container}>
			<div>
				<div className={s.companyInfo}>
					<span className={cn(s.companyInfo__caption, s.companyInfo__caption_conflicts)}>Company conflicts:</span>
					<span>{companyConflictsNumber === null ? NO_CONFLICTS_MESSAGE : companyConflictsNumber}</span>
				</div>

				<div className={s.companyInfo}>
					<span className={cn(s.companyInfo__caption, s.companyInfo__caption_conflicts)}>People conflicts:</span>
					<span>{peopleConflictsNumber === null ? NO_CONFLICTS_MESSAGE : peopleConflictsNumber}</span>
				</div>
			</div>

			<div>
				<div className={s.companyInfo}>
					<SourceIcon />
					<span className={cn(s.companyInfo__caption, s.companyInfo__caption_source)}>Source:</span>
					{sourceCompany ? (
						<span>
							<b>{sourceCompany.id} </b>
							<a
								href={`dashboard/${sourceCompany.id}/0`}
								target="_blank"
								rel="noreferrer"
							>
								{sourceCompany.name}
							</a>
						</span>
					) : (
						<span>{NO_COMPANIES_SELECTED_MESSAGE}</span>
					)}
				</div>

				<div className={s.companyInfo}>
					<TargetIcon />
					<span className={cn(s.companyInfo__caption, s.companyInfo__caption_source)}>Target:</span>
					{targetCompany ? (
						<span>
							<b>{targetCompany.id} </b>
							<a
								href={`dashboard/${targetCompany.id}/0`}
								target="_blank"
								rel="noreferrer"
							>
								{targetCompany.name}
							</a>
						</span>
					) : (
						<span>{NO_COMPANIES_SELECTED_MESSAGE}</span>
					)}
				</div>
			</div>

			<div className={s.column}>
				<ReportingPeriod type="source" />
				<ReportingPeriod type="target" />
			</div>

			<div className={s.column}>
				{getGDPR(sourceCompany)}
				{getGDPR(targetCompany)}
			</div>
		</div>
	);
};
