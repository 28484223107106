import { AnyAction, PayloadAction, ThunkDispatch } from "@reduxjs/toolkit"
import { actionsNotifications } from "src/app/providers/NotificationsProvider/_BLL/notifications/slice"
import { wait } from "src/shared/lib/async"

export const handleLongQueryRES = async (res: any, dispatch: ThunkDispatch<unknown, unknown, AnyAction>, setQueryId: (payload: number | null) => PayloadAction<number | null>, paramsModify: () => void, message: string) => {
	if (res?.queryId) {
		paramsModify()
		dispatch(setQueryId(res.queryId))
		await wait(2000)
	}

	if (res.status === 'Finished') {
		dispatch(setQueryId(null))
		return res
	} else if (res.status === 'Cancelled') {
		dispatch(setQueryId(null))
		dispatch(actionsNotifications.addNotification({
			type: 'info',
			message
		}));
	}
}