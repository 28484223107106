import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Arrow } from '../_buttons/Arrow';
import s from './BreadCrumbs.module.scss';
import { keyToTitle } from 'src/shared/lib/string';

interface Props {
	crumbs: string[];
}

export const BreadCrumbs: FC<Props> = props => {
	const {
		crumbs, //
	} = props;

	const navigate = useNavigate();

	// * Render
	return (
		<div className={s.container}>
			{crumbs.map((crumb: string, index: number) => (
				<div
					key={crumb}
					className={s.inner}
				>
					<span
						key={`/${crumb}`}
						className={s.link}
						onClick={() => navigate(`/${crumb}`)}
					>
						{keyToTitle(crumb)}
					</span>
					{index + 1 < crumbs.length && <Arrow direction="right" />}
				</div>
			))}
		</div>
	);
};
