import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormValues } from 'src/pages/MergeToolPage/_BLL/data_points/types';
import { ButtonSecondary } from 'src/shared/ui/_buttons/ButtonSecondary';
import s from './MoveCopyButtons.module.scss';

export const MoveCopyButtons: FC = () => {
	const { watch, setValue } = useFormContext<FormValues>();

	const formValues = watch();

	const bulkMoveSet = (move: boolean) => {
		Object.entries(formValues).forEach(([key, value]) => {
			const firstValue = value.dataPoints[0];

			if (!firstValue.hasUniqueValues && firstValue.isEditable) {
				setValue(`${key}.formMeta.move`, move);
			}
		});
	};

	// * Render
	return (
		<div className={s.container}>
			<ButtonSecondary onClick={() => bulkMoveSet(true)}>Move company</ButtonSecondary>
			<ButtonSecondary onClick={() => bulkMoveSet(false)}>Copy company</ButtonSecondary>
		</div>
	);
};
