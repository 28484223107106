import { List } from 'src/pages/QcDashboardPage/_BLL/types';
import { firstChar2UpperCase } from 'src/shared/lib/string';

export const getColumnNames = (list: List): { [key: string]: string } => {
	return Object.keys(list).reduce((acc, value) => {
		return {
			...acc,
			[value]: firstChar2UpperCase(value),
		};
	}, {});
};
