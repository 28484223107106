import { Select as AntSelect, SelectProps } from 'antd';
import { FC, useId } from 'react';
import { Tooltip } from '../../_tooltips/Tooltip';
import { InputWrapper } from '../_common/InputWrapper';
import s from './Select.module.scss';

interface Props extends SelectProps<any, any> {
	label?: string;
	error?: string;
}

export const Select: FC<Props> = props => {
	const {
		label, //
		error,
		status,
		className,
		value,
		...rest
	} = props;

	const id = useId();

	// * Render
	return (
		<InputWrapper
			label={label}
			error={error}
			className={className}
			id={id}
		>
			<AntSelect
				{...rest}
				className={s.input}
				value={value}
				style={{ width: '100%' }}
				id={id}
				status={error ? 'error' : status}
				popupMatchSelectWidth={false}
				maxTagTextLength={Array.isArray(value) && value.length > 1 ? 6 : 12}
				maxTagPlaceholder={omittedValues => (
					<Tooltip
						title={
							<ul>
								{omittedValues.map(({ label }) => (
									<li key={String(label)}>{String(label)}</li>
								))}
							</ul>
						}
					>
						+ {omittedValues.length} ...
					</Tooltip>
				)}
			/>
		</InputWrapper>
	);
};
