import { Tabs as AntTabs } from 'antd';
import { ComponentPropsWithoutRef, PropsWithChildren } from 'react';

interface Props<T> extends ComponentPropsWithoutRef<'div'> {
	selectedTab: T;
	tabs: T[];
	selectTab: (tab: T) => void;
}

export const Tabs = <T extends string>(props: PropsWithChildren<Props<T>>) => {
	const {
		selectedTab, //
		tabs,
		selectTab,
		className,
	} = props;

	const items = tabs.map(item => ({ label: item, key: item }));

	// * Render
	return (
		<AntTabs
			className={className}
			defaultActiveKey={selectedTab}
			items={items}
			onChange={selectTab}
		/>
	);
};
