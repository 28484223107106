import { FC } from 'react';
import s from './ErrorMessage.module.scss';

interface Props {
	errorMessage: string;
}

export const ErrorMessage: FC<Props> = props => {
	const { errorMessage } = props;

	return <div className={s.message}>{errorMessage}</div>;
};
