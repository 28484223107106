import { ActionCreatorsMapObject, bindActionCreators } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { AppDispatch } from './store';
import { RootState } from './rootReducer';

export const createAction = <T extends string, P>(type: T, payload?: P): Action<T, P> => {
	return {type, payload};
};

export const useActions = <T extends ActionCreatorsMapObject>(actions: T) => {
	const dispatch = useAppDispatch();

	return useMemo(() => {
		return bindActionCreators(actions, dispatch);
	}, [actions, dispatch]);
};

export const useAppDispatch = () => {
	return useDispatch<AppDispatch>();
};

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Types
export type Action<T, P> = {
	type: T
	payload?: P
}
