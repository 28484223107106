import { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { actionsKPI } from 'src/pages/MainPage/_BLL/kpi/slice';
import { UrlKPI } from 'src/shared/api/types';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import s from './TimePeriodPicker.module.scss';

interface Props {
	partUrl: UrlKPI;
	dates: [string | null, string | null];
}

export const TimePeriodPicker: FC<Props> = props => {
	const {
		partUrl, //
		dates,
	} = props;

	const [showPicker, setShowPicker] = useState(false);

	// * Actions
	const dispatch = useDispatch();
	const { updatePart } = actionsKPI;

	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
	const [startDate, endDate] = dateRange;

	const onPeriodRangeChange = (dates: [Date | null, Date | null]) => {
		setDateRange(dates);
	};

	useEffect(() => {
		startDate &&
			endDate &&
			dispatch(
				updatePart({
					url: partUrl,
					startDate,
					endDate,
					dates: dateRange.map(date => date?.toLocaleDateString('en-US')) as [string, string],
				}),
			);
	}, [startDate, endDate]);

	return (
		<>
			{showPicker ? (
				<DatePicker
					selected={startDate}
					onChange={onPeriodRangeChange}
					startDate={startDate}
					endDate={endDate}
					selectsRange
					inline
				/>
			) : (
				<ButtonPrimary
					className={s.button}
					onClick={() => setShowPicker(true)}
				>
					Dates: {dates[0] ?? ''} - {dates[1] ?? ''}
				</ButtonPrimary>
			)}
		</>
	);
};
