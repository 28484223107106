import _ from 'lodash';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { DataPoint, FormValues } from 'src/pages/MergeToolPage/_BLL/data_points/types';
import { sortForCompare } from 'src/shared/lib/sorting';

// TODO: Convert to hook. formValues / setValue
export const onValueClick = (propertyName: string, dataPoints: DataPoint[], allDataPoints: DataPoint[][], formValues: FormValues, setValue: UseFormSetValue<FormValues>) => {
	// ! Exception: legalName and headerLegalName should be the same. if user changes legalName headerLegalName should be changed as well and vice versa.

	if (propertyName === 'headerLegalName' || propertyName === 'legalName') {
		setValue('headerLegalName.dataPoints', dataPoints);
		setValue('legalName.dataPoints', dataPoints);
	} else if (dataPoints[0].allowsMany) {
		const sourceDataPoints = allDataPoints && allDataPoints[0];
		const targetDataPoints = allDataPoints && allDataPoints[1];

		const currentDataPoints = formValues[propertyName].dataPoints;
		const thisDataPoints = dataPoints;
		const otherDataPoints = allDataPoints?.filter(dataPoints => !!dataPoints.find((dataPoint, index) => dataPoint.value !== thisDataPoints[index].value))[0];
		const mixedDataPoints = targetDataPoints?.map((targetDataPoint, index) => ({
			// ? What input kind should I use? From target for now, then it should be selectable.
			...targetDataPoint,
			value: (() => {
				return _.uniq(((targetDataPoint.value ?? '') + ';' + (sourceDataPoints[index].value ?? '')).split(';'))
					.filter(item => item != '')
					.join(';');
			})(),
		}));

		const currentValues = currentDataPoints.map(dataPoint => dataPoint.value && sortForCompare(dataPoint.value.split(';')).join(';'));
		const otherValues = otherDataPoints?.map(dataPoint => dataPoint.value && sortForCompare(dataPoint.value.split(';')).join(';'));
		const mixedValues = mixedDataPoints?.map(dataPoint => dataPoint.value && sortForCompare(dataPoint.value.split(';')).join(';'));

		switch (JSON.stringify(currentValues)) {
			case JSON.stringify(mixedValues):
				setValue(`${propertyName}.dataPoints`, otherDataPoints);
				break;
			case JSON.stringify(otherValues):
				setValue(`${propertyName}.dataPoints`, mixedDataPoints);
				break;
			default:
				setValue(`${propertyName}.dataPoints`, thisDataPoints);
		}
	} else {
		setValue(`${propertyName}.dataPoints`, dataPoints);
	}
};
