import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rtkApiRequest } from 'src/shared/api/api';
import { ResponseStatus } from 'src/shared/api/types';
import { UpdateToGold_RES } from './types_RES';
import { actionsNotifications } from 'src/app/providers/NotificationsProvider/_BLL/notifications/slice';
import { wait } from 'src/shared/lib/async';

const NAME = UrlAPI.toGold;

// * Thunks
const uploadToGold = createAsyncThunk(`${NAME}/uploadToGold`, async (args: { companyId: string; reportingPeriodId: number; showSuccessNotification?: boolean }, thunkAPI) => {
	const { dispatch } = thunkAPI;
	const { companyId, reportingPeriodId, showSuccessNotification } = args;

	let status: ResponseStatus | null = null;

	const params = {
		id: companyId,
		reporting_period_id: reportingPeriodId,
	};

	do {
		const res: UpdateToGold_RES = await rtkApiRequest.rtkGETRequest<UpdateToGold_RES>({
			url: NAME,
			params,
			thunkAPI,
		});

		status = res.status;

		if (res?.queryId) {
			Object.assign(params, { query_id: res.queryId });
			dispatch(actionsUploadToGold.setUploadQueryId(res.queryId));
			await wait(2000);
		}

		if (res.status === 'Finished') {
			dispatch(actionsUploadToGold.setUploadQueryId(null));
		} else if (res.status === 'Cancelled') {
			dispatch(actionsUploadToGold.setUploadQueryId(null));
			dispatch(
				actionsNotifications.addNotification({
					type: 'info',
					message: 'Company uploading cancelled',
				}),
			);
		}
	} while (status === 'InProgress');

	return {
		showSuccessNotification,
		companyId,
	};
});

const cancelUploadToGold = createAsyncThunk(`${NAME}/cancelUploadToGold`, async (params: { query_id: number }, thunkAPI) => {
	return rtkApiRequest.rtkPOSTRequest({
		url: `${UrlAPI.company}/cancel`,
		params,
		thunkAPI,
	});
});

interface State {
	uploadedCompaniesIds: string[] | null;
	uploadQueryId: number | null;
	status: RequestStatus;
}

export const initialState: State = {
	uploadedCompaniesIds: null,
	uploadQueryId: null,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		closeModal: state => {
			state.uploadedCompaniesIds = null;
		},
		setUploadQueryId: (state, action: PayloadAction<number | null>) => {
			state.uploadQueryId = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(uploadToGold.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(uploadToGold.fulfilled, (state, action) => {
			const { showSuccessNotification, companyId } = action.payload;

			state.status = RequestStatus.still;

			if (showSuccessNotification) {
				state.uploadedCompaniesIds = state.uploadedCompaniesIds !== null ? [...state.uploadedCompaniesIds, companyId] : [companyId];
			}
		});
		builder.addCase(uploadToGold.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(cancelUploadToGold.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(cancelUploadToGold.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(cancelUploadToGold.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsUploadToGold = {
	...slice.actions,
	uploadToGold,
	cancelUploadToGold,
};
