import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { RouterPath } from 'src/app/router/AppRouter';
import { DashboardDump } from '../DashboardDump';
import { MergeDump } from '../MergeDump';
import s from './CompanyDump.module.scss';

export const CompanyDump: FC = () => {
	const location = useLocation();

	const isDashboard = location.pathname.includes(RouterPath.dashboard);
	const isMergeTool = location.pathname.includes(RouterPath.merge);

	if (isDashboard) {
		return (
			<div className={s.container}>
				<DashboardDump />
			</div>
		);
	} else if (isMergeTool) {
		return (
			<div className={s.container}>
				<MergeDump />
			</div>
		);
	} else {
		return null;
	}
};
