import { Select, SelectProps } from 'antd';
import { FC, useId } from 'react';
import { InputWrapper } from '../_common/InputWrapper';
import s from './SelectDictionary.module.scss';

export interface Option {
	id: number | string | null | undefined; // id is an actual value of the select.
	name: string; // name for display.
}

interface Props extends SelectProps {
	label?: string;
	error?: string;
	options: Option[];
}

const { Option } = Select;

/**
 * Select with dictionary, see TreeSelectDictionary for another version.
 */
export const SelectDictionary: FC<Props> = props => {
	const {
		options, //
		label, //
		error,
		status,
		className,
		...selectProps
	} = props;

	const id = useId();

	// * Render
	return (
		<InputWrapper
			label={label}
			error={error}
			className={className}
			id={id}
		>
			<div>
				<Select
					{...selectProps}
					className={s.input}
					id={id}
					status={error ? 'error' : status}
					options={options.map(option => ({ label: option.name, value: option.id }))}
					popupMatchSelectWidth={false}
				/>
			</div>
		</InputWrapper>
	);
};
