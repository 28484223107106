export const extractBetweenX = (str: string): string[] => {
	return str
		.split('x')
		.filter(part => part.length > 0 && part.length != str.length)
		.map(part => part.trim());
};

/*
	example: 
	original string: 'Silver x 3222391 x 1'
	result: ['Silver', '3222391 ', '1']
*/
