import cn from 'classnames';
import { ComponentPropsWithoutRef, FC } from 'react';
import ReactDOM from 'react-dom';
import s from './Backdrop.module.scss';

interface Props extends ComponentPropsWithoutRef<'div'> {
	isActive: boolean;
	onClick: any;
	increaseZIndexBy: number;
}

export const Backdrop: FC<Props> = props => {
	const {
		isActive, //
		onClick,
		increaseZIndexBy,
		children,
	} = props;

	const content = (
		<div
			className={cn(s.container, isActive && s.container_active)}
			style={{
				zIndex: 1001 + increaseZIndexBy,
			}}
			onClick={onClick}
		>
			{children}
		</div>
	);
	const container = document.getElementById('backdrop-portal') as HTMLElement;

	return ReactDOM.createPortal(content, container);
};
