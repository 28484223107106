import cn from 'classnames';
import { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import s from './PreloaderSquared.module.scss';

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const PreloaderSquared: FC<Props> = props => {
	const { className, ...divProps } = props;

	return (
		<div
			{...divProps}
			className={cn(s.container, className)}
		>
			<div className={s.preloader} />
		</div>
	);
};
