import { useAppSelector } from 'src/app/redux/createAction';
import { RequestStatus } from 'src/shared/api/types';

export const useDashboardStatus = () => {
	const mainStatus = useAppSelector(state => state.dashboardMain.status);
	const companyStatus = useAppSelector(state => state.dashboardCompany.status);
	const peopleStatus = useAppSelector(state => state.dashboardPeople.status);
	const goldStatus = useAppSelector(state => state.uploadToGold.status);
	const metaDictionaries = useAppSelector(state => state.metaDictionaries.status);

	const allStatuses = [mainStatus, companyStatus, peopleStatus, goldStatus, metaDictionaries];

	return allStatuses.includes(RequestStatus.loading) ? RequestStatus.loading : RequestStatus.still;
};
