import { Button, ButtonProps } from 'antd';
import { FC } from 'react';
import s from './ButtonPrimary.module.scss';
import cn from 'classnames';

export const ButtonPrimary: FC<ButtonProps> = props => {
	const { className, ...buttonProps } = props;

	// * Render
	return (
		<Button
			className={cn(s.container, className)}
			type="primary"
			{...buttonProps}
		/>
	);
};

/*
! HTML button type is htmlType in Ant design. default = 'button'
! loading true disables the button automatically.
*/
