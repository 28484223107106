import { FC, ReactNode, createContext, useContext } from 'react';
import { MetaEntity } from 'src/pages/DashboardPage/_BLL/dashboard/types/types';
import { Dictionaries } from '../redux/slices/metaDictionaries/types';

interface Context {
	companyMeta: MetaEntity;
	personMeta: MetaEntity;
	dictionaries: Dictionaries;
}

interface Props extends Context {
	children: ReactNode;
}

const MetaContext = createContext<Context | null>(null);

const MetaContextProvider: FC<Props> = props => {
	const {
		companyMeta, //
		personMeta,
		dictionaries,
		children,
	} = props;

	// * Render
	return (
		<MetaContext.Provider
			value={{
				companyMeta,
				personMeta,
				dictionaries,
			}}
		>
			{children}
		</MetaContext.Provider>
	);
};

const useMetaContext = () => {
	const context = useContext(MetaContext);

	if (!context) {
		throw new Error('useMetaContext must be used within MetaContextProvider');
	}

	return context;
};

export { MetaContextProvider, useMetaContext };
