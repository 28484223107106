import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'src/app/redux/createAction';
import { RouterPath } from 'src/app/router/AppRouter';
import { RequestStatus } from 'src/shared/api/types';
import { Preloader } from 'src/shared/ui/_loaders/Preloader';
import { MetaItemWithStats } from '../../types';
import s from './AllWidgets.module.scss';
import { DashboardWidget } from './DashboardWidget';

interface Props {
	search: string;
	metaItems: MetaItemWithStats[];
	namesURL: {
		reportingPeriod: string;
		region: string;
		industry: string;
		DM: string;
	};
}

export const AllWidgets: FC<Props> = props => {
	const {
		search,
		metaItems, //
		namesURL,
	} = props;

	// * Selectors
	const metaStatus = useAppSelector(state => state.qcDashboard.status.meta);
	const statsStatus = useAppSelector(state => state.qcDashboard.status.stats);
	const findWidget = useAppSelector(state => state.qcDashboard.widgetsStats);

	const isLoadingMeta = metaStatus === RequestStatus.loading;
	const isLoadingStatus = statsStatus === RequestStatus.loading;

	const isFailedMeta = metaStatus === RequestStatus.failed;
	const isFailedStatus = statsStatus === RequestStatus.failed;

	const isLoading = isLoadingMeta || isLoadingStatus;
	const isFailed = isFailedMeta || isFailedStatus;

	// * Render
	return (
		<div className={s.grid}>
			{metaItems.length > 0 ? (
				metaItems
					.filter(metaItem => metaItem.displayName.toLowerCase().includes(search.toLowerCase()))
					.map(({ name, displayName, companiesCount, peopleCount }) => (
						<Link
							key={name}
							className={s.link}
							to={{
								pathname: RouterPath.qcDashboard + RouterPath.qcWidget + `/${name}`,
								search: `?reportingPeriod=${namesURL.reportingPeriod}&region=${namesURL.region}&industry=${namesURL.industry}&DM=${namesURL.DM}`,
							}}
						>
							<DashboardWidget
								key={name}
								displayName={displayName}
								isLoading={!findWidget.find(element => element.result?.widgetName === name)}
								ExtraInfo={
									<span className={s.extraInfo}>
										{companiesCount > 0 && (
											<p>
												Companies: <b>{companiesCount}</b>
											</p>
										)}

										{peopleCount > 0 && (
											<p>
												People: <b>{peopleCount}</b>
											</p>
										)}
									</span>
								}
							/>
						</Link>
					))
			) : (
				<div>
					{isLoading && <Preloader size="large" />}
					{isFailed && <h4>No results. Please refresh the page or wait for a while.</h4>}
				</div>
			)}
		</div>
	);
};
