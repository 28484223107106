import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { actionsMergeToolDataPoints } from 'src/pages/MergeToolPage/_BLL/data_points/slice';
import { PersonMergeMeta } from 'src/pages/MergeToolPage/_BLL/silver_people/types';
import { PersonDataPoints } from 'src/pages/MergeToolPage/ui/SilverMode/PeopleMergeForm/PersonField/ui/PersonDataPoints/PersonDataPoints';
import CheckMarkSVG from 'src/shared/assets/svg/checkMark.svg';
import { Arrow } from 'src/shared/ui/_buttons/Arrow';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import { ButtonSecondary } from 'src/shared/ui/_buttons/ButtonSecondary';
import { Select } from 'src/shared/ui/_inputs/Select';
import { Modal } from 'src/shared/ui/_modals/Modal';
import { IndividualDatapointsMC } from '../IndividualDatapointsMC/IndividualDatapointsMC';
import s from './PersonField.module.scss';
import { RequestStatus } from 'src/shared/api/types';
import { InfoTooltip } from 'src/shared/ui/_tooltips/InfoTooltipAlt';

export const disabledButtonOption = ['Keep the person in target', 'Do not merge this person', 'Do nothing. The person exists only in target', 'Keep all target data points'];

interface Props {
	personMeta: PersonMergeMeta;
}

export const PersonField: FC<Props> = props => {
	const {
		personMeta, //
	} = props;

	const {
		formID, //
		name,
		conflictType,
		conflicts,
		options,
		acceptedBy,
		acceptedAtUTC,
		personIds,
	} = personMeta;

	// * Selectors
	const persons = useAppSelector(state => state.mergeToolDataPoints.peopleConflicts);
	const autoMergeErrors = useAppSelector(state => state.mergeToolDataPoints.autoMergeErrors);
	const status = useAppSelector(state => state.mergeToolDataPoints.status);

	const sourcePersonId = personIds[0];
	let error: { personId: number; error: string } | undefined;

	if (sourcePersonId) {
		error = autoMergeErrors.find(error => error.personId === sourcePersonId);
	}

	// * Actions
	const dispatch = useAppDispatch();
	const { mergePeople } = actionsMergeToolDataPoints;

	// * Form
	const fieldName = `${formID}.selectedOption`;

	const { control, setValue } = useFormContext();

	const onChange = (value: string) => {
		const option = options.find(option => option.optionName === value);
		setValue(fieldName, option);
	};

	// * Edit individual datapoints.
	const [modalActive, setModalActive] = useState(false);

	const toggleModal = () => setModalActive(!modalActive);

	// * Conditions
	const isAccepted = !!(acceptedBy && acceptedAtUTC);

	const [collapsedContent, setCollapsedContent] = useState(isAccepted);
	const toggleCollapsedContent = () => {
		setCollapsedContent(prevState => !prevState);
	};

	// * Apply action
	// TODO: Fix any.
	const onApplyActionClick = (fieldValue: any) => {
		if (persons) {
			dispatch(
				mergePeople({
					personsToMerge: [fieldValue.payload],
				}),
			);
		}
	};

	// * Render
	return (
		<>
			<Modal
				isOpen={modalActive}
				onClose={toggleModal}
			>
				<IndividualDatapointsMC personMergeMeta={personMeta} />
			</Modal>

			<Controller
				name={fieldName}
				control={control}
				render={({ field }) => (
					<div className={s.card}>
						<div
							className={s.header}
							onClick={toggleCollapsedContent}
						>
							<div className={s.titleWrapper}>
								<div className={s.title}>{name}</div>

								{isAccepted && (
									<InfoTooltip
										title={`${acceptedBy}\n${acceptedAtUTC}`}
										Icon={<CheckMarkSVG />}
									/>
								)}
							</div>

							<Arrow direction={collapsedContent ? 'up' : 'down'} />
						</div>

						{!collapsedContent && (
							<div className={s.content}>
								{/* Data points */}
								<PersonDataPoints
									conflictType={conflictType}
									conflicts={conflicts}
								/>

								{/*	Merge options */}
								<div className={s.buttons}>
									<Select
										label="Action"
										onChange={onChange}
										value={field.value.optionName}
										options={options.map(option => ({ label: option.optionName, value: option.optionName }))}
									/>

									<ButtonPrimary
										disabled={disabledButtonOption.includes(field.value.optionName)}
										loading={status === RequestStatus.loading}
										onClick={() => onApplyActionClick(field.value)}
									>
										Apply action
									</ButtonPrimary>
								</div>

								{conflictType === 'conflicts' && <ButtonSecondary onClick={toggleModal}>Individual merge</ButtonSecondary>}
							</div>
						)}
						<span className={s.error}>{error?.error}</span>
					</div>
				)}
			/>
		</>
	);
};
