import React, { useCallback, useEffect, useMemo } from 'react';
import s from './MainSearchResultsPage.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTable } from 'react-table';
import CircleA from 'src/shared/assets/svg/circleA.svg';
import ChildCompanySVG from 'src/shared/assets/svg/companyInheritance/child_company.svg';
import Star from 'src/shared/assets/svg/star.svg';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { BreadCrumbs } from 'src/shared/ui/BreadCrumbs';
import { Preloader } from 'src/shared/ui/_loaders/Preloader';
import { Pagination, PaginationI } from 'src/shared/ui/Pagination';
import { RequestStatus } from 'src/shared/api/types';
import { actionsMainSearchResults } from 'src/pages/MainSearchResultsPage/_BLL/slice';
import { PageDecorator } from 'src/app/decorators';

export interface LocationState {
	pagination: PaginationI;
	requestParams: string | (string | number)[];
}

export const MainSearchResultsPage = () => {
	// * Selectors
	const { items, pagination, status } = useAppSelector(state => state.mainSearchResults);
	const { search } = useAppSelector(state => state.mainSearch);

	// * Actions
	const dispatch = useAppDispatch();
	const { getMainSearchResults, setPagination, clearAll } = actionsMainSearchResults;

	// * History API
	const navigate = useNavigate();
	const location = useLocation();

	const locationState = location.state as LocationState;

	const defineSearch = (locationState?.requestParams as string) || search;

	const makeRequest = useCallback(
		(pagination: PaginationI) => {
			dispatch(setPagination({ pagination }));
			dispatch(
				getMainSearchResults({
					searchPrefix: defineSearch,
					pageSize: pagination.pageSize,
					pageIndex: pagination.pageIndex,
				}),
			);
		},
		[defineSearch, getMainSearchResults, setPagination],
	);

	// * initial requests
	useEffect(() => {
		makeRequest(pagination);
		return () => {
			dispatch(clearAll());
		};
	}, [makeRequest]);

	// * Table
	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
			},
			{
				Header: 'Original name',
				accessor: 'name',
			},
			{
				Header: 'Latin name',
				accessor: 'latinName',
			},
			{
				Header: 'Country',
				accessor: 'country',
			},
			{
				Header: 'City',
				accessor: 'city',
			},
			{
				Header: 'Priority',
				accessor: 'priority',
				Cell: ({ value }: any) => (value ? <Star /> : <></>),
			},
			{
				Header: 'Rating',
				accessor: 'hasAdvancedRating',
				Cell: ({ value }: any) => (value ? <CircleA /> : <></>),
			},
			{
				Header: 'Associated parent name',
				accessor: 'associatedParentName',
				Cell: ({ value }: any) =>
					value ? (
						<div className={s.iconCell}>
							<ChildCompanySVG /> {value}
						</div>
					) : (
						<></>
					),
			},
			{
				Header: 'Industry',
				accessor: 'industry',
			},
		],
		[],
	);

	const tableData = useMemo(() => items, [items]);

	const tableInstance = useTable<any>({
		columns,
		data: tableData ? tableData : [],
	});

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

	const handleClick = (e: any, id: string) => {
		if (e.shiftKey || e.ctrlKey) {
			window.open(`/dashboard/${id}/0`);
		} else {
			navigate(`/dashboard/${id}/0`);
		}
	};

	// * Render
	return (
		<PageDecorator>
			<div className={s.container}>
				<BreadCrumbs crumbs={['main']} />

				<div className={s.title}>List of companies</div>

				<div className={s.content}>
					<Pagination
						className={s.pagination}
						pageSize={pagination.pageSize}
						currentPage={pagination.pageIndex}
						pageCount={pagination.pageCount}
						changePage={makeRequest}
					/>

					{status === RequestStatus.loading ? (
						<div className={s.preloaderWrapper}>
							<Preloader />
						</div>
					) : (
						<div className={s.tableWrapper}>
							<table
								className={s.table}
								{...getTableProps()}
							>
								<thead>
									{headerGroups.map(group => (
										<tr {...group.getHeaderGroupProps()}>
											{group.headers.map(column => (
												<th {...column.getHeaderProps()}>{column.render('Header')}</th>
											))}
										</tr>
									))}
								</thead>

								<tbody {...getTableBodyProps()}>
									{rows.map(row => {
										prepareRow(row);
										return (
											<tr
												{...row.getRowProps()}
												onClick={e => handleClick(e, row.values.id)}
											>
												{row.cells.map(cell => (
													<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
												))}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>
		</PageDecorator>
	);
};
