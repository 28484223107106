export const getStickyStyles = (index: number, sticky?: string[]) => {
	if (sticky) {
		const stickyCell = sticky[index];

		if (stickyCell) {
			return {
				position: 'sticky' as const,
				left: sticky[index - 1] ?? '0',
				width: sticky[index],
			};
		} else {
			return undefined;
		}
	} else {
		return undefined;
	}
};
