import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { rtkApiRequest } from 'src/shared/api/api';
import { CompanyBaseInfo } from 'src/pages/MainPage/_BLL/main_search/types';

const NAME = 'mergetool/silver_conflicts';

const getCompanies = createAsyncThunk(`${NAME}/getCompanies`, async (arg: { search: string | null }, thunkAPI) => {
	const {search} = arg;
	const {signal} = thunkAPI;

	if (search !== '' && search !== null) {
		return await rtkApiRequest.rtkGETRequest<{ companies: CompanyBaseInfo[] }>({
			url: UrlAPI.companies,
			params: {
				search_prefix: encodeURIComponent(search),
				page_size: 10,
				page_index: 0
			},
			thunkAPI,
			signal
		});
	}
});

interface State {
	search: string | null;
	companies: CompanyBaseInfo[];
	sourceCompany: CompanyBaseInfo | null;
	targetCompany: CompanyBaseInfo | null;
	reportingPeriods: {
		source: number | null;
		target: number | null;
	}
	status: RequestStatus;
}

export const initialState: State = {
	search: null,
	companies: [],
	sourceCompany: null,
	targetCompany: null,
	reportingPeriods: {
		source: null,
		target: null,
	},
	status: RequestStatus.still
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		saveSearch: (state, action: PayloadAction<string | null>) => {
			state.search = action.payload;
		},
		storeSourceCompany: (state, action: PayloadAction<CompanyBaseInfo>) => {
			state.sourceCompany = action.payload;
		},
		storeTargetCompany: (state, action: PayloadAction<CompanyBaseInfo>) => {
			state.targetCompany = action.payload;
		},
		clearCompanies: state => {
			state.search = '';
			state.companies = [];
		},
		setPeriod: (state, action: PayloadAction<{type: 'source' | 'target', value: number | null}>) => {
			state.reportingPeriods[action.payload.type] = action.payload.value;
		}
	},
	extraReducers: builder => {
		builder.addCase(getCompanies.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getCompanies.fulfilled, (state, action) => {
			state.status = RequestStatus.still;

			if (action.payload?.companies) {
				state.companies = action.payload.companies;
			}
		});
		builder.addCase(getCompanies.rejected, state => {
			state.status = RequestStatus.failed;
		});
	}
});

export const mergeToolSilverConflicts = {
	...slice.actions,
	getCompanies
};
