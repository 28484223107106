import { FC, ReactNode } from 'react';
import { Preloader } from 'src/shared/ui/_loaders/Preloader';
import s from './DashboardWidget.module.scss';

interface Props {
	displayName: string;
	isLoading: boolean;
	ExtraInfo?: ReactNode;
}

export const DashboardWidget: FC<Props> = props => {
	const {
		displayName, //
		isLoading,
		ExtraInfo,
	} = props;

	// * Render
	return (
		<div className={s.container}>
			<h2 className={s.title}>{displayName}</h2>
			{isLoading ? (
				<Preloader
					style={{ color: 'white' }}
					size="large"
				/>
			) : (
				<>{ExtraInfo}</>
			)}
		</div>
	);
};
