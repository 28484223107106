import { localStorageName } from 'src/app/redux/localStorage';
import { RootState } from 'src/app/redux/rootReducer';
import { GASendApiCall } from 'src/shared/api/GoogleAnalytics';
import { rtkHandleResponse } from 'src/shared/api/rtkHandleResponse';
import { State } from 'src/pages/AuthPage/_BLL/slice';
import { isDevEnv } from '../lib/app';
import { FetchGET, FetchPOST, FetchPUT, FetchDEL, RequestUrlArgs } from './types';

// Prod - https://denominator-api-silver-prod.0rsk44vv9evf0.us-east-2.cs.amazonlightsail.com
// Dev - https://denominator-api-silver-dev.0rsk44vv9evf0.us-east-2.cs.amazonlightsail.com
const silverHost = process.env.REACT_APP_HOST || 'Error... in building REACT_APP_HOST';

const qcDEVHost = 'https://denominator-api-qcdashboard-dev.0rsk44vv9evf0.us-east-2.cs.amazonlightsail.com';
const qcPRODHost = 'https://denominator-api-qcdashboard-prod.0rsk44vv9evf0.us-east-2.cs.amazonlightsail.com';

const getAuth = (): State | null => {
	const state = localStorage.getItem(localStorageName);
	const parsed = state ? (JSON.parse(state) as RootState) : null;
	return parsed ? parsed.auth : null;
};

const getHeaders = (state: State | null, token?: string) => {
	const apiToken = token || (state && state.userData ? state.userData.token : '');

	return {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Request-Headers': '*',
		'x-api-token': apiToken,
	};
};

export const getRequestUrl = (args: RequestUrlArgs) => {
	const {
		url, //
		params,
		customHost,
	} = args;

	let host = silverHost;

	if (customHost) {
		if (customHost === 'qc') {
			host = isDevEnv ? qcDEVHost : qcPRODHost;
		}
	}

	return `${host}/${url}${params ? '?' + params : ''}`;
};

export const generateParams = (params?: object) => {
	return params
		? Object.entries(params)
				.map(([key, value]) => `${key}=${value}`)
				.join('&')
		: '';
};

const rtkGET = async (args: FetchGET) => {
	const { url, params, signal, customHost, token } = args;

	const auth = getAuth();
	if (auth) {
		await GASendApiCall('get', url, auth?.userData?.id);
	}

	return await fetch(getRequestUrl({ url, params: generateParams(params), customHost }), {
		method: 'GET',
		headers: getHeaders(auth, token),
		referrerPolicy: 'no-referrer',
		signal,
	});
};

const rtkPOST = async (args: FetchPOST) => {
	const { url, params, payload, customHost, signal } = args;

	const auth = getAuth();
	if (auth) {
		await GASendApiCall('post', url, auth?.userData?.id);
	}

	return await fetch(getRequestUrl({ url, params: generateParams(params), customHost }), {
		method: 'POST',
		mode: 'cors',
		headers: getHeaders(auth),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
		signal,
	});
};

const rtkPUT = async (args: FetchPUT) => {
	const { url, params, payload, customHost, signal } = args;

	const auth = getAuth();
	if (auth) {
		await GASendApiCall('put', url, auth?.userData?.id);
	}

	return await fetch(getRequestUrl({ url, params: generateParams(params), customHost }), {
		method: 'PUT',
		mode: 'cors',
		headers: getHeaders(auth),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
		signal,
	});
};

const rtkDEL = async (args: FetchDEL) => {
	const { url, params, customHost, signal } = args;

	const auth = getAuth();
	if (auth) {
		await GASendApiCall('delete', url, auth?.userData?.id);
	}

	return await fetch(getRequestUrl({ url, params: generateParams(params), customHost }), {
		method: 'DELETE',
		headers: getHeaders(auth),
		referrerPolicy: 'no-referrer',
		signal,
	});
};

// * RTK request handlers
const rtkGETRequest = async <RES>(args: FetchGET & { thunkAPI: any }) => {
	const { thunkAPI, ...getArgs } = args;

	const response = await rtkGET(getArgs);

	return await rtkHandleResponse<RES>({
		response,
		thunkAPI,
	});
};

const rtkGETRequestBLOB = async <RES>(args: FetchGET & { thunkAPI: any }) => {
	const { thunkAPI, ...getArgs } = args;

	const response = await rtkGET(getArgs);

	return await rtkHandleResponse<RES>({
		response,
		thunkAPI,
		type: 'blob',
	});
};

const rtkPOSTRequest = async <RES>(args: FetchPOST & { thunkAPI: any; displayNotifications?: boolean }) => {
	const { thunkAPI, displayNotifications, ...postArgs } = args;

	const response = await rtkPOST(postArgs);

	return await rtkHandleResponse<RES>({
		response,
		thunkAPI,
		displayNotifications,
	});
};

const rtkPUTRequest = async <RES>(args: FetchPUT & { thunkAPI: any }) => {
	const { thunkAPI, ...postArgs } = args;

	const response = await rtkPUT(postArgs);

	return await rtkHandleResponse<RES>({
		response,
		thunkAPI,
	});
};

const rtkDELRequest = async <RES>(args: FetchDEL & { thunkAPI: any }) => {
	const { thunkAPI, ...delArgs } = args;

	const response = await rtkDEL(delArgs);

	return await rtkHandleResponse<RES>({
		response,
		thunkAPI,
	});
};

// * Export
export const rtkApiRequest = {
	rtkGETRequest,
	rtkGETRequestBLOB,
	rtkPOSTRequest,
	rtkPUTRequest,
	rtkDELRequest,
};
