import { FC } from 'react';
import { useSortBy, useTable } from 'react-table';
import SortDownSVG from 'src/shared/assets/svg/tableSort/sort-down.svg';
import SortUpSVG from 'src/shared/assets/svg/tableSort/sort-up.svg';
import s from './SimpleTable.module.scss';

interface Column {
	Header: any;
	accessor: string;
}

interface Props {
	columns: Column[];
	data: any;
}

// TODO: Convert to 8 version.
export const SimpleTable: FC<Props> = props => {
	const {
		columns, //
		data,
	} = props;

	const tableInstance = useTable<any>(
		{
			columns: columns,
			data: data ? data : [],
		},
		useSortBy,
	);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

	const isLinkHeader = (id: string) => {
		if (id === 'silverLink' || id === 'goldLink') {
			return 'link';
		} else return '';
	};

	return (
		<table
			{...getTableProps()}
			className={s.table}
		>
			<thead>
				{headerGroups.map(group => (
					<tr {...group.getHeaderGroupProps()}>
						{group.headers.map(column => (
							<th
								className={isLinkHeader(column.id)}
								{...column.getHeaderProps(column.getSortByToggleProps())}
							>
								<span>{column.render('Header')}</span>
								<div>{column.isSorted ? column.isSortedDesc ? <SortDownSVG /> : <SortUpSVG /> : <SortUpSVG className="invisible" />}</div>
							</th>
						))}
					</tr>
				))}
			</thead>

			<tbody {...getTableBodyProps()}>
				{rows.map(row => {
					prepareRow(row);

					return (
						<tr {...row.getRowProps()}>
							{row.cells.map(cell => (
								<td
									{...cell.getCellProps([
										{
											// @ts-ignore
											style: cell.column.style,
										},
									])}
								>
									{cell.render('Cell')}
								</td>
							))}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};
