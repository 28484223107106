import { IPagination } from 'src/shared/ui/Pagination/types';

interface Args {
	reportingPeriodState: string;
	regionState: string;
	industryState: string;
	DMState: string;
	pagination: IPagination;
}

export const generateWidgetParams = (args: Args) => {
	const { reportingPeriodState, regionState, industryState, DMState, pagination } = args;

	return {
		reporting_period_id: reportingPeriodState,
		...(regionState && regionState !== 'null' && { country_id: regionState }),
		...(industryState && industryState !== 'null' && { industry_id: industryState }),
		...(DMState && DMState !== 'null' && { dm_search: DMState }),
		...(pagination.pageIndex && { page_index: pagination.pageIndex }),
		...(pagination.pageSize ? { page_size: pagination.pageSize } : { page_size: 10 }),
	};
};
