import cn from 'classnames';
import { FC } from 'react';
import { InfoTooltip } from 'src/shared/ui/_tooltips/InfoTooltipAlt';
import { PersonMergeMeta } from 'src/pages/MergeToolPage/_BLL/silver_people/types';
import { PersonField } from 'src/pages/MergeToolPage/ui/SilverMode/PeopleMergeForm/PersonField/ui/PersonField/PersonField';
import s from './ConflictTypeColumn.module.scss';
import { Select } from 'src/shared/ui/_inputs/Select';
import { useFormContext } from 'react-hook-form';

interface Props {
	personMergeMeta: PersonMergeMeta[];
}

export const ConflictTypeColumn: FC<Props> = props => {
	const {
		personMergeMeta, //
	} = props;

	// * Sorting
	const getAccepted = (personMeta: PersonMergeMeta) => {
		return !!(personMeta.acceptedBy && personMeta.acceptedAtUTC);
	};

	const restPeople = personMergeMeta.filter(personMeta => !getAccepted(personMeta));
	const acceptedPeopleMeta = personMergeMeta.filter(personMeta => getAccepted(personMeta));

	// * Multi assign
	const { setValue } = useFormContext();

	const multiAssignOptions = personMergeMeta[0].options;

	const onChange = (optionName: string) => {
		personMergeMeta.forEach(meta => {
			const option = meta.options.find(option => option.optionName === optionName);
			if (option) {
				setValue(`${meta.formID}.selectedOption`, option);
			}
		});
	};

	// * Render
	return (
		<div className={cn(s.paperCustom, s[`paperCustom_${personMergeMeta[0].conflictType}`])}>
			<div className={s.columnHeader}>
				<div className={s.title}>{personMergeMeta[0].conflictType} </div>
				<InfoTooltip title={personMergeMeta[0].conflictTypeDescriptions} />

				<Select
					placeholder="Multi action assign"
					value={null}
					onChange={onChange}
					options={multiAssignOptions.map(option => ({ label: option.optionName, value: option.optionName }))}
				/>
			</div>

			<div className={s.people}>
				{restPeople.map(personMeta => (
					<PersonField
						key={personMeta.name}
						personMeta={personMeta}
					/>
				))}

				{acceptedPeopleMeta.map(personMeta => (
					<PersonField
						key={personMeta.name}
						personMeta={personMeta}
					/>
				))}
			</div>
		</div>
	);
};
