import { AppDecorator } from 'src/app/decorators';
import { AppRouter } from 'src/app/router/AppRouter';

export const App = () => {
	// * Render
	return (
		<AppDecorator>
			<AppRouter />
		</AppDecorator>
	);
};
