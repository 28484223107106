import cn from 'classnames';
import { FC } from 'react';
import s from './PageDecorator.module.scss';
import { DivProps } from 'src/shared/types/props';
import { Header } from 'src/widgets/Header';

export const PageDecorator: FC<DivProps> = props => {
	const {
		children, //
		className,
	} = props;

	// * Render
	return (
		<div className={cn(s.container, className)}>
			<Header />

			<div className={cn(s.content)}>{children}</div>
		</div>
	);
};
