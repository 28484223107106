import { FC, useMemo } from 'react';
import { KpiTimeSpent } from 'src/pages/MainPage/_BLL/kpi/types';
import { TimePeriodPicker } from 'src/pages/MainPage/ui/Kpi/ui/cardsContent/table/TimePeriodPicker';
import { UrlKPI } from 'src/shared/api/types';
import { SimpleTable } from 'src/shared/ui/_tables/SimpleTable';
import s from './KpiTimeSpentTable.module.scss';

interface Props {
	title: string;
	data: KpiTimeSpent;
	// Props for date picker
	partUrl?: UrlKPI;
	dates?: [string | null, string | null];
}

export const KpiTimeSpentTable: FC<Props> = props => {
	const {
		title, //
		data,
		partUrl,
		dates,
	} = props;

	const { users } = data;

	const columns = useMemo(
		() => [
			{
				Header: 'User',
				accessor: 'user',
				style: {
					fontWeight: 500,
				},
			},
			{
				Header: 'Sessions count',
				accessor: 'sessionCount',
			},
			{
				Header: 'Average session duration',
				accessor: 'averageSessionDuration',
			},
			{
				Header: 'Time spent in silver',
				accessor: 'timeSpent',
			},
		],
		[],
	);

	return (
		<>
			<div className={s.title}>{title}</div>

			{partUrl && dates && (
				<TimePeriodPicker
					partUrl={partUrl}
					dates={dates}
				/>
			)}

			<SimpleTable
				columns={columns}
				data={users}
			/>
		</>
	);
};
