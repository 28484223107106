import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { rtkApiRequest } from "src/shared/api/api";
import { RequestStatus, UrlAPI } from "src/shared/api/types";
import { DumpParamsREQ } from "./types";

const NAME = 'dump';

const dumpCompany = createAsyncThunk(`${NAME}/dumpCompany`, async (params: DumpParamsREQ, thunkAPI) => {
	
	return await rtkApiRequest.rtkGETRequestBLOB<any>({
		url: `${UrlAPI.company}/admin`,
		params,
		thunkAPI
	})
});

const dumpPeople = createAsyncThunk(`${NAME}/dumpPeople`, async (params: DumpParamsREQ, thunkAPI) => {
	
	return await rtkApiRequest.rtkGETRequestBLOB<any>({
		url: `${UrlAPI.people}/admin`,
		params,
		thunkAPI
	})
});

export interface State {
	status: RequestStatus
}

export const initialState: State = {
	status: RequestStatus.still
}

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(dumpCompany.pending, (state) => {
			state.status = RequestStatus.loading
		})
		builder.addCase(dumpCompany.fulfilled, (state) => {
			state.status = RequestStatus.still
		})
		builder.addCase(dumpCompany.rejected, (state) => {
			state.status = RequestStatus.failed
		})

		builder.addCase(dumpPeople.pending, (state) => {
			state.status = RequestStatus.loading
		})
		builder.addCase(dumpPeople.fulfilled, (state) => {
			state.status = RequestStatus.still
		})
		builder.addCase(dumpPeople.rejected, (state) => {
			state.status = RequestStatus.failed
		})
	}
})

export const actionsDump = {
	...slice.actions,
	dumpCompany,
	dumpPeople
}