import { BooleanRequest, CompanyOutlier, OutlierStatusItem } from 'src/pages/QcAutomationPage/_BLL/types';
import { RequestStatus } from 'src/shared/api/types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rtkApiRequest } from 'src/shared/api/api';

const NAME = 'qcAutomation';

const getCompanyOutliers = createAsyncThunk(`${NAME}/getCompanyOutliers`, async (args: { params: { company_id: number } }, thunkAPI) => {
	const {params} = args;
	const {signal} = thunkAPI;

	return await rtkApiRequest.rtkGETRequest<CompanyOutlier>({
		url: 'outliers/company',
		params,
		thunkAPI,
		signal
	});
});

const getOutliers = createAsyncThunk(`${NAME}/getOutliers`, async (args: { params: { page_index: number, page_size: number }, payload?: {search: BooleanRequest} }, thunkAPI) => {
	const {params, payload} = args;
	const {signal} = thunkAPI;

	return await rtkApiRequest.rtkPOSTRequest<{ companies: CompanyOutlier[], total: number }>({
		url: 'outliers/search',
		params,
		payload,
		thunkAPI,
		signal
	});
});

const setOutliersStatuses = createAsyncThunk(`${NAME}/setOutlierStatuses`, async (args: { payload: { outliers: OutlierStatusItem[] } }, thunkAPI) => {
	const {payload} = args;
	const {signal, dispatch} = thunkAPI;

	await rtkApiRequest.rtkPOSTRequest({
		url: 'outliers/statuses',
		payload,
		thunkAPI,
		signal
	});

	dispatch(getCompanyOutliers({params: {company_id: payload.outliers[0].companyID}}));
});

export interface State {
	companies: CompanyOutlier[];
	total: number | null;
	activeCompanyId: number | null;
	status: RequestStatus;
}

export const initialState: State = {
	companies: [],
	total: null,
	activeCompanyId: null,
	status: RequestStatus.still
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setActiveCompanyId: (state, action: PayloadAction<{ activeCompanyId: number | null }>) => {
			state.activeCompanyId = action.payload.activeCompanyId;
		}
	},
	extraReducers: builder => {
		builder.addCase(getCompanyOutliers.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getCompanyOutliers.fulfilled, (state, action) => {
			const companyOutlier = action.payload;

			state.status = RequestStatus.still;
			state.companies = state.companies.map(outlier => {
				return outlier.companyID === companyOutlier.companyID
					? companyOutlier
					: outlier;
			});
		});
		builder.addCase(getCompanyOutliers.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getOutliers.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getOutliers.fulfilled, (state, action) => {
			const {companies, total} = action.payload;

			state.status = RequestStatus.still;
			state.companies = companies;
			state.total = total;
		});
		builder.addCase(getOutliers.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(setOutliersStatuses.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(setOutliersStatuses.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(setOutliersStatuses.rejected, state => {
			state.status = RequestStatus.failed;
		});
	}
});

export const actionsQC = {
	...slice.actions,
	getCompanyOutliers,
	getOutliers,
	setOutliersStatuses
};
