import { Input } from 'antd';
import { PasswordProps } from 'antd/es/input';
import { FC, useId } from 'react';
import { InputWrapper } from '../_common/InputWrapper';
import s from './PasswordInput.module.scss';

interface Props extends PasswordProps {
	label?: string;
	error?: string;
}

const { Password: AntPasswordInput } = Input;

export const PasswordInput: FC<Props> = props => {
	const {
		label, //
		error,
		status,
		className,
		...rest
	} = props;

	const id = useId();

	// * Render
	return (
		<InputWrapper
			label={label}
			error={error}
			className={className}
			id={id}
		>
			<AntPasswordInput
				{...rest}
				className={s.input}
				id={id}
				status={error ? 'error' : status}
			/>
		</InputWrapper>
	);
};
