import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionsNotifications } from 'src/app/providers/NotificationsProvider/_BLL/notifications/slice';
import { actionsMainSearchResults } from 'src/pages/MainSearchResultsPage/_BLL/slice';
import { rtkApiRequest } from 'src/shared/api/api';
import { RequestStatus } from 'src/shared/api/types';
import { PaginationI } from 'src/shared/ui/Pagination';
import { CompanyBaseInfo } from './types';

const NAME = 'main_search';

const searchRequest = createAsyncThunk(`${NAME}/search`, async (payload: { search: string }, thunkAPI) => {
	const {dispatch, signal} = thunkAPI;
	const {search} = payload;

	const params = {
		search_prefix: search,
		page_index: 0,
		page_size: 10
	};

	if (search !== '') {
		const res = await rtkApiRequest.rtkGETRequest<{ companies: CompanyBaseInfo[], pagination: PaginationI }>({
			url: 'companies',
			params,
			thunkAPI,
			signal
		});

		if (res.companies.length === 0) {
			dispatch(actionsNotifications.addNotification({
				type: 'info',
				message: 'Nothing found'
			}));
		}

		dispatch(actionsMainSearchResults.storeSearchResults(res));

		return res;
	} else {
		return {
			companies: initialState.companies,
			pagination: initialState.pagination
		};
	}
});

// * Reducer
interface State {
	companies: CompanyBaseInfo[];
	pagination: PaginationI;
	search: string;
	status: RequestStatus;
}

export const initialState: State = {
	search: '', // Search current value
	companies: [], // Search result for companies
	pagination: {
		pageSize: 10,
		pageIndex: 0,
		pageCount: 0,
	},
	status: RequestStatus.still
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		saveSearch: (state, action: { payload: string }) => {
			state.search = action.payload;
		},
		clearAll: state => {
			state.search = initialState.search;
			state.companies = initialState.companies;
			state.pagination = initialState.pagination;
		},
		clearResults: state => {
			state.companies = initialState.companies;
			state.pagination = initialState.pagination;
		},
		paginationIncrement: state => {
			const {pageIndex} = state.pagination;

			state.pagination = {
				...state.pagination,
				pageIndex: pageIndex + 1
			};
		}
	},
	extraReducers: builder => {
		builder.addCase(searchRequest.pending, (state, action) => {
			const searchPrefix = action.meta.arg.search;
			state.status = searchPrefix === '' ? RequestStatus.still : RequestStatus.loading;
		});
		builder.addCase(searchRequest.fulfilled, (state, action) => {
			const {companies, pagination} = action.payload;
			state.companies = companies;
			state.pagination = pagination;
			state.status = RequestStatus.still;
		});
		builder.addCase(searchRequest.rejected, (state, action) => {
			if (!action.meta.aborted) {
				state.status = RequestStatus.failed;
			}
		});
	}
});

export const actionsMainSearch = {
	...slice.actions,
	searchRequest
};
