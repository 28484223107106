import { ErrorCritical, Notification } from 'src/app/providers/NotificationsProvider/_BLL/notifications/types';
import { createSlice } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

// * Reducer
interface State {
	error: ErrorCritical | null;
	notifications: Notification[];
}

export const initialState: State = {
	error: null,
	notifications: []
};

export const slice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		clearError: (state) => {
			state.error = null;
		},
		setError: (state, action: { payload: ErrorCritical }) => {
			state.error = action.payload;
		},
		addNotification: (state, action: { payload: Omit<Notification, 'id'> }) => {
			const {type, message} = action.payload;
			state.notifications = [...state.notifications, {id: v4(), type, message}];
		},
		deleteNotification: (state, action: { payload: Pick<Notification, 'id'> }) => {
			const {id} = action.payload;
			state.notifications = state.notifications.filter(note => note.id !== id);
		}
	}
});

export const actionsNotifications = slice.actions;
