import { DICTItem } from '../../types';

export const dictionaryIdToString = (id: number | string | null, dictionary: DICTItem[]) => {
	if (id === null) return '';

	const dictionaryItem = dictionary.find(item => +id === item.id);

	if (dictionaryItem) {
		return dictionaryItem.name;
	} else {
		return id;
	}
};
