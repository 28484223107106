import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { mergeToolCopperMenuAction } from 'src/pages/MergeToolPage/_BLL/copper_menu/slice';
import { Company, SourceType } from 'src/pages/MergeToolPage/_BLL/copper_menu/types';
import { actionsMergeToolDataPoints } from 'src/pages/MergeToolPage/_BLL/data_points/slice';
import { DiffPayload, DiffSource } from 'src/pages/MergeToolPage/_BLL/data_points/types';
import { RequestStatus } from 'src/shared/api/types';
import MenuSVG from 'src/shared/assets/svg/menu.svg';
import { ButtonSecondary } from 'src/shared/ui/_buttons/ButtonSecondary';
import { Preloader } from 'src/shared/ui/_loaders/Preloader';
import { Tooltip } from 'src/shared/ui/_tooltips/Tooltip';
import s from './MergeToolMenu.module.scss';

interface Props {
	sources: SourceType[];
}

export const MergeToolMenu: FC<Props> = props => {
	const {
		sources, //
	} = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { getCompanies, selectCompany, clear: menuClear } = mergeToolCopperMenuAction;
	const { getDataPoints } = actionsMergeToolDataPoints;

	// * Selectors
	const companies = useAppSelector(state => state.copperMenu.companies);
	const selectedCompany = useAppSelector(state => state.copperMenu.selectedCompany);
	const status = useAppSelector(state => state.copperMenu.status);

	// * Menu
	const [menuOpen, setMenuOpen] = useState(true);

	const loadMoreCompanies = (companies: Company[]) => {
		const lastCompanyId = companies[companies.length - 1].id;
		dispatch(
			getCompanies({
				sources,
				lastCompanyId,
			}),
		);
	};

	// * Initial companies load
	useEffect(() => {
		dispatch(getCompanies({ sources }));
	}, []);

	useEffect(() => {
		return () => {
			dispatch(menuClear());
		};
	}, []);

	const payloadSources: DiffSource[] = [
		// Source company
		{
			source: 'Copper',
			companyId: selectedCompany ? selectedCompany.id : 0,
			reportingPeriodId: null,
		},
		// Target company
		{
			source: 'Silver',
			companyId: selectedCompany ? selectedCompany.id : 0,
			reportingPeriodId: null,
		},
	];

	const diffPayload: DiffPayload = {
		sources: payloadSources,
		target: payloadSources[1],
		metadataSource: 'Copper',
		onlyConflicts: true,
	};

	useEffect(() => {
		selectedCompany && dispatch(getDataPoints({ payload: diffPayload }));
	}, [selectedCompany]);

	// * Render
	return (
		<div className={s.container}>
			<MenuSVG
				className={s.menuSVG}
				onClick={() => setMenuOpen(!menuOpen)}
			/>

			{menuOpen && <div className={s.title}>Companies with conflicts</div>}

			{status === RequestStatus.loading && <Preloader />}

			{menuOpen && companies && (
				<>
					<div className={s.menu}>
						{companies.map(company => (
							<Tooltip
								key={company.id}
								title={company.name}
							>
								<div
									className={cn(s.menuItem, (selectedCompany && selectedCompany.id) === company.id && s.menuItem_active)}
									onClick={() =>
										dispatch(
											selectCompany({
												companies,
												id: company.id,
											}),
										)
									}
								>
									<span className={s.redBG}>{company.conflicts}</span> <b>{company.id}</b> {company.name}
								</div>
							</Tooltip>
						))}
					</div>

					<ButtonSecondary
						className={s.button}
						onClick={() => loadMoreCompanies(companies)}
					>
						Load more companies
					</ButtonSecondary>
				</>
			)}
		</div>
	);
};
