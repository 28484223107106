import { RequestStatus } from 'src/shared/api/types';
import s from './UploadToGoldModalContent.module.scss';
import { useAppSelector } from 'src/app/redux/createAction';
import { Preloader } from 'src/shared/ui/_loaders/Preloader';

export const UploadToGoldModalContent = () => {
	const uploadedCompaniesIds = useAppSelector(state => state.uploadToGold.uploadedCompaniesIds);
	const status = useAppSelector(state => state.uploadToGold.status);

	const updatedMultiple = uploadedCompaniesIds && uploadedCompaniesIds.length > 1;

	// * Render
	return (
		<>
			<div className={s.title}>{updatedMultiple ? 'Companies' : 'Company'} uploaded to Gold successfully</div>
			<p className={s.paragraph}>Do you wish to open the company in Gold?</p>
			{uploadedCompaniesIds?.map(id => (
				<a
					className={s.link}
					key={id}
					href={`https://gold.denominator.com/database/company/${id}/1`}
					target="_blank"
					rel="noreferrer"
				>
					Open DEI-{id} in Gold
				</a>
			))}
			{status === RequestStatus.loading && (
				<div>
					Updating more companies... <Preloader />
				</div>
			)}
		</>
	);
};
