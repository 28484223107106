import { FC, memo } from 'react';
import { KpiDatabase } from 'src/pages/MainPage/_BLL/kpi/types';
import s from './Datebase.module.scss';

interface Props {
	data: KpiDatabase | null;
}

export const Database: FC<Props> = memo(props => {
	const {
		data, //
	} = props;

	if (data === null) return null;

	const {
		companiesTotal,
		totalDataPoints,
		// totalWithLessThanXDatapoints,
		// totalWithXDatapoints
	} = data;

	return (
		<>
			<div className={s.caption}>Total Companies</div>
			<div className={s.rating}>{companiesTotal.toLocaleString('ru-RU')}</div>

			<div className={s.caption}>Total Data Points</div>
			<div className={s.rating}>{totalDataPoints.toLocaleString('ru-RU')}</div>
		</>
	);
});
