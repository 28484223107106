import cn from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import { actionsNotifications } from 'src/app/providers/NotificationsProvider/_BLL/notifications/slice';
import { Notification } from 'src/app/providers/NotificationsProvider/_BLL/notifications/types';
import { useActions } from 'src/app/redux/createAction';
import s from './NotificationSingle.module.scss';

export const NotificationSingle: FC<Notification> = props => {
	const {
		id, //
		message,
		type,
	} = props;

	// * Actions
	const { deleteNotification } = useActions(actionsNotifications);

	// * Local state
	const [close, setClose] = useState(false);
	const [barWidth, setBarWidth] = useState<number>(0);
	const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval> | null>(null);

	const handleStartTimer = useCallback(() => {
		const id = setInterval(() => {
			setBarWidth(prevState => {
				if (prevState < 100) {
					return prevState + 0.5;
				}
				return prevState;
			});
		}, 20);

		setIntervalId(id);
	}, []);

	const handlePauseTimer = useCallback(() => {
		clearInterval(intervalId as ReturnType<typeof setInterval>);
	}, [intervalId]);

	useEffect(() => {
		handleStartTimer();
	}, [handleStartTimer]);

	useEffect(() => {
		const handleCloseNotification = () => {
			handlePauseTimer();
			setClose(true);
			setTimeout(() => {
				deleteNotification({ id });
			}, 400);
		};

		if (barWidth === 100) {
			handleCloseNotification();
		}
	}, [barWidth, deleteNotification, handlePauseTimer, id]);

	// * Render
	return (
		<div
			className={cn(s.container, close && s.container_exit)}
			onMouseEnter={handlePauseTimer}
			onMouseLeave={handleStartTimer}
		>
			<p>{message}</p>

			<div
				className={cn(s.bar, s[`bar_${type}`])}
				style={{ width: `${barWidth}%` }}
			/>
		</div>
	);
};
