import { useAppSelector } from 'src/app/redux/createAction';
import { DumpCompany } from 'src/features/DumpCompany';

export const DashboardDump = () => {
	// * Selectors
	const currentCompany = useAppSelector(state => state.dashboardCompany.currentCompany);

	// * Render
	return (
		<>
			{currentCompany && (
				<DumpCompany
					companyId={currentCompany.id}
					reportingPeriodId={currentCompany.reportingPeriodId}
				/>
			)}
		</>
	);
};
