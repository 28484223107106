import cn from 'classnames';
import { DetailedHTMLProps, FC, HTMLAttributes, memo, useState } from 'react';
import { useAppSelector } from 'src/app/redux/createAction';
import { RequestStatus } from 'src/shared/api/types';
import { PreloaderSquared } from 'src/shared/ui/_loaders/PreloaderSquared';
import { ButtonSecondary } from '../_buttons/ButtonSecondary';
import s from './Pagination.module.scss';
import { IPagination } from './types';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	pageSize: number;
	pageCount: number;
	currentPage: number;
	changePage: (pagination: IPagination) => void;
	visibleOptionsCount?: number;
	className?: string;
	hasPortionChange?: boolean;
}

export const Pagination: FC<Props> = memo(props => {
	const {
		pageSize, // Number of items displayed on the page.
		pageCount, // Total number of pages.
		currentPage, // The current page displayed.
		changePage, // Callback function that sets the current page when clicking on the page number.
		visibleOptionsCount = 10, // Number of pages displayed on the pagination.
		className,
		hasPortionChange = false,
		...divProps
	} = props;

	// * Selectors
	const { status } = useAppSelector(state => state.mainSearchResults);

	// Pagination logic
	const pages = [];
	for (let i = 1; i <= pageCount; i++) {
		pages.push(i);
	}

	const portionCount = Math.ceil(pageCount / pageSize);

	const [portionNumber, setPortionNumber] = useState(1);

	const leftPortionPageNumber = (portionNumber - 1) * visibleOptionsCount + 1;
	const rightPortionPageNumber = portionNumber * visibleOptionsCount;

	const disablePrevButton = () => {
		return portionNumber === 1;
	};

	const disableNextButton = () => {
		return portionNumber === portionCount;
	};

	const changePageHandler = (pageNumber: number) => {
		if (pageNumber < 1) pageNumber = 1;
		if (pageNumber > pageCount) pageNumber = pageCount;

		setPortionNumber(Math.ceil(pageNumber / visibleOptionsCount));

		const newPage = {
			pageSize,
			pageCount,
			pageIndex: pageNumber - 1,
		};

		changePage(newPage);
	};

	const changePageSizeHandler = (pageSize: number) => {
		const newPage = {
			pageSize,
			pageCount,
			pageIndex: 0,
		};

		changePage(newPage);
	};

	// * Render
	return (
		<div
			{...divProps}
			className={cn(s.container, className)}
		>
			{hasPortionChange && (
				<div>
					<select
						className={s.select}
						defaultValue={pageSize}
						onChange={e => changePageSizeHandler(+e.currentTarget.value)}
					>
						<option value={10}>10</option>
						<option value={50}>50</option>
						<option value={100}>100</option>
					</select>
				</div>
			)}

			<div className={s.pages}>
				<ButtonSecondary
					onClick={() => {
						setPortionNumber(portionNumber - 1);
					}}
					disabled={disablePrevButton()}
				>
					PREV
				</ButtonSecondary>
				{status === RequestStatus.still && pageCount ? (
					pages
						.filter(page => page >= leftPortionPageNumber && page <= rightPortionPageNumber)
						.map(page => (
							<ButtonSecondary
								key={page}
								className={cn(s.button, currentPage + 1 === page && s.button__active)}
								onClick={() => changePageHandler(page)}
							>
								{page}
							</ButtonSecondary>
						))
				) : (
					<PreloaderSquared />
				)}
				<ButtonSecondary
					onClick={() => {
						setPortionNumber(portionNumber + 1);
					}}
					disabled={disableNextButton()}
				>
					NEXT
				</ButtonSecondary>
			</div>

			<div>
				<span>Go to: </span>
				<input
					className={s.input}
					type="number"
					onBlur={e => changePageHandler(+e.currentTarget.value)}
					onKeyDown={e => (e.key === 'Enter' ? changePageHandler(+e.currentTarget.value) : null)}
				/>
			</div>

			<div>
				<span>Total pages: </span>
				<span className={s.total}>{pageCount}</span>
			</div>
		</div>
	);
});
