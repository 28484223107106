import { FC } from 'react';
import { useAppDispatch } from 'src/app/redux/createAction';
import { actionsMergeToolDataPoints } from '../../_BLL/data_points/slice';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';

interface Props {
	queryId: number;
}

export const CancelLongQueryButton: FC<Props> = props => {
	const {
		queryId, //
	} = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { cancelMerge } = actionsMergeToolDataPoints;

	const cancelLongQuery = () => {
		dispatch(cancelMerge({ query_id: queryId }));
	};

	return <ButtonPrimary onClick={cancelLongQuery}>Cancel</ButtonPrimary>;
};
