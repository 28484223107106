import { CompanyDataPoints, DataPoint, FormValues, Property } from "src/pages/MergeToolPage/_BLL/data_points/types";

const getDataPoints = (property: Property, targetCompanyId: number): DataPoint[] => {
	// Don't auto merge associated parent of target company if it is the target company.
	if (property.propertyName === 'associatedParent') {
		if (property.autoMergedValue?.datapoints[0].value === targetCompanyId.toString()) {
			return property.currentValue
		}
	}

	return property.autoMergedValue ? property.autoMergedValue.datapoints : property.currentValue
}

export const generateInitialValues = (companyConflicts: CompanyDataPoints[], targetCompanyId: number): FormValues => {
	return Object.fromEntries(
		companyConflicts
			.map(group =>
				group.properties.map(property => [
					property.propertyName,
					{
						dataPoints: getDataPoints(property, targetCompanyId),
						formMeta: {
							move: property.currentValue[0].hasUniqueValues,
						},
					},
				]),
			)
			.flat(),
	)
}