import { useEffect } from 'react';
import { Logo } from 'src/shared/ui/Logo';
import { SignInForm } from '../SignInForm';
import s from './SignInPage.module.scss';

export const SignInPage = () => {
	// * Refresh all data
	useEffect(() => {
		localStorage.clear();
	}, []);

	// * Render
	return (
		<div className={s.container}>
			<div className={s.inner}>
				<Logo className={s.logo} />
				<SignInForm />
			</div>
		</div>
	);
};
