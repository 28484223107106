import { ComponentPropsWithoutRef, FC } from 'react';
import s from './Label.module.scss';

interface Props extends Omit<ComponentPropsWithoutRef<'label'>, 'htmlFor'> {
	label: string;
	id: string;
}

export const Label: FC<Props> = props => {
	const { label, id, ...labelProps } = props;

	return (
		<label
			className={s.label}
			{...labelProps}
			htmlFor={id}
		>
			{label}
		</label>
	);
};
