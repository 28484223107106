import { FC } from 'react';
import { useMetaContext } from 'src/app/context/useMetaContext';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { actionsMergeToolDataPoints } from 'src/pages/MergeToolPage/_BLL/data_points/slice';
import { extractBetweenX, getSubstringInParentheses } from 'src/shared/lib/string';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import s from './ReportingPeriodError.module.scss';

export const ReportingPeriodError: FC = () => {
	// * Context
	const {
		dictionaries: { reportingPriods: reportingPeriods },
	} = useMetaContext();

	// * Actions
	const dispatch = useAppDispatch();
	const { mergeCompany } = actionsMergeToolDataPoints;

	// * Selectors
	const reportingPeriodError = useAppSelector(state => state.mergeToolDataPoints.reportingPeriodError);
	const targetCompany = useAppSelector(state => state.mergeToolCompanySearch.targetCompany);

	if (reportingPeriodError === null) {
		return null;
	}

	const [type, companyId, reportingPeriodId] = extractBetweenX(getSubstringInParentheses(reportingPeriodError) ?? '');

	const missingReportingCompanyType = String(targetCompany?.id) === companyId ? 'Target' : 'Source';

	const addReportingClick = () => {
		dispatch(
			mergeCompany({
				formValues: {},
				isNewPeriod: true,
			}),
		);
	};

	// * Render
	return (
		<div className={s.container}>
			<b>
				{missingReportingCompanyType} is missing a reporting period - {reportingPeriods.find(period => String(period.id) === reportingPeriodId)?.name}
			</b>
			{missingReportingCompanyType === 'Target' && <ButtonPrimary onClick={addReportingClick}>Add Reporting period to target</ButtonPrimary>}
		</div>
	);
};
