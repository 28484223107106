import _ from 'lodash';
import { FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { extractWord } from 'src/shared/lib/string';

export const cancelVerify = (
	namePrefix: string,
	propertyName: string, // Company only
	internalName: string, // Company only
	setValue: UseFormSetValue<FieldValues>,
	getValues: UseFormGetValues<FieldValues>,
) => {
	const values = getValues();

	const personIndexWrapped = extractWord(namePrefix, '[', ']');

	if (personIndexWrapped !== undefined) {
		// Person
		const personIndex = personIndexWrapped.slice(1, -1);
		const propertyNames = Object.keys(values.people[personIndex].properties);

		propertyNames.forEach(property => {
			setValue(`${namePrefix}${property}.dataPoint.verified`, false);
		});
	} else {
		// Company
		const dataPointKeys = Object.keys(_.get(values, `${namePrefix}${propertyName}`));
		dataPointKeys.forEach(property => {
			setValue(`${namePrefix}${propertyName}.${property}.verified`, false);
		});
	}
};
