import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionsNotifications } from 'src/app/providers/NotificationsProvider/_BLL/notifications/slice';
import { RootState } from 'src/app/redux/rootReducer';
import { actionsUploadToGold } from 'src/pages/DashboardPage/_BLL/uploadToGold/slice';
import { mergeToolCopperMenuAction } from 'src/pages/MergeToolPage/_BLL/copper_menu/slice';
import { formValuesToSubmitPayload } from 'src/pages/MergeToolPage/_BLL/data_points/lib/formValuesToSubmitPayload';
import {
	CompanyDataPoints,
	DiffPayload,
	DiffRES,
	DiffSource,
	FormValues,
	PersonAutoMergeResult,
	PersonDIFF,
	Property,
	SavePayload,
	SavePeopleRES,
	SavePersonRES,
	Source,
} from 'src/pages/MergeToolPage/_BLL/data_points/types';
import { personPayload } from 'src/pages/MergeToolPage/_BLL/silver_people/types';
import { rtkApiRequest } from 'src/shared/api/api';
import { RequestStatus, ResponseStatus, UrlAPI } from 'src/shared/api/types';
import { removeDuplicates } from 'src/shared/lib/object_array';
import { handleLongQueryRES } from './lib/handleLongQueryRES';
import { isReportingError } from './lib/isReportingError';

const NAME = 'mergetool/data_points';

const refreshDataPoints = createAsyncThunk(`${NAME}/refreshDataPoints`, async (_: void, thunkAPI) => {
	const { signal, getState } = thunkAPI;

	const state = getState() as RootState;
	const diffPayload = state.mergeToolDataPoints.lastDiffPayload as DiffPayload;
	const { source: sourceReportingPeriod, target: targetReportingPeriod } = state.mergeToolCompanySearch.reportingPeriods;

	const payload: DiffPayload = {
		...diffPayload,
		sources: [
			{
				...diffPayload.sources[0],
				reportingPeriodId: sourceReportingPeriod,
			},
			{
				...diffPayload.sources[1],
				reportingPeriodId: targetReportingPeriod,
			},
		],
		target: {
			...diffPayload.target,
			reportingPeriodId: targetReportingPeriod,
		},
	};

	return await rtkApiRequest.rtkPOSTRequest<DiffRES>({
		url: UrlAPI.mergeToolDiff,
		payload,
		thunkAPI,
		signal,
	});
});

const getDataPoints = createAsyncThunk(`${NAME}/getDataPoints`, async (arg: { payload: DiffPayload }, thunkAPI) => {
	const { payload } = arg;
	const { signal } = thunkAPI;

	return await rtkApiRequest.rtkPOSTRequest<DiffRES>({
		url: UrlAPI.mergeToolDiff,
		payload,
		thunkAPI,
		signal,
	});
});

const mergeCompany = createAsyncThunk(`${NAME}/mergeCompany`, async (arg: { formValues: FormValues; isNewPeriod: boolean }, thunkAPI) => {
	const { formValues, isNewPeriod } = arg;
	const { signal, dispatch, getState } = thunkAPI;

	const state = getState() as RootState;
	const diffPayload = state.mergeToolDataPoints.lastDiffPayload as DiffPayload;
	const { source: sourceReportingPeriod, target: targetReportingPeriod } = state.mergeToolCompanySearch.reportingPeriods;
	const targetCompany = state.mergeToolCompanySearch.targetCompany; // Target company info from diff info panel.

	const source = diffPayload?.sources[0];
	const target = diffPayload?.target;
	const metadataSource = diffPayload?.metadataSource ?? 'Silver';

	const sourcePayload: DiffSource = {
		...source,
		reportingPeriodId: sourceReportingPeriod,
	};

	const targetPayload: DiffSource = {
		...target,
		reportingPeriodId: targetReportingPeriod,
	};

	const isReportingPeriodsSame = sourcePayload.reportingPeriodId === targetPayload.reportingPeriodId;

	const saveFormValues: FormValues = {};
	const moveFormValues: FormValues = {};

	for (const [key, formValue] of Object.entries(formValues)) {
		const move = formValue.formMeta.move;
		if (move) {
			moveFormValues[key] = formValue;
		} else {
			saveFormValues[key] = formValue;
		}
	}

	let payload = formValuesToSubmitPayload(targetPayload, saveFormValues);

	if (Object.entries(formValues).length === 0 && targetCompany) {
		payload = {
			...payload,
			values: [
				{
					datapointId: 1000000,
					value: String(targetCompany.id),
				},
			],
		};
	}

	let status: ResponseStatus | null = null;

	const params = {
		metadata_source: metadataSource,
	};

	do {
		const res: any = await rtkApiRequest.rtkPOSTRequest({
			url: UrlAPI.mergeToolSave,
			params,
			payload: {
				...payload,
				source: sourcePayload,
			},
			thunkAPI,
			signal,
		});

		status = res.status;

		await handleLongQueryRES(res, dispatch, slice.actions.setQueryId, () => Object.assign(params, { query_id: res.queryId }), 'Merge cancelled');
	} while (status === 'InProgress');

	// * Handling move
	if (Object.keys(moveFormValues).length > 0 && isReportingPeriodsSame) {
		// ! No move when reporting periods are different.
		let status: ResponseStatus | null = null;

		const params = {
			metadata_source: metadataSource,
		};

		const payload = formValuesToSubmitPayload(targetPayload, moveFormValues);

		do {
			const res: any = await rtkApiRequest.rtkPOSTRequest({
				url: UrlAPI.mergeToolMove,
				params,
				payload: {
					...payload,
					source: sourcePayload,
				},
				thunkAPI,
				signal,
			});

			status = res.status;

			await handleLongQueryRES(res, dispatch, slice.actions.setQueryId, () => Object.assign(params, { query_id: res.queryId }), 'Merge move cancelled');
		} while (status === 'InProgress');
	}

	// * Copper only - Saves data to copper as well to remove conflicts.
	if (metadataSource === 'Copper') {
		let status: ResponseStatus | null = null;

		const params = {
			metadata_source: metadataSource,
		};

		do {
			const payload = formValuesToSubmitPayload(sourcePayload, formValues);

			const res: any = await rtkApiRequest.rtkPOSTRequest({
				url: UrlAPI.mergeToolSave,
				params,
				payload,
				thunkAPI,
				signal,
			});

			status = res.status;

			await handleLongQueryRES(res, dispatch, slice.actions.setQueryId, () => Object.assign(params, { query_id: res.queryId }), 'Copper save cancelled');
		} while (status === 'InProgress');
	}

	// * Refresh dataPoints
	const res = await dispatch(refreshDataPoints()).unwrap();

	if (res.count === 0) {
		dispatch(
			mergeToolCopperMenuAction.removeCompany({
				companyId: targetPayload.companyId,
			}),
		);
	} else {
		dispatch(
			mergeToolCopperMenuAction.refreshCompany({
				dataPointsNumber: res.count,
			}),
		);
	}

	dispatch(
		actionsNotifications.addNotification({
			type: 'success',
			message: 'Data points saved successfully',
		}),
	);

	targetPayload.reportingPeriodId &&
		!isNewPeriod &&
		dispatch(
			actionsUploadToGold.uploadToGold({
				companyId: String(targetPayload.companyId),
				reportingPeriodId: targetPayload.reportingPeriodId,
				showSuccessNotification: true,
			}),
		);

	sourcePayload.reportingPeriodId &&
		!isNewPeriod &&
		dispatch(
			actionsUploadToGold.uploadToGold({
				companyId: String(sourcePayload.companyId),
				reportingPeriodId: sourcePayload.reportingPeriodId,
				showSuccessNotification: true,
			}),
		);
});

const mergePeople = createAsyncThunk(`${NAME}/mergePeople`, async (arg: { personsToMerge: personPayload[] }, thunkAPI) => {
	const { personsToMerge } = arg;
	const { signal, dispatch, getState } = thunkAPI;

	const state = getState() as RootState;
	const lastDiffPayload = state.mergeToolDataPoints.lastDiffPayload;

	const updateSource = personsToMerge.find(person => person.mode === 'Move');

	const source = lastDiffPayload?.sources[0];
	const target = lastDiffPayload?.sources[1];

	let status: ResponseStatus | null = null;
	let response: SavePeopleRES | null = null;

	const params = {
		metadata_source: 'silver',
	};

	const payload = {
		target,
		persons: personsToMerge,
	};

	do {
		const res = await rtkApiRequest.rtkPOSTRequest<SavePeopleRES>({
			url: UrlAPI.mergeToolSavePeople,
			params,
			payload,
			thunkAPI,
			signal,
		});

		status = res.status;

		response = await handleLongQueryRES(res, dispatch, slice.actions.setQueryId, () => Object.assign(params, { query_id: res.queryId }), 'People merge cancelled');
	} while (status === 'InProgress');

	if (!!response && response?.result?.length > 0) {
		dispatch(actionsMergeToolDataPoints.setAutoMergeError(response.result));
	}

	if (updateSource) {
		if (source?.reportingPeriodId) {
			dispatch(
				actionsUploadToGold.uploadToGold({
					companyId: String(source.companyId),
					reportingPeriodId: source.reportingPeriodId,
					showSuccessNotification: true,
				}),
			)
				.unwrap()
				.then(() => {
					if (target?.reportingPeriodId) {
						dispatch(
							actionsUploadToGold.uploadToGold({
								companyId: String(target.companyId),
								reportingPeriodId: target.reportingPeriodId,
								showSuccessNotification: true,
							}),
						);
					}
				})
				.catch(error => console.log(error));
		}
	} else {
		if (target?.reportingPeriodId) {
			dispatch(
				actionsUploadToGold.uploadToGold({
					companyId: String(target.companyId),
					reportingPeriodId: target.reportingPeriodId,
					showSuccessNotification: true,
				}),
			);
		}
	}

	const diffPayload = state.mergeToolDataPoints.lastDiffPayload;

	if (diffPayload) {
		return await rtkApiRequest.rtkPOSTRequest({
			url: UrlAPI.mergeToolDiff,
			payload: diffPayload,
			thunkAPI,
			signal,
		});
	}
});

const mergeSinglePerson = createAsyncThunk(
	`${NAME}/mergeSinglePerson`,
	async (arg: { reqPayload: Omit<SavePayload, 'target'> & { personId: number }; personName: string }, thunkAPI) => {
		const { reqPayload, personName } = arg;
		const { signal, dispatch, getState } = thunkAPI;

		const state = getState() as RootState;
		const lastDiffPayload = state.mergeToolDataPoints.lastDiffPayload;

		const source = lastDiffPayload?.sources[0];
		const target = lastDiffPayload?.sources[1];

		let status: ResponseStatus | null = null;
		let response: SavePersonRES | null = null;

		const params = {
			metadata_source: 'silver',
		};

		do {
			const res = await rtkApiRequest.rtkPOSTRequest<SavePersonRES>({
				url: UrlAPI.mergeToolSave,
				params,
				payload: {
					...reqPayload,
					source,
					target,
				},
				thunkAPI,
				signal,
			});

			status = res.status;

			response = await handleLongQueryRES(res, dispatch, slice.actions.setQueryId, () => Object.assign(params, { query_id: res.queryId }), 'Person merge cancelled');
		} while (status === 'InProgress');

		dispatch(
			actionsNotifications.addNotification({
				type: 'success',
				message: 'Person merged successfully',
			}),
		);

		if (source?.reportingPeriodId) {
			dispatch(
				actionsUploadToGold.uploadToGold({
					companyId: String(source.companyId),
					reportingPeriodId: source.reportingPeriodId,
					showSuccessNotification: true,
				}),
			)
				.unwrap()
				.then(() => {
					if (target?.reportingPeriodId) {
						dispatch(
							actionsUploadToGold.uploadToGold({
								companyId: String(target.companyId),
								reportingPeriodId: target.reportingPeriodId,
								showSuccessNotification: true,
							}),
						);
					}
				})
				.catch(error => console.log(error));
		}

		return {
			response,
			personName,
		};
	},
);

const transferAssociatedChildren = createAsyncThunk(`${NAME}/transferAssociatedChildren`, async (arg: { source: Source; target: Source }, thunkAPI) => {
	const { source, target } = arg;
	const { signal, dispatch } = thunkAPI;

	let status: ResponseStatus | null = null;

	const params = {};

	do {
		const res: any = await rtkApiRequest.rtkPOSTRequest({
			url: UrlAPI.mergeToolTransferChildren,
			params,
			payload: {
				source,
				target,
			},
			thunkAPI,
			signal,
		});

		status = res.status;

		await handleLongQueryRES(res, dispatch, slice.actions.setQueryId, () => Object.assign(params, { query_id: res.queryId }), 'Transfer children cancelled');
	} while (status === 'InProgress');

	dispatch(refreshDataPoints());
});

const cancelMerge = createAsyncThunk(`${NAME}/cancelMerge`, async (params: { query_id: number }, thunkAPI) => {
	return rtkApiRequest.rtkPOSTRequest({
		url: UrlAPI.mergeToolCancel,
		params,
		thunkAPI,
	});
});

interface State {
	companyConflicts: CompanyDataPoints[] | null;
	peopleConflicts: PersonDIFF[] | null;
	autoMergeErrors: Array<{ personId: number; error: string }>;
	lastDiffPayload: DiffPayload | null;
	reportingPeriodError: string | null;
	queryId: number | null;
	status: RequestStatus;
}

export const initialState: State = {
	companyConflicts: null,
	peopleConflicts: null,
	autoMergeErrors: [],
	lastDiffPayload: null,
	reportingPeriodError: null,
	queryId: null,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setAutoMergeError: (state, action: PayloadAction<PersonAutoMergeResult[]>) => {
			state.autoMergeErrors = action.payload
				.filter(result => result.error)
				.map(result => ({
					personId: result.sourcePersonId,
					error: result.error,
				}));
		},
		clearAutoMergeError: (state, action: PayloadAction<{ personId: number | null }>) => {
			const { personId } = action.payload;

			state.autoMergeErrors = personId ? state.autoMergeErrors.filter(error => error.personId !== personId) : []; // Sending null as a person id will clear all the merge errors.
		},
		clear: state => {
			state.companyConflicts = initialState.companyConflicts;
			state.peopleConflicts = initialState.peopleConflicts;
			state.autoMergeErrors = initialState.autoMergeErrors;
			state.lastDiffPayload = initialState.lastDiffPayload;
			state.reportingPeriodError = initialState.reportingPeriodError;
			state.status = initialState.status;
		},
		clearReportingPeriodError: state => {
			state.reportingPeriodError = null;
		},
		setQueryId: (state, action: PayloadAction<number | null>) => {
			state.queryId = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(refreshDataPoints.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(refreshDataPoints.fulfilled, (state, action) => {
			const { persons, conflicts } = action.payload;

			state.status = RequestStatus.still;
			state.companyConflicts = conflicts;
			state.peopleConflicts = persons;
			state.reportingPeriodError = null;
		});
		builder.addCase(refreshDataPoints.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getDataPoints.pending, (state, action) => {
			state.lastDiffPayload = action.meta.arg.payload;
			state.companyConflicts = null;
			state.peopleConflicts = null;
			state.status = RequestStatus.loading;
		});
		builder.addCase(getDataPoints.fulfilled, (state, action) => {
			const { persons, conflicts } = action.payload;

			state.status = RequestStatus.still;
			state.companyConflicts = conflicts;
			state.peopleConflicts = persons;
		});
		builder.addCase(getDataPoints.rejected, (state, action) => {
			if (isReportingError(action.payload) && action.payload.errorCode === 'COMPLEX_SOURCE_NOT_PRESENT') {
				state.reportingPeriodError = action.payload.message;
				state.companyConflicts = null;
				state.peopleConflicts = null;
			}
			state.status = RequestStatus.failed;
		});

		builder.addCase(mergeCompany.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(mergeCompany.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(mergeCompany.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(mergePeople.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(mergePeople.fulfilled, (state, action) => {
			state.status = RequestStatus.still;

			if (action.payload) {
				const { persons, conflicts } = action.payload;

				state.companyConflicts = conflicts;
				state.peopleConflicts = persons;
			}
		});
		builder.addCase(mergePeople.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(mergeSinglePerson.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(mergeSinglePerson.fulfilled, (state, action) => {
			const { personName, response } = action.payload;

			state.status = RequestStatus.still;

			if (state.peopleConflicts !== null && response) {
				state.peopleConflicts = state.peopleConflicts.map(personConflict => {
					if (personConflict.name === personName) {
						const dataPoints = response?.result.datapoints;
						const conflicts: Property[] = [];

						for (const dataPoint of dataPoints) {
							for (const conflict of personConflict.conflicts) {
								if (
									(conflict.currentValue[0].datapointId === dataPoint.datapointId && conflict.values[0].datapoints[0].value !== dataPoint.value) ||
									conflict.propertyName === 'personId' ||
									conflict.propertyName === 'companyId'
								) {
									conflicts.push(conflict);
								}
							}
						}

						return {
							...personConflict,
							conflicts: removeDuplicates(conflicts, 'propertyName'),
						};
					} else {
						return personConflict;
					}
				});
			}
		});
		builder.addCase(mergeSinglePerson.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(transferAssociatedChildren.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(transferAssociatedChildren.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(transferAssociatedChildren.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(cancelMerge.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(cancelMerge.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(cancelMerge.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsMergeToolDataPoints = {
	...slice.actions,
	getDataPoints,
	mergeCompany,
	mergePeople,
	mergeSinglePerson,
	transferAssociatedChildren,
	cancelMerge,
};
