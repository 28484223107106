export const getSubstringInParentheses = (str: string): string | undefined => {
	const regex = /\((.*?)\)/g;
	let match;

	while ((match = regex.exec(str)) !== null) {
		return match[1];
	}
};

/*
	example:
	original string: "Complex source (Silver x 3222393 x 1) is not present."
	result: "Silver x 3222393 x 1"
*/
