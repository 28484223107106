import cn from 'classnames';
import { FC, HTMLAttributes, memo } from 'react';
import LogoSVG from 'src/shared/assets/svg/denominator_logo.svg';
import s from './Logo.module.scss';

export const Logo: FC<HTMLAttributes<HTMLDivElement>> = memo(props => {
	const { className, ...divProps } = props;

	return (
		<div
			className={className}
			{...divProps}
		>
			<LogoSVG className={cn(s.container)} />
		</div>
	);
});
