import cn from 'classnames';
import { FC, memo } from 'react';
import { useMetaContext } from 'src/app/context/useMetaContext';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { DumpCompany } from 'src/features/DumpCompany';
import { actionsMergeToolDataPoints } from 'src/pages/MergeToolPage/_BLL/data_points/slice';
import { DiffPayload, DiffSource } from 'src/pages/MergeToolPage/_BLL/data_points/types';
import { mergeToolSilverConflicts } from 'src/pages/MergeToolPage/_BLL/silver_conflicts/slice';
import { SearchBar } from 'src/pages/MergeToolPage/ui/SilverMode/SearchBar';
import ArrowsSVG from 'src/shared/assets/svg/arrows/arrow-up-down.svg';
import { ButtonSecondary } from 'src/shared/ui/_buttons/ButtonSecondary';
import { DiffInfo } from '../../DiffInfo';
import s from './SilverFilter.module.scss';
import { RequestStatus } from 'src/shared/api/types';

export const SilverFilter: FC = memo(() => {
	// * Context
	const {
		dictionaries: { reportingPriods: reportingPeriods },
	} = useMetaContext();

	// * Actions
	const dispatch = useAppDispatch();
	const { getDataPoints, clearReportingPeriodError } = actionsMergeToolDataPoints;
	const { storeSourceCompany, storeTargetCompany, setPeriod } = mergeToolSilverConflicts;

	// * Selectors
	const sourceCompany = useAppSelector(state => state.mergeToolCompanySearch.sourceCompany);
	const targetCompany = useAppSelector(state => state.mergeToolCompanySearch.targetCompany);
	const activeReportingPeriods = useAppSelector(state => state.mergeToolCompanySearch.reportingPeriods);
	const status = useAppSelector(state => state.mergeToolDataPoints.status);

	// * Filter fields
	const sourcesSelected = !!sourceCompany?.id && !!targetCompany?.id;
	const latestReportingPeriodId = reportingPeriods[0].id;

	const sources: DiffSource[] = [
		{
			source: 'Silver',
			companyId: sourceCompany ? sourceCompany.id : 0,
			reportingPeriodId: activeReportingPeriods['source'] ?? latestReportingPeriodId,
		},
		{
			source: 'Silver',
			companyId: targetCompany ? targetCompany.id : 0,
			reportingPeriodId: activeReportingPeriods['target'] ?? latestReportingPeriodId,
		},
	];

	const source = sources[0];
	const target = sources[1];

	const payload: DiffPayload = {
		sources,
		target,
		metadataSource: 'Silver',
		onlyConflicts: true,
	};

	// * User actions
	const getConflicts = () => {
		dispatch(clearReportingPeriodError());

		if (sourcesSelected) {
			dispatch(getDataPoints({ payload }));
		}
	};

	const switchSourceTarget = () => {
		dispatch(clearReportingPeriodError());

		dispatch(
			getDataPoints({
				payload: {
					sources: [target, source],
					target: source,
					metadataSource: 'Silver',
					onlyConflicts: true,
				},
			}),
		);

		targetCompany && dispatch(storeSourceCompany(targetCompany));
		sourceCompany && dispatch(storeTargetCompany(sourceCompany));

		dispatch(setPeriod({ type: 'source', value: activeReportingPeriods.target }));
		dispatch(setPeriod({ type: 'target', value: activeReportingPeriods.source }));
	};

	// * Render
	return (
		<div className={s.container}>
			<SearchBar />

			<div className={s.inner}>
				<DiffInfo />

				<div className={s.buttons}>
					<ButtonSecondary
						onClick={getConflicts}
						disabled={!sourcesSelected}
						loading={status === RequestStatus.loading}
					>
						Get conflicts
					</ButtonSecondary>

					<ButtonSecondary
						className={cn(s.switchButton, !sourcesSelected && s.switchButton_disabled)}
						onClick={switchSourceTarget}
						disabled={!sourcesSelected}
						loading={status === RequestStatus.loading}
						icon={<ArrowsSVG />}
					>
						Switch source & target
					</ButtonSecondary>

					{sourceCompany && activeReportingPeriods['source'] && (
						<DumpCompany
							companyId={sourceCompany.id}
							reportingPeriodId={activeReportingPeriods['source']}
						/>
					)}

					{targetCompany && activeReportingPeriods['target'] && (
						<DumpCompany
							companyId={targetCompany.id}
							reportingPeriodId={activeReportingPeriods['target']}
						/>
					)}
				</div>
			</div>
		</div>
	);
});
