import { ResponsivePie } from '@nivo/pie';
import { FC, memo } from 'react';
import { NivoChartWrapper } from '../../../NivoChartWrapper';
import { PieStatList } from '../PieStatList';

interface Props {
	data: Record<string, number>;
	colorMap: Map<string, string>;
}

export const PieChart: FC<Props> = memo(props => {
	const {
		data, //
		colorMap,
	} = props;

	const addColorData = (data: { [key: string]: number }, colorMap: Map<string, string>) =>
		Object.entries(data).map(([name, value]) => ({
			id: name,
			label: name,
			value: value,
			color: colorMap.get(name),
		}));

	const dataWithColors = addColorData(data, colorMap);
	const sortedDataWithColors = [...dataWithColors].sort((a: any, b: any) => b.initialValue - a.initialValue);

	return (
		<>
			<NivoChartWrapper
				height="150px"
				width="150px"
			>
				<ResponsivePie
					data={sortedDataWithColors}
					margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
					innerRadius={0.65}
					colors={(d: any) => d.data.color || 'black'}
					enableArcLabels={false}
					enableArcLinkLabels={false}
				/>
			</NivoChartWrapper>

			<PieStatList dataWithColors={sortedDataWithColors} />
		</>
	);
});
