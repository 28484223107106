import { useWatch } from 'react-hook-form';

export const useDisabledReportLink = (propertyName: string, namePrefix: string, internalName: string) => {
	const isCompSustainabilityReportLink = propertyName === 'compSustainabilityReportLink';
	const isCompDEIReportLink = propertyName === 'compDEIReportLink';
	const isCompAnnualReportLink = propertyName === 'compAnnualReportLink';

	const compSustainabilityReport = useWatch({ name: `${namePrefix}compSustainabilityReport.${internalName}.value` });
	const compDEIReport = useWatch({ name: `${namePrefix}compDEIReport.${internalName}.value` });
	const compAnnualReport = useWatch({ name: `${namePrefix}compAnnualReport.${internalName}.value` });

	const sustainabilityLinkDisabled = isCompSustainabilityReportLink && (compSustainabilityReport === 'false' || compSustainabilityReport === null);
	const deiLinkDisabled = isCompDEIReportLink && (compDEIReport === 'false' || compDEIReport === null);
	const annualLinkDisabled = isCompAnnualReportLink && (compAnnualReport === 'false' || compAnnualReport === null);

	return sustainabilityLinkDisabled || deiLinkDisabled || annualLinkDisabled;
};
