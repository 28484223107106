import { FC } from 'react';
import { useAppDispatch } from 'src/app/redux/createAction';
import { actionsMergeToolDataPoints } from 'src/pages/MergeToolPage/_BLL/data_points/slice';
import { CompanyDataPoints } from 'src/pages/MergeToolPage/_BLL/data_points/types';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import s from './TransferChildrenModalContent.module.scss';

interface Props {
	toggleModal: () => void;
	companyConflicts: CompanyDataPoints[];
}

export const TransferChildrenModalContent: FC<Props> = props => {
	const {
		toggleModal, //
		companyConflicts,
	} = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { transferAssociatedChildren } = actionsMergeToolDataPoints;

	// * Source/Target data
	const unnamedGroup = companyConflicts.find(conflicts => conflicts.name === null);
	const associatedChildrenDeiIds = unnamedGroup?.properties.find(property => property.propertyName === 'CompAssociatedChildrenDEIID')?.values;
	const associatedChildrenNames = unnamedGroup?.properties.find(property => property.propertyName === 'CompAssociatedChildrenName')?.values;

	const sourceCompany = associatedChildrenDeiIds && associatedChildrenDeiIds[0].source;
	const targetCompany = associatedChildrenDeiIds && associatedChildrenDeiIds[1].source;

	// * Children info
	const sourceChildrenIds = associatedChildrenDeiIds && associatedChildrenDeiIds[0].datapoints[0].value?.split(';');
	const sourceChildrenNames = associatedChildrenNames && associatedChildrenNames[0].datapoints[0].value?.split(';');

	const targetChildrenIds = associatedChildrenDeiIds && associatedChildrenDeiIds[1].datapoints[0].value?.split(';');
	const targetChildrenNames = associatedChildrenNames && associatedChildrenNames[1].datapoints[0].value?.split(';');

	const sourceCompanies = sourceChildrenIds?.map((id, index) => `${id} ${sourceChildrenNames ? ': ' + sourceChildrenNames[index] : ''}`);
	const targetCompanies = targetChildrenIds?.map((id, index) => `${id} ${targetChildrenNames ? ': ' + targetChildrenNames[index] : ''}`);

	const transferChildren = (transferType: 'sourceToTarget' | 'targetToSource') => {
		if (sourceCompany && targetCompany) {
			transferType === 'sourceToTarget'
				? dispatch(
						transferAssociatedChildren({
							source: sourceCompany,
							target: targetCompany,
						}),
					)
				: dispatch(
						transferAssociatedChildren({
							source: targetCompany,
							target: sourceCompany,
						}),
					);
		}
		toggleModal();
	};

	// * Render
	return (
		<div className={s.container}>
			<div className={s.infoWrapper}>
				<div className={s.paper}>
					<div className={s.title}>{sourceCompany && `Source DEI: ${sourceCompany.companyId}`}</div>

					{sourceCompanies && sourceCompanies.map(company => <div key={company}>{company}</div>)}
				</div>

				<div className={s.buttonWrapper}>
					<ButtonPrimary onClick={() => transferChildren('sourceToTarget')}>{'transfer source to target =>'}</ButtonPrimary>

					<ButtonPrimary onClick={() => transferChildren('targetToSource')}>{'<= transfer target to source '}</ButtonPrimary>
				</div>

				<div className={s.paper}>
					<div className={s.title}>{targetCompany && `Target DEI: ${targetCompany.companyId}`}</div>
					{targetCompanies && targetCompanies.map(company => <div key={company}>{company}</div>)}
				</div>
			</div>
		</div>
	);
};
