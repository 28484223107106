import cn from 'classnames';
import { FC, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useAppSelector } from 'src/app/redux/createAction';
import { Dictionaries } from 'src/app/redux/slices/metaDictionaries/types';
import { InfoTooltip } from 'src/shared/ui/_tooltips/InfoTooltipAlt';
import { CompanyBaseInfo } from 'src/pages/MainPage/_BLL/main_search/types';
import { SourceType } from 'src/pages/MergeToolPage/_BLL/copper_menu/types';
import { CompanyDataPoints } from 'src/pages/MergeToolPage/_BLL/data_points/types';
import { dataPointsToString } from 'src/pages/MergeToolPage/lib/dataPointsToString';
import { TransferChildrenModalContent } from 'src/pages/MergeToolPage/ui/TransferChildrenModalContent';
import { RequestStatus } from 'src/shared/api/types';
import { ButtonSecondary } from 'src/shared/ui/_buttons/ButtonSecondary';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import { Switch } from 'src/shared/ui/_inputs/Switch';
import { ModalLegacy } from 'src/shared/ui/_modals/ModalLegacy';
import { checkForNewValues } from '../../lib/checkForNewValues';
import { onGroupClick } from '../../lib/onGroupClick';
import { onValueClick } from '../../lib/onValueClick';
import { MoveCopyButtons } from '../MoveCopyButtons/MoveCopyButtons';
import { checkIsEditable } from '../../lib/checkIsEditable';
import { generateInitialValues } from '../../lib/generateInitialValues';
import s from './CompanyMergeForm.module.scss';
import { firstChar2UpperCase } from 'src/shared/lib/string';
import { CancelLongQueryButton } from 'src/pages/MergeToolPage/ui/CancelLongQueryButton/CancelLongQueryButton';
import { SaveCompanyModalContent } from 'src/pages/MergeToolPage/ui/SaveCompanyModalContent/SaveCompanyModalContent';

interface Props {
	sources: SourceType[];
	targetCompany: { id: number; name: string };
	companyConflicts: CompanyDataPoints[];
	dictionaries: Dictionaries;
	sourceCompany?: CompanyBaseInfo;
}

export const CompanyMergeForm: FC<Props> = props => {
	const {
		sources, // ! only the first source is usable. TODO: Document.
		targetCompany,
		companyConflicts,
		dictionaries,
		sourceCompany, // ! Only silver to silver merge. Any company could be a source unlike copper.
	} = props;

	// * Selectors
	const reportingPeriods = useAppSelector(state => state.mergeToolCompanySearch.reportingPeriods);
	const status = useAppSelector(state => state.mergeToolDataPoints.status);
	const queryId = useAppSelector(state => state.mergeToolDataPoints.queryId);

	// * transfer children modal
	const [transferChildrenModalActive, setTransferChildrenModalActive] = useState(false);
	const toggleTransferChildrenModal = () => setTransferChildrenModalActive(prevState => !prevState);

	// * Save modal
	const [saveModalActive, setSaveModalActive] = useState(false);
	const toggleModal = () => setSaveModalActive(prevState => !prevState);

	// * Conditions
	const isCopperToSilver = sources[0] === 'Copper';
	const isSilverToSilver = sources[0] === 'Silver' && sourceCompany;
	const isReportingPeriodsSame = reportingPeriods.source === reportingPeriods.target;

	// * Form
	const initialValues = generateInitialValues(companyConflicts, targetCompany.id);

	const dataPointsFormMethods = useForm({
		defaultValues: initialValues,
	});

	const { control, setValue, watch } = dataPointsFormMethods;

	const formValues = watch();

	// * Render
	return (
		<FormProvider {...dataPointsFormMethods}>
			<ModalLegacy
				show={transferChildrenModalActive}
				toggleModal={toggleTransferChildrenModal}
				width={60}
				Content={
					<TransferChildrenModalContent
						toggleModal={toggleTransferChildrenModal}
						companyConflicts={companyConflicts}
					/>
				}
			/>

			<ModalLegacy
				show={saveModalActive}
				toggleModal={toggleModal}
				Content={
					<SaveCompanyModalContent
						dataPointGroups={companyConflicts}
						dictionaries={dictionaries}
						toggleModal={toggleModal}
						formValues={formValues}
					/>
				}
			/>

			<form>
				<div className={s.container}>
					<div>
						<div className={s.title}>{targetCompany.name}</div>
						<div className={s.caption}>DEI ID: {targetCompany.id}</div>
					</div>

					<MoveCopyButtons />

					{/* Table */}
					<div className={s.table}>
						<div className={s.header}>
							<div className={s.tabWrapper}>
								<div className={s.headerCell}>Result</div>
								<div className={s.headerCell}>Available values</div>
							</div>

							{/* Header */}
							<div className={s.cellWrapper}>
								<div className={s.cellBorders}>
									<div className={s.headerCell}>Data variable</div>
									<div className={s.headerCell}>{isSilverToSilver ? `Target DEI ID: ${targetCompany.id}` : 'Silver & Copper Target'}</div>
								</div>

								<div className={s.cellBorders}>
									{isCopperToSilver && (
										<>
											{sources.map(source => (
												<div
													className={s.headerCell}
													key={source}
												>
													{firstChar2UpperCase(source)}
												</div>
											))}
										</>
									)}

									{isSilverToSilver && (
										<>
											<div className={s.headerCell}>{`DEI ID: ${sourceCompany.id}`}</div>
											<div className={s.headerCell}>{`DEI ID: ${targetCompany.id}`}</div>
										</>
									)}
								</div>
							</div>
						</div>

						{companyConflicts.map(group => (
							<>
								{/* Tab name row */}
								<div className={s.tabWrapper}>
									<div className={s.tab}>{group.name ? group.name : 'Unnamed group'}</div>
									<div className={s.tab}>
										<ButtonSecondary
											className={s.button}
											onClick={() => onGroupClick(group, 'left', sources, formValues, setValue)}
										>
											Select LEFT column
										</ButtonSecondary>

										<ButtonSecondary
											className={s.button}
											onClick={() => onGroupClick(group, 'right', sources, formValues, setValue)}
										>
											Select RIGHT column
										</ButtonSecondary>
									</div>
								</div>

								{/* Values row */}
								{group.properties.map(property => {
									const { propertyName } = property;
									const isEditable = checkIsEditable(property, targetCompany.id);
									const hasUniqueValues = property.values[0].datapoints[0].hasUniqueValues;

									return (
										<div
											className={s.cellWrapper}
											key={propertyName}
										>
											{/* Value cells */}
											<Controller
												control={control}
												name={propertyName}
												render={({ field }) => (
													<>
														<div className={s.cellBorders}>
															<div className={cn(s.cell, s.cell_variable, !isEditable && s.cell_readOnly)}>
																<div className={s.cellInner}>
																	{property.displayName}
																	{!isEditable && <InfoTooltip title="auto calculated field. Not allowed to select" />}
																</div>

																<Switch
																	label="Move"
																	checked={field.value.formMeta.move}
																	disabled={hasUniqueValues || !isEditable}
																	onChange={value => setValue(`${propertyName}.formMeta.move`, value)}
																/>
															</div>

															<div className={s.cell}>
																{propertyName === 'CompAssociatedChildrenDEIID' || propertyName === 'CompAssociatedChildrenName' ? (
																	<>
																		<ButtonPrimary
																			onClick={toggleTransferChildrenModal}
																			disabled={!isReportingPeriodsSame} // ! No transfer when reporting periods are different.
																		>
																			transfer children
																		</ButtonPrimary>
																		{!isReportingPeriodsSame && <div className={s.cell_readOnly}>Transfer is forbidden when reporting periods are different.</div>}
																	</>
																) : (
																	<>
																		{isEditable ? (
																			<span
																				className={s.value}
																				dangerouslySetInnerHTML={{ __html: dataPointsToString(field.value.dataPoints, dictionaries) }}
																			/>
																		) : (
																			<span className={s.value}>...</span>
																		)}
																	</>
																)}
															</div>
														</div>

														<div className={s.cellBorders}>
															{sources.map((_, index) => {
																const currentValueAsString = dataPointsToString(field.value.dataPoints, dictionaries);
																const valueAsString = dataPointsToString(property.values[index].datapoints, dictionaries);
																const allowsMany = property.values[index].datapoints[0].allowsMany;

																// const disabled = valueAsString === targetCompany.id.toString();

																return (
																	<div
																		key={valueAsString}
																		className={cn(
																			s.inputCell,
																			!isEditable && s.inputCell_readOnly,
																			!checkForNewValues(currentValueAsString, valueAsString, '\n') && s.inputCell_selected,
																			allowsMany && s.inputCell_allowsMany,
																		)}
																		onClick={() =>
																			isEditable &&
																			onValueClick(
																				propertyName,
																				property.values[index].datapoints,
																				sources.map((_, index) => property.values[index].datapoints),
																				formValues,
																				setValue,
																			)
																		}
																	>
																		{/* TODO: Find a way to fix dangerouslySetInnerHTML */}
																		<span
																			className={s.value}
																			dangerouslySetInnerHTML={{ __html: valueAsString }}
																		/>
																	</div>
																);
															})}
														</div>
													</>
												)}
											/>
										</div>
									);
								})}
							</>
						))}
					</div>

					{queryId ? (
						<CancelLongQueryButton queryId={queryId} />
					) : (
						<ButtonPrimary
							onClick={toggleModal}
							disabled={status === RequestStatus.loading}
						>
							Save
						</ButtonPrimary>
					)}
				</div>
			</form>
		</FormProvider>
	);
};
