import { ComponentPropsWithoutRef, FC } from 'react';
import { ErrorMessage } from '../ErrorMessage';
import { Label } from '../Label';
import { InputWrapperProps } from './types';

type Props = InputWrapperProps & Pick<ComponentPropsWithoutRef<'div'>, 'className' | 'id' | 'children'>;

export const InputWrapper: FC<Props> = props => {
	const {
		label, //
		error,
		id,
		className,
		children,
	} = props;

	// * Render
	return (
		<div className={className}>
			{label && id && (
				<Label
					id={id}
					label={label}
				/>
			)}

			{children}

			{error && <ErrorMessage errorMessage={error} />}
		</div>
	);
};
