import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { ButtonSecondary } from 'src/shared/ui/_buttons/ButtonSecondary';
import { Heading } from 'src/shared/ui/Heading';
import { actionsDump } from '../_BLL/slice';
import s from './DumpCompany.module.scss';
import { Modal } from 'src/shared/ui/_modals/Modal';
import { RequestStatus } from 'src/shared/api/types';

interface Props {
	companyId: number;
	reportingPeriodId: number;
}

export const DumpCompany: FC<Props> = props => {
	const {
		companyId, //
		reportingPeriodId,
	} = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { dumpCompany, dumpPeople } = actionsDump;

	// * Selectors
	const status = useAppSelector(state => state.dump.status);
	const dictionaries = useAppSelector(state => state.metaDictionaries.dictionaries);

	const reportingPeriodName = dictionaries?.reportingPriods.find(period => period.id === reportingPeriodId)?.name;

	// * Dump
	const [companyLink, setCompanyLink] = useState<{ name: string; link: string }>();
	const [peopleLink, setPeopleLink] = useState<{ name: string; link: string }>();

	const onDumpClick = () => {
		dispatch(dumpCompany({ id: String(companyId), reporting_period_id: reportingPeriodId }))
			.unwrap()
			.then(res => {
				setCompanyLink({
					name: `${companyId}_${reportingPeriodId}_company_${Date.now()}.xlsx`,
					link: window.URL.createObjectURL(res),
				});
			})
			.catch(err => console.log(err));

		dispatch(dumpPeople({ id: String(companyId), reporting_period_id: reportingPeriodId }))
			.unwrap()
			.then(res => {
				setPeopleLink({
					name: `${companyId}_${reportingPeriodId}_people_${Date.now()}.xlsx`,
					link: window.URL.createObjectURL(res),
				});
			})
			.catch(err => console.log(err));
	};

	const closeModal = () => {
		setCompanyLink(undefined);
		setPeopleLink(undefined);
	};

	// * Render
	return (
		<>
			<Modal
				isOpen={Boolean(companyLink) && Boolean(peopleLink)}
				onClose={closeModal}
				zIndex={'var(--z-index-top-priority)'}
			>
				<Heading
					level={2}
					marginBottom="m"
				>
					Company dump ready
				</Heading>

				<div className={s.info}>
					<div>
						Company DEI ID: <strong>{companyId}</strong>
					</div>

					<div>
						Reporting period: <strong>{reportingPeriodName ?? reportingPeriodId}</strong>
					</div>
				</div>

				<div className={s.links}>
					{companyLink && (
						<a
							href={companyLink.link}
							download={companyLink.name}
						>
							company download
						</a>
					)}

					{peopleLink && (
						<a
							href={peopleLink.link}
							download={peopleLink.name}
						>
							people download
						</a>
					)}
				</div>
			</Modal>

			<ButtonSecondary
				onClick={onDumpClick}
				loading={status === RequestStatus.loading}
			>
				Dump - {companyId}
			</ButtonSecondary>
		</>
	);
};

// ! Auto downloading. Doesn't work in Chrome for some reason. Download only one file, then click() doesn't react.
// const onDumpClick = () => {
// 	dispatch(dumpCompany({ id: String(companyId), reporting_period_id: reportingPeriodId }))
// 		.unwrap()
// 		.then(res => {
// 			console.log('res :>> ', res);
// 			const url = window.URL.createObjectURL(res);
// 			const link = document.createElement('a');
// 			link.href = url;
// 			link.setAttribute('download', `${companyId}_${reportingPeriodId}_company_${Date.now()}.xlsx`);
// 			document.body.appendChild(link);
// 			link.click();
// 			document.body.removeChild(link);
// 		})
// 		.catch(err => console.log(err));

// 	dispatch(dumpPeople({ id: String(companyId), reporting_period_id: reportingPeriodId }))
// 		.unwrap()
// 		.then(res => {
// 			console.log('res :>> ', res);
// 			const url = window.URL.createObjectURL(res);
// 			const link = document.createElement('a');
// 			link.href = url;
// 			link.setAttribute('download', `${companyId}_${reportingPeriodId}_people_${Date.now()}.xlsx`);
// 			document.body.appendChild(link);
// 			link.click();
// 			document.body.removeChild(link);
// 		})
// 		.catch(err => console.log(err));
// };
