import { actionsNotifications } from 'src/app/providers/NotificationsProvider/_BLL/notifications/slice';
import { actionsAuth } from 'src/pages/AuthPage/_BLL/slice';

export const rtkHandleResponse = async <Data>(args: { response: Response; thunkAPI: any; displayNotifications?: boolean; type?: 'json' | 'blob' }): Promise<Data> => {
	const {
		response,
		thunkAPI: { rejectWithValue, dispatch },
		displayNotifications = true,
		type = 'json',
	} = args;

	let parsedResponse: any;

	if (type === 'json') {
		parsedResponse = response.status === 204 ? '' : await response.json();
	} else if (type === 'blob') {
		parsedResponse = response.status === 204 ? '' : await response.blob();
	}

	if (response.status < 200 || response.status >= 300) {
		if (response.status !== 404) {
			// There is no reason to show error on 404.
			if ('severity' in parsedResponse && 'message' in parsedResponse) {
				const { severity, message, incidentId } = parsedResponse;
				if (severity === 'Information') {
					displayNotifications &&
						dispatch(
							actionsNotifications.addNotification({
								type: 'info',
								message,
							}),
						);
				}

				if (severity === 'Warning') {
					displayNotifications &&
						dispatch(
							actionsNotifications.addNotification({
								type: 'warning',
								message,
							}),
						);
				}

				if (severity === 'Error') {
					displayNotifications &&
						dispatch(
							actionsNotifications.addNotification({
								type: 'error',
								message,
							}),
						);
				}

				if (severity === 'AccessDenied') {
					displayNotifications &&
						dispatch(
							actionsNotifications.addNotification({
								type: 'error',
								message,
							}),
						);
					dispatch(actionsAuth.logout());
				}

				if (severity === 'Critical' && parsedResponse.incidentId) {
					displayNotifications &&
						dispatch(
							actionsNotifications.setError({
								message,
								incidentId,
							}),
						);
				}
			} else {
				displayNotifications &&
					dispatch(
						actionsNotifications.addNotification({
							type: 'error',
							message: 'Unknown error',
						}),
					);
			}
			// throw instead of return is required for the thunk to stop its execution when error happens. Try/Catch is handled by createAsyncThunk.
			throw rejectWithValue(parsedResponse);
		}
		throw rejectWithValue(parsedResponse);
	} else {
		return parsedResponse as Data;
	}
};
