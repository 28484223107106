import { px2remTransformer, StyleProvider } from '@ant-design/cssinjs';
import { ConfigProvider } from 'antd';
import { FC } from 'react';
import { Provider } from 'react-redux';
import { store } from 'src/app/redux/store';
import { themeAnt } from 'src/shared/styles/themeAnt';
import { DivProps } from 'src/shared/types/props';

export const AppDecorator: FC<DivProps> = props => {
	const {
		children, //
	} = props;

	// https://ant.design/docs/react/compatible-style#rem-adaptation
	const px2rem = px2remTransformer({
		rootValue: 14,
	});

	// * Render
	return (
		<ConfigProvider theme={themeAnt}>
			<StyleProvider transformers={[px2rem]}>
				<Provider store={store}>{children}</Provider>
			</StyleProvider>
		</ConfigProvider>
	);
};
