import { FC } from 'react';
import { useAppSelector } from 'src/app/redux/createAction';
import { DICTItem } from 'src/app/redux/slices/metaDictionaries/types';
import { RequestStatus } from 'src/shared/api/types';
import { SelectDictionary } from 'src/shared/ui/_inputs/SelectDictionary';
import s from './DependableDualSelectFilter.module.scss';

interface Props {
	labelPrimary: string;
	optionsPrimary: Array<DICTItem | { id: undefined; name: string }>;
	selectedPrimaryId: number | null;
	setSelectedPrimary: (id: number | undefined) => void;

	labelSecondary: string;
	optionsSecondary: Array<DICTItem | { id: undefined; name: string }> | null;
	selectedSecondaryId: number | null;
	setSelectedSecondary: (id: number | undefined) => void;
}

export const DependableDualSelectFilter: FC<Props> = props => {
	const {
		labelPrimary, //
		optionsPrimary,
		selectedPrimaryId,
		setSelectedPrimary,

		labelSecondary,
		optionsSecondary,
		selectedSecondaryId,
		setSelectedSecondary,
	} = props;

	// * Selectors
	const status = useAppSelector(state => state.qcAutomation.status);

	return (
		<>
			<div className={s.container}>
				{optionsPrimary && (
					<div className={s.filter}>
						<span>{labelPrimary}:</span>

						<SelectDictionary
							value={selectedPrimaryId}
							options={optionsPrimary}
							onChange={value => setSelectedPrimary(value)}
							disabled={status === RequestStatus.loading}
						/>
					</div>
				)}

				<div className={s.filter}>
					<span>{labelSecondary}:</span>

					<SelectDictionary
						value={selectedSecondaryId}
						options={optionsSecondary ? optionsSecondary : []}
						onChange={value => setSelectedSecondary(value)}
						disabled={!optionsSecondary || status === RequestStatus.loading}
					/>
				</div>
			</div>
		</>
	);
};
