import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { RouterPath } from 'src/app/router/AppRouter';
import { RequestStatus } from 'src/shared/api/types';
import { Heading } from 'src/shared/ui/Heading';
import { IPagination } from 'src/shared/ui/Pagination/types';
import { PaginationNew } from 'src/shared/ui/PaginationNew';
import { Preloader } from 'src/shared/ui/_loaders/Preloader';
import { TableSticky } from 'src/shared/ui/_tables/TableSticky';
import { actionsQcMeta } from '../../_BLL/slice';
import { WidgetList } from '../../_BLL/types';
import { generateColumns } from '../../lib/generateColumns';
import s from './QCContent.module.scss';
import { DEFAULT_PAGINATION } from './consts';
import { getColumnNames } from './lib/getColumnNames/getColumnNames';
import { Widget } from './types';

interface Props {
	widget?: Widget;
	list: WidgetList;
}

export const QCContent: FC<Props> = props => {
	const {
		widget, //
		list,
	} = props;

	const companies = list?.result.companies ?? [];
	const people = list?.result.people ?? [];
	const duplicates = list?.result.duplicates ?? [];

	// * Actions
	const dispatch = useAppDispatch();

	const { clearSelectedList, setPagination } = actionsQcMeta;

	// * Selectors
	const metaStatus = useAppSelector(state => state.qcDashboard.status.meta);
	const statsStatus = useAppSelector(state => state.qcDashboard.status.stats);
	const listStatus = useAppSelector(state => state.qcDashboard.status.list);
	const widgetState = useAppSelector(state => state.qcDashboard.widget);

	const isLoadingMeta = metaStatus === RequestStatus.loading;
	const isLoadingStatus = statsStatus === RequestStatus.loading;
	const isLoadingList = listStatus === RequestStatus.loading;

	const isLoading = isLoadingMeta || isLoadingStatus || isLoadingList;

	const onCompanyRowClick = (_: React.MouseEvent<HTMLDivElement, MouseEvent>, rowInfo: any) => {
		rowInfo.companyId && window.open(RouterPath.dashboard + `/${rowInfo.companyId}/0`, '_blank');
	};

	const onPersonRowClick = (_: React.MouseEvent<HTMLDivElement, MouseEvent>, rowInfo: any) => {
		let menuNum = '0';
		if (rowInfo.role) {
			if (rowInfo.role === 'Board') menuNum = '4';
			if (rowInfo.role === 'Executive') menuNum = '5';
		}

		if ('personName' in rowInfo) {
			menuNum += `?personName=${rowInfo.personName}`;
		}

		window.open(RouterPath.dashboard + `/${rowInfo.companyId}/${menuNum}`, '_blank');
	};

	const changePage = (pagination: IPagination) => {
		const finalPagination = pagination ?? DEFAULT_PAGINATION;
		dispatch(clearSelectedList(widgetState));
		dispatch(setPagination({ pagination: finalPagination }));
	};

	// * Render
	return (
		<div className={s.container}>
			<h1>{widget?.displayName}</h1>

			{isLoading ? (
				<div className={s.preloader}>
					<Preloader size="large" />
				</div>
			) : (
				<>
					<div>
						<div>Companies: {widget?.companiesCount}</div>
						<div>People: {widget?.peopleCount}</div>
						{duplicates.length > 0 && <div>Duplicates: {duplicates.length}</div>}
					</div>

					{(companies.length > 0 || people.length > 0 || duplicates.length > 0) && !isLoading ? (
						<>
							<div className={s.card}>
								<PaginationNew
									pageSize={list?.result.pagination.pageSize ?? 10}
									pageCount={list?.result.pagination.pageCount ?? 1}
									current={list?.result.pagination.pageIndex ?? 0}
									changePage={changePage}
									showSizeChanger
								/>

								{companies.length > 0 && (
									<TableSticky
										columns={generateColumns([getColumnNames(companies[0])])}
										data={companies}
										onRowClick={onCompanyRowClick}
									/>
								)}

								{people.length > 0 && (
									<TableSticky
										columns={generateColumns([getColumnNames(people[0])])}
										data={people}
										onRowClick={onPersonRowClick}
									/>
								)}

								{duplicates.length > 0 && (
									<TableSticky
										columns={generateColumns([getColumnNames(duplicates[0])])}
										data={duplicates}
										onRowClick={onCompanyRowClick}
									/>
								)}
							</div>
						</>
					) : (
						<Heading level={3}>No {widget?.displayName} found</Heading>
					)}
				</>
			)}
		</div>
	);
};
