import { useEffect, useState } from 'react';
import { PageDecorator } from 'src/app/decorators';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { SourceType } from 'src/pages/MergeToolPage/_BLL/copper_menu/types';
import { actionsMergeToolDataPoints } from 'src/pages/MergeToolPage/_BLL/data_points/slice';
import { CopperMode } from '../CopperMode';
import { SelectDictionary } from 'src/shared/ui/_inputs/SelectDictionary';
import s from './MergeToolPage.module.scss';
import { MetaContextProvider } from 'src/app/context/useMetaContext';
import { SilverMode } from '../SilverMode';

export const MergeToolPage = () => {
	const [sources, setSources] = useState<[SourceType, SourceType]>(['Silver', 'Copper']);

	const selectSource = (source: SourceType) => {
		setSources([source, sources[1]]);
	};

	// * Selectors
	const companyMeta = useAppSelector(state => state.metaDictionaries.meta.company);
	const personMeta = useAppSelector(state => state.metaDictionaries.meta.person);
	const dictionaries = useAppSelector(state => state.metaDictionaries.dictionaries);

	// * Actions
	const dispatch = useAppDispatch();
	const { clear: dataPointsClear } = actionsMergeToolDataPoints;

	// * Conditions
	const copperMode = sources[0] === 'Copper' && dictionaries;
	const silverMode = sources[0] === 'Silver' && dictionaries;

	useEffect(() => {
		dispatch(dataPointsClear());
	}, [sources]);

	useEffect(() => {
		return () => {
			dispatch(dataPointsClear());
		};
	}, []);

	// * Render
	return (
		<PageDecorator>
			{companyMeta && personMeta && dictionaries && (
				<MetaContextProvider
					companyMeta={companyMeta}
					personMeta={personMeta}
					dictionaries={dictionaries}
				>
					<div className={s.content}>
						{/* Mode select */}
						<div className={s.mode}>
							<SelectDictionary
								options={[
									{
										id: 'Silver',
										name: 'Silver',
									},
									{
										id: 'Copper',
										name: 'Copper',
									},
								]}
								onChange={value => selectSource(value)}
								value={sources[0]}
							/>
							<span>to silver</span>
						</div>

						{copperMode && (
							<CopperMode
								sources={sources}
								dictionaries={dictionaries}
							/>
						)}

						{silverMode && (
							<SilverMode
								sources={sources}
								dictionaries={dictionaries}
							/>
						)}
					</div>
				</MetaContextProvider>
			)}
		</PageDecorator>
	);
};
