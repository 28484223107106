import cn from 'classnames';
import { ComponentPropsWithoutRef, FC, ReactElement, ReactNode } from 'react';
import InfoSvg from 'src/shared/assets/svg/info.svg';
import { Tooltip } from 'src/shared/ui/_tooltips/Tooltip';
import s from './InfoTooltipAlt.module.scss';

type IconVariant = 'default' | 'error';

interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'title'> {
	title: ReactNode;
	Icon?: ReactElement;
	iconVariant?: IconVariant;
}

export const InfoTooltip: FC<Props> = props => {
	const {
		title, //
		Icon,
		iconVariant = 'default',
		className,
	} = props;

	// * Render
	return (
		<div className={cn(s.container, s[`container_${iconVariant}`], className)}>
			<Tooltip title={title}>{Icon ? Icon : <InfoSvg />}</Tooltip>
		</div>
	);
};
