import { Switch as AntSwitch, SwitchProps } from 'antd';
import cn from 'classnames';
import { FC, useId } from 'react';
import { Label } from '../_common/Label/Label';
import s from './Switch.module.scss';

interface Props extends SwitchProps {
	label?: string;
	labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}

export const Switch: FC<Props> = props => {
	const {
		label, //
		labelPlacement = 'end',
		className,
		...switchProps
	} = props;

	const id = useId();

	const containerStyles = label && cn(s.container, s[`container_${labelPlacement}`], className);

	// * Render
	return (
		<div className={containerStyles}>
			{label && (
				<Label
					label={label}
					id={id}
				/>
			)}
			<AntSwitch {...switchProps} />
		</div>
	);
};
