import { SinglePerson } from '../types';
import { DataPointSource, DataPointSourceUrl, ReferenceDate } from '../../dashboard/types/types';
import _ from 'lodash';

export interface SourceInfo {
	source: DataPointSource;
	sourceUrl: DataPointSourceUrl;
	referenceDate: ReferenceDate;
}

export const getPeopleWithUpdatedSource = (peopleIds: number[], people: SinglePerson[], sourceInfo: SourceInfo) => {
	const peopleToUpdate = people.filter(person => peopleIds.includes(person.id));

	if (peopleToUpdate.length > 0) {
		const personPropertiesKeys = Object.keys(peopleToUpdate[0].properties).map(key => key);

		return peopleToUpdate.map(person => {
			const updatedPerson = _.cloneDeep(person);

			personPropertiesKeys.forEach(propertyKey => {
				updatedPerson.properties[propertyKey].dataPoint.source = sourceInfo.source;
				updatedPerson.properties[propertyKey].dataPoint.sourceUrl = sourceInfo.sourceUrl;
				updatedPerson.properties[propertyKey].dataPoint.referenceDate = sourceInfo.referenceDate;
			});

			return updatedPerson;
		});
	} else {
		return [];
	}
};
