import { Input, InputProps } from 'antd';
import { FC, useId } from 'react';
import { InputWrapper } from '../_common/InputWrapper';
import s from './StringInput.module.scss';

interface Props extends InputProps {
	label?: string;
	error?: string;
}

export const StringInput: FC<Props> = props => {
	const {
		label, //
		error,
		status,
		className,
		...rest
	} = props;

	const id = useId();

	// * Render
	return (
		<InputWrapper
			label={label}
			error={error}
			className={className}
			id={id}
		>
			<Input
				{...rest}
				className={s.input}
				id={id}
				status={error ? 'error' : status}
			/>
		</InputWrapper>
	);
};
