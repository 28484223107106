import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CompanyBaseInfo } from 'src/pages/MainPage/_BLL/main_search/types';
import { rtkApiRequest } from 'src/shared/api/api';
import { RequestStatus } from 'src/shared/api/types';
import { PaginationI } from 'src/shared/ui/Pagination';

const NAME = 'main_search_results';

const getMainSearchResults = createAsyncThunk(`${NAME}/getMainSearchResults`, async (payload: { pageIndex: number, pageSize: number, searchPrefix: string }, thunkAPI) => {
	const {searchPrefix, pageIndex, pageSize} = payload;

	const params = {
		search_prefix: searchPrefix,
		page_index: pageIndex,
		page_size: pageSize
	};

	return await rtkApiRequest.rtkGETRequest<any>({
		url: 'companies',
		params,
		thunkAPI
	});
});

// * Reducer
interface State {
	items: CompanyBaseInfo[] | null;
	pagination: PaginationI;
	status: RequestStatus;
}

export const initialState: State = {
	items: null,
	pagination: {
		pageIndex: 0,
		pageSize: 10,
		pageCount: 0,
	},
	status: RequestStatus.still
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		storeSearchResults: (state, action: { payload: { companies: CompanyBaseInfo[], pagination: PaginationI } }) => {
			const {companies, pagination} = action.payload;

			state.items = companies;
			state.pagination = {
				...state.pagination,
				pageCount: pagination ? pagination.pageCount : state.pagination.pageCount
			};
			state.status = RequestStatus.still;
		},
		setPagination: (state, action: { payload: { pagination: PaginationI } }) => {
			const {pagination} = action.payload;

			state.pagination = pagination;
		},
		clearAll: state => {
			state.items = initialState.items;
			state.pagination = initialState.pagination;
			state.status = initialState.status;
		},
	},
	extraReducers: builder => {
		builder.addCase(getMainSearchResults.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getMainSearchResults.fulfilled, (state, action) => {
			const {companies, pagination} = action.payload;

			state.items = companies;
			state.pagination = {
				...state.pagination,
				pageCount: pagination ? pagination.pageCount : state.pagination.pageCount
			};
			state.status = RequestStatus.still;
		});
		builder.addCase(getMainSearchResults.rejected, state => {
			state.status = RequestStatus.failed;
		});
	}
});

export const actionsMainSearchResults = {
	...slice.actions,
	getMainSearchResults
};
