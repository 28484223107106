import { FC, memo } from 'react';
import { useAppSelector } from 'src/app/redux/createAction';
import { ToGoldButton } from 'src/features/ToGoldButton';
import { RequestStatus } from 'src/shared/api/types';
import SilverLogoSVG from 'src/shared/assets/svg/silverLogo.svg';
import { Logo } from 'src/shared/ui/Logo';
import { HeaderProfile } from 'src/widgets/Header/ui/HeaderProfile';
import s from './Header.module.scss';
import { Link } from 'react-router-dom';

interface Props {
	companyId?: number | null;
}

export const Header: FC<Props> = memo(props => {
	const {
		companyId, //
	} = props;

	// * Selectors
	const { userData, status } = useAppSelector(state => state.auth);

	// * Render
	return (
		<header className={s.header}>
			<div className={s.header__left}>
				<Link to={'/main'}>
					<Logo className={s.logo} />
				</Link>

				{companyId && (
					<div className={s.companyId}>
						<span>
							DEI ID: <span className={s.companyId__id}>{companyId}</span>
						</span>
					</div>
				)}

				<SilverLogoSVG className={s.silverLogo} />

				{userData && userData.accessRights.includes('UpdateCompaniesToGold') && status === RequestStatus.still && <ToGoldButton />}
			</div>

			{userData && <HeaderProfile userData={userData} />}
		</header>
	);
});
