// Separates an array to groups of smaller array in array.
export const createGroups = <Item>(array: Array<Item>, numberOfGroups: number, numberOfValuesInGroup: number): Item[][] => {
	const arrayOfGroups: Array<Item[]> = [];

	if (array.length === 0) {
		return [];
	}

	for (let i = 0; i < numberOfGroups; i++) {
		const group = array.slice(i * numberOfValuesInGroup, i * numberOfValuesInGroup + numberOfValuesInGroup);
		arrayOfGroups.push(group);
	}

	return arrayOfGroups;
};
