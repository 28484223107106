import { DiffSource, FormValues, SavePayload } from 'src/pages/MergeToolPage/_BLL/data_points/types';

export const formValuesToSubmitPayload = (target: DiffSource, formValues: FormValues): SavePayload => {
	const values = Object.values(formValues).map(values => (
		values.dataPoints.map(dataPoint => (
			{
				...dataPoint
				// datapointID: dataPoint.datapointId,
				// value: dataPoint.value,
				// ? status: dataPoint.status // ? What with the status?
			}
		))
	)).flat();

	return {
		target,
		values
	};
};
