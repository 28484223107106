import randomColor from 'randomcolor';

// export const MarriedColorMap = new Map([
//   ['Married', theme.palette.race.middleEastern],
//   ['Single', theme.palette.race.hispanic]
// ]);

export const createRandomColorMap = (obj: { [key: string]: string | number }) => {
	const map: Array<[string, string]> = [];

	for (const key of Object.keys(obj)) {
		map.push([key, randomColor()]);
	}

	return new Map(map);
};
