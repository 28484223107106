import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { UploadToGoldModalContent } from 'src/features/ToGoldButton/ui/UploadToGoldModalContent';
import { RequestStatus } from 'src/shared/api/types';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import { Modal } from 'src/shared/ui/_modals/Modal';
import { actionsUploadToGold } from '../../../../pages/DashboardPage/_BLL/uploadToGold/slice';
import { useDashboardStatus } from '../../../../pages/DashboardPage/_hooks/useDashboardStatus';

export const ToGoldButton = () => {
	const params = useParams() as { id: string };

	// * Actions
	const dispatch = useAppDispatch();
	const { closeModal, uploadToGold, cancelUploadToGold } = actionsUploadToGold;

	// * Selectors
	const uploadedCompaniesIds = useAppSelector(state => state.uploadToGold.uploadedCompaniesIds);
	const reportingPeriodId = useAppSelector(state => state.dashboardCompany.currentCompany?.reportingPeriodId);
	const uploadQueryId = useAppSelector(state => state.uploadToGold.uploadQueryId);

	const status = useDashboardStatus();

	// * Upload to gold
	const uploadToGoldHandler = () => {
		reportingPeriodId &&
			dispatch(
				uploadToGold({
					companyId: params.id,
					reportingPeriodId,
					showSuccessNotification: true,
				}),
			);
	};

	// * Cancel upload to gold
	const cancelUploadToGoldHandler = () => {
		if (uploadQueryId) {
			dispatch(cancelUploadToGold({ query_id: uploadQueryId }));
		}
	};

	// * Conditions
	const isCompanyDashboard = location.pathname.includes(`/dashboard/${params.id}`);

	// * Render
	return (
		<>
			<Modal
				isOpen={!!uploadedCompaniesIds && uploadedCompaniesIds.length > 0}
				onClose={() => dispatch(closeModal())}
			>
				<UploadToGoldModalContent />
			</Modal>

			{isCompanyDashboard && (
				<>
					{uploadQueryId ? (
						<ButtonPrimary
							onClick={cancelUploadToGoldHandler}
							disabled={status === RequestStatus.loading || reportingPeriodId === undefined}
						>
							Cancel upload
						</ButtonPrimary>
					) : (
						<ButtonPrimary
							onClick={uploadToGoldHandler}
							disabled={status === RequestStatus.loading || reportingPeriodId === undefined}
						>
							Upload changes to gold
						</ButtonPrimary>
					)}
				</>
			)}
		</>
	);
};
