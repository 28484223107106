import { ChangeEvent, FC } from 'react';
import { useAppSelector } from 'src/app/redux/createAction';
import { RequestStatus } from 'src/shared/api/types';
import { StringInput } from 'src/shared/ui/_inputs/StringInput';
import s from './SingleStringFilter.module.scss';

interface Props {
	label: string;
	value: string | null;
	onChange: (value: string | null) => void;
}

export const SingleStringFilter: FC<Props> = props => {
	const {
		label, //
		value,
		onChange,
	} = props;

	// * Selectors
	const status = useAppSelector(state => state.qcAutomation.status);

	const convertedValue = value ? value : '';

	const onChangeConvert = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.currentTarget.value;
		const convertedValue = value === undefined || value === '' ? null : value.toString();
		onChange(convertedValue);
	};

	// * Render
	return (
		<div className={s.container}>
			<span>{label}:</span>

			<StringInput
				value={convertedValue}
				onChange={onChangeConvert}
				disabled={status === RequestStatus.loading}
			/>
		</div>
	);
};
