import { Checkbox as AntCheckbox, CheckboxProps } from 'antd';
import { FC } from 'react';

interface Props extends CheckboxProps {
	label?: string;
}

export const Checkbox: FC<Props> = props => {
	const {
		label, //
		...rest
	} = props;

	return <AntCheckbox {...rest}>{label}</AntCheckbox>;
};
