import { FC } from 'react';
import { OutlierStatus } from 'src/pages/QcAutomationPage/_BLL/types';
import s from './StatusCircle.module.scss';

interface Props {
	status?: OutlierStatus;
	acceptedCount?: number;
	errorCount?: number;
}

export const StatusCircle: FC<Props> = props => {
	const {
		status, //
		acceptedCount,
		errorCount,
	} = props;

	const getOutlierStatus = (status: OutlierStatus) => {
		if (status === 'Warning' || status === 'Error') {
			return status.toLowerCase() as 'warning' | 'error';
		} else {
			return 'success';
		}
	};

	const getStatusBasedOnCount = (acceptedCount: number, errorCount: number) => {
		if (acceptedCount > 0 && errorCount === 0) {
			return 'success' as const;
		} else if (acceptedCount === 0 && errorCount > 0) {
			return 'error' as const;
		} else {
			return 'warning' as const;
		}
	};

	const getColor = () => {
		if (status) {
			return `var(--${getOutlierStatus(status)}-main`;
		} else if (typeof acceptedCount === 'number' && typeof errorCount === 'number') {
			return `var(--${getStatusBasedOnCount(acceptedCount, errorCount)}-main`;
		}
	};

	// * Render
	return (
		<div
			className={s.container}
			style={{
				backgroundColor: getColor(),
			}}
		/>
	);
};
