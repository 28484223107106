import { FC, memo } from 'react';
import { KpiCompaniesDataPoints } from 'src/pages/MainPage/_BLL/kpi/types';
import s from './CompaniesDatapoints.module.scss';

interface Props {
	data: KpiCompaniesDataPoints | null;
}

export const CompaniesDatapoints: FC<Props> = memo(props => {
	const {
		data, //
	} = props;

	if (data === null) return null;

	const { totalWithAdvancedRating, totalWithAdvancedRatingNoAssociation } = data;

	return (
		<>
			<div className={s.caption}>Total number of companies with advanced rating</div>
			<div className={s.rating}>{totalWithAdvancedRating.toLocaleString('ru-RU')}</div>

			<div className={s.caption}>Total number of companies with advanced rating without association</div>
			<div className={s.rating}>{totalWithAdvancedRatingNoAssociation.toLocaleString('ru-RU')}</div>
		</>
	);
});
