import { FC, memo, useMemo } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { KpiProductivity } from 'src/pages/MainPage/_BLL/kpi/types';
import { TimePeriodPicker } from 'src/pages/MainPage/ui/Kpi/ui/cardsContent/table/TimePeriodPicker';
import { UrlKPI } from 'src/shared/api/types';
import { SimpleTable } from 'src/shared/ui/_tables/SimpleTable';
import s from './KpiProductivityTable.module.scss';

interface Props {
	title: string;
	data: KpiProductivity;
	// Props for date picker
	partUrl?: UrlKPI;
	dates?: [string | null, string | null];
}

export const KpiProductivityTable: FC<Props> = memo(props => {
	const {
		title, //
		data,
		partUrl,
		dates,
	} = props;

	const { researchers } = data;

	const columns = useMemo(
		() => [
			{
				Header: 'User',
				accessor: 'user',
				style: {
					fontWeight: 500,
				},
			},
			{
				Header: 'Data points entered',
				accessor: 'dataPointsEntered',
			},
			{
				Header: 'Data points modified',
				accessor: 'dataPointsModified',
			},
			{
				Header: 'Exec/Board Data points entered',
				accessor: 'personalDataPointsEntered',
			},
			{
				Header: 'Exec/Board Data points modified',
				accessor: 'personalDataPointsModified',
			},
			{
				Header: 'Companies updated',
				accessor: 'companiesUpdated',
			},
		],
		[],
	);

	return (
		<>
			<div className={s.title}>{title}</div>

			{partUrl && dates && (
				<TimePeriodPicker
					partUrl={partUrl}
					dates={dates}
				/>
			)}

			<SimpleTable
				columns={columns}
				data={researchers}
			/>
		</>
	);
});
