import { Company, SourceType } from 'src/pages/MergeToolPage/_BLL/copper_menu/types';
import { PaginationI } from 'src/shared/ui/Pagination';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rtkApiRequest } from 'src/shared/api/api';

const NAME = 'mergetool/copper_menu';

const getCompanies = createAsyncThunk(`${NAME}/getCompaniesWorker`, async (arg: { sources: SourceType[], lastCompanyId?: number }, thunkAPI) => {
	const {sources, lastCompanyId} = arg;
	const {signal} = thunkAPI;

	if (lastCompanyId) {
		return await rtkApiRequest.rtkGETRequest<Company[]>({
			url: UrlAPI.mergeToolCompanies,
			params: {
				sources: 'copper,silver',
				with_conflicts: 'true',
				last_returned_id: lastCompanyId
			},
			thunkAPI,
			signal
		});
	} else {
		return await rtkApiRequest.rtkGETRequest<Company[]>({
			url: UrlAPI.mergeToolCompanies,
			params: {
				sources: 'copper,silver',
				with_conflicts: 'true'
			},
			thunkAPI,
			signal
		});
	}
});

interface State {
	companies: Company[] | null;
	selectedCompany: Company | null;
	pagination: Omit<PaginationI, 'pageCount'>;
	status: RequestStatus;
}

export const initialState: State = {
	companies: null,
	selectedCompany: null,
	pagination: {
		pageIndex: 0,
		pageSize: 10,
	},
	status: RequestStatus.still
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		selectCompany: (state, action: PayloadAction<{ companies: Company[], id: number }>) => {
			const {companies, id} = action.payload;
			const selectedCompany = companies.find(company => company.id === id);

			state.selectedCompany = selectedCompany ? selectedCompany : null;
		},
		removeCompany: (state, action: PayloadAction<{ companyId: number }>) => {
			const {companyId} = action.payload;
			const remainingCompanies = state.companies && state.companies.filter(company => company.id !== companyId);

			state.selectedCompany = null;
			state.companies = remainingCompanies;
		},
		refreshCompany: (state, action: PayloadAction<{ dataPointsNumber: number }>) => {
			const {dataPointsNumber} = action.payload;

			if (state.selectedCompany !== null && state.companies !== null) {
				state.companies = state.companies.map(company => {
					if (company.id === (state.selectedCompany && state.selectedCompany.id)) {
						return {
							...company,
							conflicts: dataPointsNumber
						};
					} else {
						return company;
					}
				});

				state.selectedCompany = {
					...state.selectedCompany,
					conflicts: dataPointsNumber
				};
			}
		},
		clear: state => {
			state.companies = initialState.companies;
			state.selectedCompany = initialState.selectedCompany;
			state.pagination = initialState.pagination;
			state.status = initialState.status;
		}
	},
	extraReducers: builder => {
		builder.addCase(getCompanies.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getCompanies.fulfilled, (state, action) => {
			const {lastCompanyId} = action.meta.arg;

			state.status = RequestStatus.still;

			if (lastCompanyId) {
				state.companies = state.companies ? [...state.companies, ...action.payload] : action.payload;
			} else {
				state.companies = action.payload;
			}
		});
		builder.addCase(getCompanies.rejected, state => {
			state.status = RequestStatus.failed;
		});
	}
});

export const mergeToolCopperMenuAction = {
	...slice.actions,
	getCompanies
}
