import { useAppSelector } from 'src/app/redux/createAction';
import s from './MergeDump.module.scss';
import { DumpCompany } from 'src/features/DumpCompany';

export const MergeDump = () => {
	// * Selectors
	const sourceCompany = useAppSelector(state => state.mergeToolCompanySearch.sourceCompany);
	const targetCompany = useAppSelector(state => state.mergeToolCompanySearch.targetCompany);
	const reportingPeriods = useAppSelector(state => state.mergeToolCompanySearch.reportingPeriods);

	const sourceReportingPeriod = reportingPeriods['source'];
	const targetReportingPeriod = reportingPeriods['target'];

	const sourceExists = sourceCompany && sourceReportingPeriod;
	const targetExists = targetCompany && targetReportingPeriod;

	// * Render
	if (sourceExists || targetExists) {
		return (
			<div className={s.container}>
				{sourceExists && (
					<DumpCompany
						companyId={sourceCompany.id}
						reportingPeriodId={sourceReportingPeriod}
					/>
				)}

				{targetExists && (
					<DumpCompany
						companyId={targetCompany.id}
						reportingPeriodId={targetReportingPeriod}
					/>
				)}
			</div>
		);
	} else {
		return null;
	}
};
