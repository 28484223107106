import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch } from 'src/app/redux/createAction';
import { Dictionaries } from 'src/app/redux/slices/metaDictionaries/types';
import { actionsMergeToolDataPoints } from 'src/pages/MergeToolPage/_BLL/data_points/slice';
import { CompanyDataPoints, FormValues } from 'src/pages/MergeToolPage/_BLL/data_points/types';
import { dataPointsToString } from 'src/pages/MergeToolPage/lib/dataPointsToString';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import s from './SaveCompanyModalContent.module.scss';

interface Props {
	dataPointGroups: CompanyDataPoints[];
	dictionaries: Dictionaries;
	toggleModal: () => void;
	formValues: FormValues;
}

export const SaveCompanyModalContent: FC<Props> = props => {
	const {
		dataPointGroups, //
		dictionaries,
		toggleModal,
		formValues,
	} = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { mergeCompany } = actionsMergeToolDataPoints;

	const findValue = (propertyName: string, formValues: FormValues): string => {
		const valueEntry = Object.entries(formValues).find(entry => entry[0] === propertyName);
		const values = valueEntry && valueEntry[1];

		return values ? dataPointsToString(values.dataPoints, dictionaries) : 'value not found!';
	};

	const { handleSubmit } = useFormContext();

	const onSubmit = (values: FormValues) => {
		dispatch(mergeCompany({ formValues: values, isNewPeriod: false }));
		toggleModal();
	};

	const submitForm = handleSubmit(onSubmit);

	// * Render
	return (
		<div className={s.container}>
			<div className={s.propertyList}>
				{dataPointGroups.map(group => (
					<div
						className={s.group}
						key={group.name}
					>
						<div className={s.group__name}>{group.name}</div>

						{group.properties.map(property => {
							const value = findValue(property.propertyName, formValues);
							const move = formValues[property.propertyName].formMeta.move;

							return (
								<div
									className={s.property}
									key={property.displayName}
								>
									<b className={s.displayName}>{property.displayName}:</b>
									{move && <b className={s.move}>Move</b>}
									<div
										className={s.value}
										dangerouslySetInnerHTML={{ __html: value }}
									/>
								</div>
							);
						})}
					</div>
				))}
			</div>

			<ButtonPrimary onClick={submitForm}>Submit</ButtonPrimary>
		</div>
	);
};
