import { Breadcrumb } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PageDecorator } from 'src/app/decorators';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import InfoSVG from 'src/shared/assets/svg/info.svg';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import { StringInput } from 'src/shared/ui/_inputs/StringInput';
import { InfoTooltip } from 'src/shared/ui/_tooltips/InfoTooltipAlt';
import { actionsQcMeta } from '../../_BLL/slice';
import { useUrlFilterParams } from '../../hooks/useUrlFilterParams';
import { MetaItemWithStats } from '../../types';
import { AllWidgets } from '../AllWidgets';
import { Filters } from '../Filters/Filters';
import s from './QcDashboardPage.module.scss';
import { generateWidgetParams } from './lib/generateStatsParams/generateStatsParams';

let aborted = false;
let promise: any;

export const QcDashboardPage: FC = () => {
	// * History API
	const { reportingPeriodState, regionState, industryState, DMState } = useUrlFilterParams();

	// * Selectors
	const pagination = useAppSelector(state => state.qcDashboard.pagination);

	const metaData = useAppSelector(state => state.qcDashboard.metaData);
	const widgetsStats = useAppSelector(state => state.qcDashboard.widgetsStats);
	const widgetState = useAppSelector(state => state.qcDashboard.widget);

	const sendToURL = { reportingPeriod: reportingPeriodState, region: regionState, industry: industryState, DM: DMState };

	// * Actions
	const dispatch = useAppDispatch();

	const {
		getMetadata, //
		getStats,
		clearAllStats,
	} = actionsQcMeta;

	const widgetParams = generateWidgetParams({
		reportingPeriodState,
		regionState,
		industryState,
		DMState,
		pagination,
	});

	// * Initialize
	useEffect(() => {
		if (metaData.length === 0) dispatch(getMetadata());
	}, []);

	const fetchStatsRec = (names: string[], curr: number) => {
		if (curr >= names.length) {
			return;
		}

		if (!aborted) {
			promise = dispatch(getStats({ params: widgetParams, qcType: names[curr] }));
			promise &&
				promise.then(() => {
					!aborted && fetchStatsRec(names, curr + 1);
				});
		} else {
			return;
		}
	};

	const fetchStats = () => {
		aborted = false;
		const names = metaData.map(element => element.name);
		fetchStatsRec(names, 0);
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			fetchStats();
			clearTimeout(timeout);
		}, 0);

		return () => {
			dispatch(clearAllStats());
			aborted = true;
			promise && promise.abort();
		};
	}, [metaData, reportingPeriodState, regionState, industryState, DMState]);

	const refresh = () => {
		dispatch(clearAllStats());
		aborted = true;
		promise && promise.abort();

		const timeout = setTimeout(() => {
			fetchStats();
			clearTimeout(timeout);
		}, 0);
	};

	// Filter function for search
	const metaWithStats: MetaItemWithStats[] = metaData.map(data => {
		const matchingItem = widgetsStats.find(stats => stats.result?.widgetName === data.name);

		return {
			...data,
			companiesCount: matchingItem?.result?.companies ?? 0,
			peopleCount: matchingItem?.result?.people ?? 0,
		};
	});

	// * Search
	const [search, setSearch] = useState('');

	const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.currentTarget.value);
	};

	// * Render
	return (
		<PageDecorator>
			<div className={s.container}>
				<div className={s.card}>
					<Breadcrumb
						items={[
							{
								title: <Link to={'/main'}>Main</Link>,
							},
						]}
					/>
					<div className={s.header}>
						<h1>QC Dashboard</h1>
						<div className={s.headerButtons}>{!widgetState && <ButtonPrimary onClick={refresh}>Refresh</ButtonPrimary>}</div>
					</div>

					<div>
						<div className={s.searchBarRow}>
							<StringInput
								id="qcDashboard__WidgetInput"
								type="text"
								placeholder="Search widgets..."
								value={search}
								onChange={e => handleInput(e)}
							/>

							<InfoTooltip
								Icon={<InfoSVG />}
								title="This search bar allows you to search for widget names."
							/>
						</div>
					</div>

					<Filters />
				</div>

				{/* All widgets */}
				{metaWithStats && (
					<AllWidgets
						search={search}
						metaItems={metaWithStats}
						namesURL={sendToURL}
					/>
				)}
			</div>
		</PageDecorator>
	);
};
