export const checkForNewValues = (currentValues: string | null, newValues: string | null, separator: string) => {
	if (currentValues === null || newValues === null) {
		return !(currentValues === null && newValues === null);
	}

	const currentValuesArray = currentValues.split(separator);
	const newValuesArray = newValues.split(separator);
	for (const newValue of newValuesArray) {
		if (!currentValuesArray.includes(newValue)) {
			return true;
		}
	}
	return false;
};