import { Property } from "src/pages/MergeToolPage/_BLL/data_points/types";

export const checkIsEditable = (property: Property, targetCompanyId: number) => {
	// Don't auto merge associated parent of target company if it is the target company.
	if (property.propertyName === 'associatedParent') {
		if (property.autoMergedValue?.datapoints[0].value === targetCompanyId.toString()) {
			return false
		}
	}

	return property.values[0].datapoints[0].isEditable
}