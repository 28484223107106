import cn from 'classnames';
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import ArrowDown from 'src/shared/assets/svg/arrows/arrow-down.svg';
import ArrowLeft from 'src/shared/assets/svg/arrows/arrow-left.svg';
import ArrowRight from 'src/shared/assets/svg/arrows/arrow-right.svg';
import ArrowUp from 'src/shared/assets/svg/arrows/arrow-up.svg';
import s from './Arrow.module.scss';

export type ArrowDirection = 'up' | 'down' | 'left' | 'right';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	direction: ArrowDirection;
}

export const Arrow: FC<Props> = props => {
	const { direction, onClick, ...restProps } = props;

	const size = '1rem';

	const sizeStyle = {
		width: size,
		// height: size
	};

	const getArrowSVG = () => {
		if (direction === 'up') return <ArrowUp style={sizeStyle} />;
		if (direction === 'left') return <ArrowLeft style={sizeStyle} />;
		if (direction === 'right') return <ArrowRight style={sizeStyle} />;
		if (direction === 'down') return <ArrowDown style={sizeStyle} />;
	};

	return (
		<div
			{...restProps}
			className={cn(!!onClick && s.clickable)}
			style={sizeStyle}
			onClick={onClick}
		>
			{getArrowSVG()}
		</div>
	);
};
