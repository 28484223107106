import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { NotFoundPage } from 'src/pages/NotFoundPage/NotFoundPage';
import { AuthPage } from 'src/pages/AuthPage';
import { MainSearchPage } from 'src/pages/MainPage';
import { MainSearchResultsPage } from 'src/pages/MainSearchResultsPage/MainSearchResultsPage';
import { MergeToolPage } from 'src/pages/MergeToolPage';
import { GAListener } from 'src/shared/api/GoogleAnalytics';
import { useAppSelector } from 'src/app/redux/createAction';
import { QcAutomationPage } from 'src/pages/QcAutomationPage';
import { AdminPageLazy } from 'src/pages/AdminPage';
import { PeopleDuplicatesPage } from 'src/pages/PeopleDuplicatesPage';
import { DashboardPageLazy } from 'src/pages/DashboardPage';
import { Suspense } from 'react';
import { NotificationsProvider } from '../providers/NotificationsProvider';
import { QcDashboardPage, QcItemPage } from 'src/pages/QcDashboardPage';

export enum RouterPath {
	mainSearch = '/main',
	mainSearchResults = '/results',
	dashboard = '/dashboard',
	merge = '/merge-tool',
	peopleDuplicates = '/people-duplicates',
	matching = '/matching',
	adminCompany = '/adminCompany',
	qcAutomation = '/qcAutomation',
	qcDashboard = '/qc',
	qcWidget = '/widget',
	jobExecution = '/jobExecution',
}

export const AppRouter = () => {
	const userData = useAppSelector(state => state.auth.userData);
	const dictionaries = useAppSelector(state => state.metaDictionaries.dictionaries);

	return (
		<BrowserRouter>
			<GAListener>
				<NotificationsProvider />
				<Suspense fallback={<div>Loading...</div>}>
					<Routes>
						<Route
							path="/"
							element={
								userData && dictionaries ? (
									<Navigate
										to={RouterPath.mainSearch}
										replace
									/>
								) : (
									<AuthPage />
								)
							}
						/>

						<Route
							path={RouterPath.mainSearch}
							element={
								userData && dictionaries ? (
									<MainSearchPage />
								) : (
									<Navigate
										to="/"
										replace
									/>
								)
							}
						/>

						<Route
							path={RouterPath.mainSearchResults}
							element={
								userData && dictionaries ? (
									<MainSearchResultsPage />
								) : (
									<Navigate
										to="/"
										replace
									/>
								)
							}
						/>

						<Route
							path={RouterPath.dashboard + '/:id' + '/:menuId?'}
							element={
								userData && dictionaries ? (
									<DashboardPageLazy />
								) : (
									<Navigate
										to="/"
										replace
									/>
								)
							}
						/>

						<Route
							path={RouterPath.merge}
							element={
								userData && userData.accessRights.includes('MergeTool') && dictionaries ? (
									<MergeToolPage />
								) : (
									<Navigate
										to="/"
										replace
									/>
								)
							}
						/>

						<Route
							path={RouterPath.peopleDuplicates}
							element={
								userData && userData.accessRights.includes('MergeTool') && dictionaries ? (
									<PeopleDuplicatesPage />
								) : (
									<Navigate
										to="/"
										replace
									/>
								)
							}
						/>

						{/* QC Dashboard */}
						<Route
							path={RouterPath.qcDashboard}
							element={
								userData && userData.accessRights.includes('QcDashboard') && dictionaries ? (
									<QcDashboardPage />
								) : (
									<Navigate
										to="/"
										replace
									/>
								)
							}
						/>

						<Route
							path={RouterPath.qcDashboard + RouterPath.qcWidget + '/:widget'}
							element={
								userData && userData.accessRights.includes('QcDashboard') && dictionaries ? (
									<QcItemPage />
								) : (
									<Navigate
										to="/"
										replace
									/>
								)
							}
						/>

						{/* Admin */}
						<Route
							path={RouterPath.adminCompany}
							element={
								userData && userData.accessRights.includes('ExcelLoader') && dictionaries ? (
									<AdminPageLazy />
								) : (
									<Navigate
										to="/"
										replace
									/>
								)
							}
						/>

						<Route
							path={RouterPath.qcAutomation}
							element={
								userData && userData.accessRights.includes('QcAutomationTool') && dictionaries ? (
									<QcAutomationPage />
								) : (
									<Navigate
										to="/"
										replace
									/>
								)
							}
						/>

						<Route element={<NotFoundPage />} />
					</Routes>
				</Suspense>
			</GAListener>
		</BrowserRouter>
	);
};
