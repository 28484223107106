import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useMetaContext } from 'src/app/context/useMetaContext';
import { useAppSelector } from 'src/app/redux/createAction';
import { mergeToolSilverConflicts } from 'src/pages/MergeToolPage/_BLL/silver_conflicts/slice';
import { SelectDictionary } from 'src/shared/ui/_inputs/SelectDictionary';
import s from './ReportingPeriod.module.scss';

interface Props {
	type: 'source' | 'target';
}

export const ReportingPeriod: FC<Props> = props => {
	const {
		type, //
	} = props;

	// * Context
	const {
		dictionaries: { reportingPriods: reportingPeriods },
	} = useMetaContext();

	const latestReportingPeriod = reportingPeriods[0];

	// * Selector
	const selectedReportingPeriods = useAppSelector(state => state.mergeToolCompanySearch.reportingPeriods);

	// * Actions
	const dispatch = useDispatch();
	const { setPeriod } = mergeToolSilverConflicts;

	// * Render
	return (
		<div className={s.container}>
			<span className={s.caption}>Reporting period:</span>
			<SelectDictionary
				options={reportingPeriods}
				value={selectedReportingPeriods[type] ?? latestReportingPeriod.id}
				onChange={value => dispatch(setPeriod({ type, value: Number(value) }))}
			/>
		</div>
	);
};
