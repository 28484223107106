import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { PageDecorator } from 'src/app/decorators';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { DICTItem } from 'src/app/redux/slices/metaDictionaries/types';
import { actionsQC } from 'src/pages/QcAutomationPage/_BLL/slice';
import { BooleanRequest, OutlierStatus, OutlierStatusItem } from 'src/pages/QcAutomationPage/_BLL/types';
import { DependableDualSelectFilter } from 'src/pages/QcAutomationPage/ui/DependableDualSelectFilter';
import { OutlierItem } from 'src/pages/QcAutomationPage/ui/OutlierItem';
import { SingleStringFilter } from 'src/pages/QcAutomationPage/ui/SingleStringFilter';
import { StatusCircle } from 'src/pages/QcAutomationPage/ui/StatusCircle';
import { RequestStatus } from 'src/shared/api/types';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import { PreloaderSquared } from 'src/shared/ui/_loaders/PreloaderSquared';
import { Pagination, PaginationI } from 'src/shared/ui/Pagination';
import s from './QcAutomationPage.module.scss';

export const QcAutomationPage: FC = () => {
	// * Selectors
	const status = useAppSelector(state => state.qcAutomation.status);
	const companyOutliers = useAppSelector(state => state.qcAutomation.companies);
	const total = useAppSelector(state => state.qcAutomation.total);
	const activeCompanyId = useAppSelector(state => state.qcAutomation.activeCompanyId);
	const dictionaries = useAppSelector(state => state.metaDictionaries.dictionaries);

	const activeCompany = companyOutliers.find(company => company.companyID === activeCompanyId);
	const activeCompanyOutliers = activeCompany?.outliers;
	const activePersonOutliers = activeCompany?.personOutliers;

	// * Actions
	const dispatch = useAppDispatch();
	const { getOutliers, setOutliersStatuses, setActiveCompanyId } = actionsQC;

	const setStatus = (outlierDatapointID: number, newStatus: OutlierStatus, personID?: number) => {
		if (activeCompanyId) {
			const outlierStatus: OutlierStatusItem = {
				companyID: activeCompanyId,
				outlierDatapointID,
				status: newStatus,
			};

			if (personID) {
				outlierStatus.personID = personID;
			}

			dispatch(
				setOutliersStatuses({
					payload: {
						outliers: [outlierStatus],
					},
				}),
			);
		}
	};

	// * Pagination
	const initialPagination = {
		pageSize: 10,
		pageCount: total ? Math.ceil(total / 10) : 0,
		pageIndex: 0,
	};

	const [currentPagination, setCurrentPagination] = useState<PaginationI>(initialPagination);

	useEffect(() => {
		total &&
			setCurrentPagination(prevState => ({
				...prevState,
				pageCount: Math.ceil(total / 10),
			}));
	}, [total]);

	// * Filters
	// - Company
	const [companyNameSubstring, setCompanyNameSubstring] = useState<string | null>(null);

	const setCompanySubstring = (value: string | null) => {
		setCompanyNameSubstring(value && value !== '' ? value : null);
	};

	// - region/country
	const [selectedRegionId, setSelectedRegionId] = useState<number | null>(null);
	const [selectedCountryId, setSelectedCountryId] = useState<number | null>(null);

	const setSelectedRegion = (id: number | undefined) => {
		setSelectedRegionId(id ? id : null);
		setSelectedCountryId(null);
	};

	const setSelectedCountry = (id: number | undefined) => {
		setSelectedCountryId(id ? id : null);
	};

	const regions = dictionaries?.regions ? [{ id: undefined, name: 'unset' }, ...dictionaries.regions] : null;

	const countriesBySelectedRegion =
		dictionaries &&
		dictionaries.regions
			.find(region => region.id === selectedRegionId)
			?.children?.map(subregion => subregion.children as DICTItem[])
			.flat();

	const countries = countriesBySelectedRegion ? [{ id: undefined, name: 'unset' }, ...countriesBySelectedRegion] : null;

	// - sector/industry
	const [selectedSectorId, setSelectedSectorId] = useState<number | null>(null);
	const [selectedIndustryId, setSelectedIndustryId] = useState<number | null>(null);

	const setSelectedSector = (id: number | undefined) => {
		setSelectedSectorId(id ? id : null);
		setSelectedIndustryId(null);
	};

	const setSelectedIndustry = (id: number | undefined) => {
		setSelectedIndustryId(id ? id : null);
	};

	const sectors = dictionaries?.sectors ? [{ id: undefined, name: 'unset' }, ...dictionaries.sectors] : null;

	const industriesBySelectedSector = dictionaries && dictionaries.sectors.find(sector => sector.id === selectedSectorId)?.children;

	const industries = industriesBySelectedSector
		? [
				{
					id: undefined,
					name: 'unset',
				},
				...industriesBySelectedSector,
			]
		: null;

	// - Payload
	const getExpression = () => {
		const activeFilters = [companyNameSubstring, selectedRegionId, selectedCountryId, selectedSectorId, selectedIndustryId].filter(filter => filter !== null);
		return activeFilters ? activeFilters.map((item, index) => index).join(' AND ') : '';
	};

	const search: BooleanRequest = {
		expression: getExpression(),
		filters: [],
	};

	if (companyNameSubstring) {
		search.filters.push({
			datapointId: 1000001, // legal name
			filters: [
				{
					operator: 'like',
					values: [companyNameSubstring],
				},
			],
		});
	}

	if (selectedRegionId) {
		search.filters.push({
			datapointId: 1000034, // region
			filters: [
				{
					operator: 'equals',
					values: [selectedRegionId],
				},
			],
		});
	}

	if (selectedCountryId) {
		search.filters.push({
			datapointId: 1000013, // legal country
			filters: [
				{
					operator: 'equals',
					values: [selectedCountryId],
				},
			],
		});
	}

	if (selectedSectorId) {
		search.filters.push({
			datapointId: 1000083, // primary sector
			filters: [
				{
					operator: 'equals',
					values: [selectedSectorId],
				},
			],
		});
	}

	if (selectedIndustryId) {
		search.filters.push({
			datapointId: 1000084, // primary industry
			filters: [
				{
					operator: 'equals',
					values: [selectedIndustryId],
				},
			],
		});
	}

	const [activeSearch, setActiveSearch] = useState<BooleanRequest | null>(null);

	const applyFilters = () => {
		setActiveSearch(search.filters.length ? search : null);
	};

	// * Initialize. Refresh on pagination/filters change
	useEffect(() => {
		let payload;

		if (activeSearch) {
			payload = {
				search: activeSearch,
			};
		}

		dispatch(
			getOutliers({
				params: {
					page_index: currentPagination.pageIndex,
					page_size: currentPagination.pageSize,
				},
				payload,
			}),
		);
	}, [currentPagination.pageIndex, currentPagination.pageSize, activeSearch]);

	// * Render
	return (
		<PageDecorator>
			{status === RequestStatus.loading && <PreloaderSquared className={s.preloader} />}

			<div className={s.container}>
				<div className={s.card}>
					<div className={s.title}>Access outliers</div>

					<div className={s.filters}>
						<SingleStringFilter
							label="Company name"
							value={companyNameSubstring}
							onChange={setCompanySubstring}
						/>

						{regions && (
							<DependableDualSelectFilter
								labelPrimary="Region"
								optionsPrimary={regions}
								selectedPrimaryId={selectedRegionId}
								setSelectedPrimary={setSelectedRegion}
								labelSecondary="Country"
								optionsSecondary={countries}
								selectedSecondaryId={selectedCountryId}
								setSelectedSecondary={setSelectedCountry}
							/>
						)}

						{sectors && (
							<DependableDualSelectFilter
								labelPrimary="Sector"
								optionsPrimary={sectors}
								selectedPrimaryId={selectedSectorId}
								setSelectedPrimary={setSelectedSector}
								labelSecondary="Industry"
								optionsSecondary={industries}
								selectedSecondaryId={selectedIndustryId}
								setSelectedSecondary={setSelectedIndustry}
							/>
						)}

						<ButtonPrimary
							onClick={applyFilters}
							disabled={status === RequestStatus.loading}
						>
							Apply filters
						</ButtonPrimary>
					</div>

					<Pagination
						className={s.pagination}
						pageSize={currentPagination.pageSize}
						pageCount={currentPagination.pageCount}
						currentPage={currentPagination.pageIndex}
						changePage={setCurrentPagination}
						visibleOptionsCount={3}
						hasPortionChange={false}
					/>

					{companyOutliers.length > 0 && (
						<div className={s.companiesList}>
							{companyOutliers.map(companyOutlier => (
								<div
									key={companyOutlier.companyID}
									className={cn(s.companyOutlierItem, activeCompanyId === companyOutlier.companyID && s.companyOutlierItem_active)}
									onClick={() => {
										dispatch(setActiveCompanyId({ activeCompanyId: companyOutlier.companyID }));
									}}
								>
									<div className={s.id}>{companyOutlier.companyID}</div>
									<div className={s.name}>{companyOutlier.companyName}</div>
									<StatusCircle
										acceptedCount={companyOutlier.acceptedOutliersCount}
										errorCount={companyOutlier.errorOutliersCount}
									/>
								</div>
							))}
						</div>
					)}
				</div>

				{activeCompanyOutliers && activeCompanyOutliers.length > 0 && (
					<div className={s.card}>
						<div className={s.title}>Company outliers</div>

						<div className={s.list}>
							{activeCompanyOutliers.map(outlier => (
								<OutlierItem
									key={outlier.datapointID}
									{...outlier}
									onButtonClick={newStatus => setStatus(outlier.outlierDatapointID, newStatus)}
								/>
							))}
						</div>
					</div>
				)}

				{activePersonOutliers && activePersonOutliers.length > 0 && (
					<div className={s.card}>
						<div className={s.title}>Person outliers</div>

						<div className={s.list}>
							{activePersonOutliers.map(personOutlier => (
								<div
									className={s.card}
									key={personOutlier.PersonID}
								>
									<div className={s.personList}>
										<div className={s.dataPointInfo}>
											<div className={s.id}>{personOutlier.PersonID}</div>
											<div className={s.name}>{personOutlier.PersonName}</div>
											<StatusCircle
												acceptedCount={personOutlier.AcceptedOutliersCount}
												errorCount={personOutlier.ErrorOutliersCount}
											/>
										</div>

										<div className={s.list}>
											{personOutlier.Outliers.map(outlier => (
												<OutlierItem
													key={outlier.datapointID}
													{...outlier}
													onButtonClick={newStatus => setStatus(outlier.outlierDatapointID, newStatus, personOutlier.PersonID)}
												/>
											))}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</PageDecorator>
	);
};
