import { FC } from 'react';
import { useAppSelector } from 'src/app/redux/createAction';
import { PersonMergeMeta } from 'src/pages/MergeToolPage/_BLL/silver_people/types';
import s from './IndividualDatapointsMC.module.scss';
import { ConflictsTableSelectable } from '../ConflictsTableSelectable/ConflictsTableSelectable';

interface Props {
	personMergeMeta: PersonMergeMeta;
}

export const IndividualDatapointsMC: FC<Props> = props => {
	const {
		personMergeMeta, //
	} = props;

	// * Selectors
	const targetCompany = useAppSelector(state => state.mergeToolCompanySearch.targetCompany);
	const dictionaries = useAppSelector(state => state.metaDictionaries.dictionaries);

	// * Render
	return (
		<div>
			<div className={s.container}>
				{dictionaries && targetCompany && personMergeMeta.conflicts.length > 0 && (
					<ConflictsTableSelectable
						key={personMergeMeta.name}
						personMergeMeta={personMergeMeta}
						dictionaries={dictionaries}
					/>
				)}
			</div>
		</div>
	);
};
