import { FC } from 'react';
import { ListChildComponentProps } from 'react-window';
import { useMetaContext } from 'src/app/context/useMetaContext';
import { useAppDispatch } from 'src/app/redux/createAction';
import { CompanyBaseInfo } from 'src/pages/MainPage/_BLL/main_search/types';
import { mergeToolSilverConflicts } from 'src/pages/MergeToolPage/_BLL/silver_conflicts/slice';
import { ButtonSecondary } from 'src/shared/ui/_buttons/ButtonSecondary';
import { Tooltip } from 'src/shared/ui/_tooltips/Tooltip';
import s from './SearchBarResult.module.scss';

export const SearchBarResult: FC<ListChildComponentProps<CompanyBaseInfo[]>> = props => {
	const {
		index, // Indexes of react-window
		style, // Default styles of react-window
		data,
	} = props;

	// * Context
	const {
		dictionaries: { reportingPriods: reportingPeriods },
	} = useMetaContext();

	// * Actions
	const dispatch = useAppDispatch();
	const { storeSourceCompany, storeTargetCompany, setPeriod } = mergeToolSilverConflicts;

	// * Adding companies
	const currentPeriodId = reportingPeriods[0].id;

	const addSourceClick = () => {
		dispatch(storeSourceCompany(data[index]));
		dispatch(setPeriod({ type: 'source', value: currentPeriodId }));
	};

	const addTargetClick = () => {
		dispatch(storeTargetCompany(data[index]));
		dispatch(setPeriod({ type: 'target', value: currentPeriodId }));
	};

	// * Render
	return (
		<div
			className={s.container}
			style={style}
			key={index}
		>
			<span className={s.inner}>
				<span>
					{data[index].latinName ? (
						<Tooltip title={`Original name: ${data[index].name}`}>
							<b>{data[index].latinName}</b>
						</Tooltip>
					) : (
						<b>{data[index].name}</b>
					)}
					{data[index].id && <span>, {data[index].id}</span>}
					{data[index].country && <span>, {data[index].country}</span>}
					{data[index].city && <span>, {data[index].city}</span>}
				</span>

				<div className={s.buttons}>
					<ButtonSecondary onClick={addSourceClick}>Add as source</ButtonSecondary>

					<ButtonSecondary onClick={addTargetClick}>Add as target</ButtonSecondary>
				</div>
			</span>
		</div>
	);
};
