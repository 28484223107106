import { useEffect } from 'react';
import { useAppDispatch } from 'src/app/redux/createAction';

export const useDebauncedRequest = (arg: { action: any, dependencies: any[]}) => {
	const {action, dependencies} = arg;

	const dispatch = useAppDispatch();

	useEffect(() => {
		const request = () => {
			return dispatch(action);
		};

		let promise: any;

		const timeout = setTimeout(() => {
			promise = request();
		}, 300);

		return () => {
			clearTimeout(timeout);
			promise && promise.abort();
		};
	}, dependencies);
};
