import { Dictionaries } from 'src/app/redux/slices/metaDictionaries/types';
import { MetaEntity } from 'src/pages/DashboardPage/_BLL/dashboard/types/types';
import { rtkApiRequest } from 'src/shared/api/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';

const getDictionaries = createAsyncThunk('metaDictionaries/getDictionaries', async (payload: { token?: string }, thunkAPI) => {
	return await rtkApiRequest.rtkGETRequest<Dictionaries>({
		url: UrlAPI.dictionaries,
		thunkAPI,
		token: payload.token,
	});
});

const getMeta = createAsyncThunk('metaDictionaries/getMeta', async (payload: { token?: string }, thunkAPI) => {
	return await rtkApiRequest.rtkGETRequest<[MetaEntity, MetaEntity]>({
		url: UrlAPI.metadata,
		thunkAPI,
		token: payload.token,
	});
});

// * Reducer
interface State {
	dictionaries: Dictionaries | null;
	meta: {
		company: MetaEntity | null;
		person: MetaEntity | null;
	};
	status: RequestStatus;
}

export const initialState: State = {
	dictionaries: null,
	meta: {
		company: null,
		person: null,
	},
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: 'metaDictionaries',
	initialState,
	reducers: {
		clearAll: state => {
			state.dictionaries = null;
			state.meta = {
				company: null,
				person: null,
			};
		},
	},
	extraReducers: builder => {
		builder.addCase(getDictionaries.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getDictionaries.fulfilled, (state, action) => {
			state.dictionaries = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getDictionaries.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getMeta.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getMeta.fulfilled, (state, action) => {
			const companyMeta = action.payload.find(item => item.entityName === 'company');
			const personMeta = action.payload.find(item => item.entityName === 'person');

			if (companyMeta && personMeta) {
				state.meta = {
					company: companyMeta,
					person: personMeta,
				};
				state.status = RequestStatus.still;
			} else {
				state.status = RequestStatus.failed;
			}
		});
		builder.addCase(getMeta.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsMetaDictionaries = {
	...slice.actions,
	getDictionaries,
	getMeta,
};
