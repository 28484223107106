import { DICTItem } from 'src/app/redux/slices/metaDictionaries/types';

export const mergeRegionsSubregionsCountries = (dictRegions: DICTItem[]): DICTItem[] => {
	return dictRegions.flatMap((region: { id: any; name: string; children: { id: any; name: string; country?: { id: any; name: string }[] }[] }) => [
		{ id: region.id, name: region.name },
		...((region.children
			? region.children.flatMap((subRegion: { id: any; name: string; children: { id: any; name: string }[] }) => [
					{ id: subRegion.id, name: subRegion.name },
					...(subRegion.children
						? subRegion.children.map((country: { id: any; name: string }) => ({
								id: country.id,
								name: country.name,
							}))
						: []),
				])
			: []) ?? []),
	]);
};
