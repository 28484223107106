import { FC, ReactElement } from 'react';
import { RequestStatus } from 'src/shared/api/types';
import { Preloader } from 'src/shared/ui/_loaders/Preloader';
import s from './KpiContentCard.module.scss';

interface Props {
	Content: ReactElement<{ data: any; title?: string }, any>;
	status: RequestStatus;
}

export const KpiContentCard: FC<Props> = props => {
	const {
		Content, //
		status,
	} = props;

	const renderContent = () => {
		if (Content.props.data !== null && status !== RequestStatus.loading) return 'CONTENT';
		if (Content.props.data === null && status === RequestStatus.still) return 'EMPTY';
		if (status === RequestStatus.loading) return 'LOADING';
		return 'LOADING';
	};

	const contentVariants = {
		LOADING: Preloader,
		EMPTY: () => (
			<span>
				<b>{Content.props?.title} </b>Failed to load
			</span>
		),
		CONTENT: () => Content,
	};

	const Component = contentVariants[renderContent()];

	return (
		<div className={s.container}>
			<div className={s.card}>
				<Component />
			</div>
		</div>
	);
};
