import { FC, memo } from 'react';
import s from './PieStatList.module.scss';
import { createGroups } from 'src/shared/lib/object_array';

interface Props {
	dataWithColors: Array<{
		id: string;
		label: string;
		color: string | undefined;
	}>;
}

export const PieStatList: FC<Props> = memo(props => {
	const {
		dataWithColors, //
	} = props;

	const numberOfValuesInGroup = 5;
	const numberOfGroups = Math.ceil(dataWithColors.length / numberOfValuesInGroup);
	const groups = createGroups(dataWithColors, numberOfGroups, numberOfValuesInGroup);

	return (
		<div className={s.container}>
			{groups.map((group, index) => (
				<div
					className={s.group}
					key={index}
				>
					{group.map(item => (
						<div
							className={s.valueWrapper}
							key={item.id}
						>
							<div
								className={s.dot}
								style={{
									backgroundColor: item.color,
								}}
							/>
							<span className={s.label}>{item.label}</span>
						</div>
					))}
				</div>
			))}
		</div>
	);
});
