import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useActions, useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { SignInBody } from 'src/shared/api/types';
import { RequestStatus } from 'src/shared/api/types';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import { PasswordInput } from 'src/shared/ui/_inputs/PasswordInput';
import { StringInput } from 'src/shared/ui/_inputs/StringInput';
import * as Yup from 'yup';
import { actionsMetaDictionaries } from '../../../../app/redux/slices/metaDictionaries/slice';
import { actionsAuth } from '../../_BLL/slice';
import s from './SignInForm.module.scss';

export const SignInForm = () => {
	// * Selectors
	const status = useAppSelector(state => state.auth.status);
	const metaStatus = useAppSelector(state => state.metaDictionaries.status);

	// * Actions
	const dispatch = useAppDispatch();
	const { signIn } = actionsAuth;
	const { getMeta, getDictionaries } = useActions(actionsMetaDictionaries);

	const schema = Yup.object().shape({
		username: Yup.string().required(),
		password: Yup.string().required(),
	});

	const { handleSubmit, control } = useForm({
		defaultValues: {
			username: '',
			password: '',
		},
		resolver: yupResolver(schema),
	});

	const onSubmit = (values: SignInBody) => {
		dispatch(signIn(values))
			.unwrap()
			.then(res => {
				getMeta({ token: res.token });
				getDictionaries({ token: res.token });
			});
	};

	// * Render
	return (
		<form
			className={s.form}
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className={s.fields}>
				<Controller
					name="username"
					control={control}
					render={({ field, fieldState }) => (
						<StringInput
							{...field}
							label="User name"
							size="large"
							error={fieldState.error?.message}
						/>
					)}
				/>

				<Controller
					name="password"
					control={control}
					render={({ field, fieldState }) => (
						<PasswordInput
							{...field}
							label="Password"
							size="large"
							error={fieldState.error?.message}
						/>
					)}
				/>
			</div>

			<ButtonPrimary
				htmlType="submit"
				loading={status === RequestStatus.loading || metaStatus === RequestStatus.loading}
			>
				Sign in
			</ButtonPrimary>
		</form>
	);
};
