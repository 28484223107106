import { FC } from 'react';
import { PersonMergeMeta } from 'src/pages/MergeToolPage/_BLL/silver_people/types';
import { ConflictTypeColumn } from 'src/pages/MergeToolPage/ui/SilverMode/PeopleMergeForm/ConflictTypeColumn/ConflictTypeColumn';
import s from './PeopleResult.module.scss';

interface Props {
	peopleMeta: PersonMergeMeta[];
}

export const PeopleResult: FC<Props> = props => {
	const {
		peopleMeta, //
	} = props;

	const uniquePeople = peopleMeta.filter(person => person.conflictType === 'unique');
	const samePeople = peopleMeta.filter(person => person.conflictType === 'same');
	const newPeople = peopleMeta.filter(person => person.conflictType === 'new');
	const peopleWithConflicts = peopleMeta.filter(person => person.conflictType === 'conflicts');

	return (
		<div className={s.container}>
			{uniquePeople.length > 0 && <ConflictTypeColumn personMergeMeta={uniquePeople} />}

			{samePeople.length > 0 && <ConflictTypeColumn personMergeMeta={samePeople} />}

			{newPeople.length > 0 && <ConflictTypeColumn personMergeMeta={newPeople} />}

			{peopleWithConflicts.length > 0 && <ConflictTypeColumn personMergeMeta={peopleWithConflicts} />}
		</div>
	);
};
