import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { SpinProps } from 'antd/es/spin';
import { FC } from 'react';

export const Preloader: FC<SpinProps> = props => {
	return (
		<Spin
			{...props}
			indicator={<LoadingOutlined spin />}
		/>
	);
};
