import { ThemeConfig } from 'antd';

const DISABLED_TEXT_COLOR = 'rgb(97,97,97)'; // --color-grey-700

export const themeAnt: ThemeConfig = {
	components: {
		Button: {
			primaryShadow: '0 0px 0 rgba(5, 145, 255, 0.1)',
			defaultShadow: '0 0px 0 rgba(0, 0, 0, 0.02)',
			dangerShadow: '0 0px 0 rgba(255, 38, 5, 0.06)',
		},
		Select: {
			optionSelectedBg: 'rgb(230,241,250)',
			colorTextDisabled: DISABLED_TEXT_COLOR,
		},
		Input: {
			colorTextDisabled: DISABLED_TEXT_COLOR,
		},
		DatePicker: {
			colorTextDisabled: DISABLED_TEXT_COLOR,
		},
		Radio: {
			colorTextDisabled: DISABLED_TEXT_COLOR,
			dotColorDisabled: DISABLED_TEXT_COLOR,
		},
	},
	token: {
		fontFamily: 'Poppins, sans-serif',
		colorPrimary: '#00356b',
		colorInfo: '#00356b',
		borderRadius: 6,
	},
};
