import { typedObjectEntries } from 'src/shared/types/guards/typedJS';
import { CompanyDataPoints, DataPoint, FormValues } from 'src/pages/MergeToolPage/_BLL/data_points/types';
import { onValueClick } from './onValueClick';
import { SourceType } from 'src/pages/MergeToolPage/_BLL/copper_menu/types';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';

// TODO: Convert to hook. formValues / setValue / sources
export const onGroupClick = (group: CompanyDataPoints, columnClicked: 'left' | 'right', sources: SourceType[], formValues: FormValues, setValue: UseFormSetValue<FormValues>) => {
	const selectedColumnEntries: any = {};
	group.properties.forEach(property => {
		selectedColumnEntries[property.propertyName] = property.values[columnClicked === 'left' ? 0 : 1].datapoints;
	});

	typedObjectEntries(selectedColumnEntries).forEach(([propertyName, value]: [string, DataPoint[]], propertyIndex) => {
		onValueClick(
			propertyName,
			value,
			sources.map((_, index) => group.properties[propertyIndex].values[index].datapoints),
			formValues,
			setValue,
		);
	});
};
