import { Pagination, PaginationProps } from 'antd';
import { FC, memo } from 'react';
import { IPagination } from './types';

interface Props extends PaginationProps {
	pageSize: number;
	pageCount: number;
	changePage: (pagination: IPagination) => void;
}

export const PaginationNew: FC<Props> = memo(props => {
	const {
		pageSize, //		// Number of items displayed on the page.
		pageCount, //		// Total number of pages.
		changePage, //		// Callback function that sets the current page when clicking on the page number.
		current,
		...paginationProps
	} = props;

	const onPageChange = (page: number, pageSize: number) => {
		changePage({ pageIndex: page - 1, pageSize, pageCount });
	};

	const onPageSizeChange = (_: number, size: number) => {
		changePage({ pageIndex: 0, pageSize: size, pageCount });
	};

	const totalItems = pageCount * pageSize;

	return (
		<Pagination
			{...paginationProps}
			showQuickJumper
			current={current ? current + 1 : 1}
			pageSize={pageSize}
			total={totalItems}
			onChange={onPageChange}
			onShowSizeChange={onPageSizeChange}
		/>
	);
});
