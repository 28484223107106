interface ReportingError {
	message: `Complex source (Silver x ${string} x ${string}) is not present.`,
	severity: "Error",
	errorCode: "COMPLEX_SOURCE_NOT_PRESENT"
}

// interface ReportingError {
// 	"message": "Complex source (Silver x 3222391 x 4) is not present.",
// 	"severity": "Error",
// 	"errorCode": "COMPLEX_SOURCE_NOT_PRESENT"
// }

export const isReportingError = (payload: unknown): payload is ReportingError => {
	return (payload as ReportingError).errorCode !== undefined;
}