import cn from 'classnames';
import { FC, HTMLAttributes, memo, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import CloseIcon from 'src/shared/assets/svg/close.svg';
import s from './ModalLegacy.module.scss';
import { Backdrop } from '../Backdrop';

interface ModalOverlayProps {
	isActive: boolean;
	toggleModal: () => void;
	Content: ReactNode;
	width: number;
	increaseZIndexBy: number;
}

const ModalOverlay: FC<ModalOverlayProps> = props => {
	const {
		isActive, //
		toggleModal,
		Content,
		width,
		increaseZIndexBy,
	} = props;

	const component = (
		<div
			className={cn(s.container, isActive && s.container_active)}
			style={{
				zIndex: 1001 + increaseZIndexBy,
				left: `${(100 - width) / 2}%`,
				width: `${width}%`,
			}}
		>
			<div
				id="modal"
				className={s.modal}
			>
				{Content}
			</div>

			{toggleModal && (
				<div className={s.close}>
					<CloseIcon onClick={toggleModal} />
				</div>
			)}
		</div>
	);

	const container = document.getElementById('modal-portal') as HTMLElement;

	return ReactDOM.createPortal(component, container);
};

interface ModalProps extends HTMLAttributes<HTMLDivElement> {
	show: boolean;
	toggleModal: () => void;
	Content: ReactNode;
	closeOnBackdrop?: boolean;
	width?: number;
	increaseZIndexBy?: number;
}

export const ModalLegacy: FC<ModalProps> = memo(props => {
	const {
		show,
		toggleModal,
		Content,
		closeOnBackdrop = true,
		width = 80, // Percent
		increaseZIndexBy = 0,
	} = props;

	// * Render
	return (
		<>
			<Backdrop
				isActive={show}
				onClick={closeOnBackdrop ? toggleModal : undefined}
				increaseZIndexBy={increaseZIndexBy}
			/>

			<ModalOverlay
				isActive={show}
				toggleModal={toggleModal}
				Content={Content}
				width={width}
				increaseZIndexBy={increaseZIndexBy}
			/>
		</>
	);
});
