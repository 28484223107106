import { Breadcrumb } from 'antd';
import { FC, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PageDecorator } from 'src/app/decorators';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { RouterPath } from 'src/app/router/AppRouter';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import { actionsQcMeta } from '../../_BLL/slice';
import { useUrlFilterParams } from '../../hooks/useUrlFilterParams';
import { Filters } from '../Filters/Filters';
import { QCContent } from '../QCContent';
import { DEFAULT_PAGINATION } from '../QCContent/consts';
import { generateWidgetParams } from '../QcDashboardPage/lib/generateStatsParams/generateStatsParams';
import s from './QcItemPage.module.scss';

let promises: any[] = [];

export const QcMissingPage: FC = () => {
	// * Routing
	const params = useParams() as { widget: string };
	const { reportingPeriodState, regionState, industryState, DMState } = useUrlFilterParams();

	const widgetName = params.widget;

	// * Selectors
	const pagination = useAppSelector(state => state.qcDashboard.pagination);
	const metaData = useAppSelector(state => state.qcDashboard.metaData);
	const widgetsStats = useAppSelector(state => state.qcDashboard.widgetsStats);
	const widgetLists = useAppSelector(state => state.qcDashboard.widgetsLists);
	const widgetState = useAppSelector(state => state.qcDashboard.widget);

	// * Actions
	const dispatch = useAppDispatch();

	const { getMetadata, getStats, getList } = actionsQcMeta;

	const widgetParams = generateWidgetParams({
		reportingPeriodState,
		regionState,
		industryState,
		DMState,
		pagination,
	});

	const fetchData = () => {
		return [
			dispatch(getStats({ params: widgetParams, qcType: widgetName })), //
			dispatch(getList({ params: widgetParams, qcType: widgetName })),
		];
	};

	const refresh = () => {
		promises && promises.forEach(promise => promise.abort());
		promises = fetchData();
	};

	// * Initialize
	useEffect(() => {
		promises = fetchData();

		return () => {
			promises.forEach(promise => promise.abort());
		};
	}, [reportingPeriodState, regionState, industryState, DMState, pagination.pageIndex, pagination.pageSize]);

	const dataWithStats = metaData.map(data => {
		const matchingItem = widgetsStats.find(stats => stats.result?.widgetName === data.name);

		return {
			...data,
			companiesCount: matchingItem?.result?.companies,
			peopleCount: matchingItem?.result?.people,
		};
	});

	const widget = dataWithStats.find(item => item.name === widgetName);

	useEffect(() => {
		if (!widget) {
			dispatch(getMetadata());
		}
	});

	// * Render
	return (
		<PageDecorator>
			<div className={s.container}>
				<div className={s.card}>
					<Breadcrumb
						items={[
							{
								title: <Link to={'/main'}>Main</Link>,
							},
							{
								title: <Link to={RouterPath.qcDashboard + `?reportingPeriod=${reportingPeriodState}&region=${regionState}&industry=${industryState}&DM=${DMState}`}>QC Dashboard</Link>,
							},
						]}
					/>
					<div className={s.header}>
						<h1>QC Dashboard</h1>
						<div className={s.headerButtons}>{!widgetState && <ButtonPrimary onClick={refresh}>Refresh</ButtonPrimary>}</div>
					</div>

					<Filters />

					<QCContent
						widget={widget}
						list={
							widgetLists.find(item => item.widgetName === widgetName) || {
								result: {
									pagination: { ...DEFAULT_PAGINATION, count: 0 },
								},
							}
						}
					/>
				</div>
			</div>
		</PageDecorator>
	);
};
