import { CompanyPeopleDuplicates } from 'src/pages/PeopleDuplicatesPage/_BLL/types';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BooleanRequest } from 'src/pages/QcAutomationPage/_BLL/types';
import { rtkApiRequest } from 'src/shared/api/api';
import { PaginationI } from 'src/shared/ui/Pagination';

const NAME = 'PeopleDuplicates';

const callPeopleDuplicates = createAsyncThunk(`${NAME}/callPeopleDuplicates`, async (args: { params: { page_size: number, page_index: number }, payload?: { search?: BooleanRequest } }, thunkAPI) => {
	const {params, payload} = args;
	const {signal} = thunkAPI;

	return await rtkApiRequest.rtkPOSTRequest<{ companies: CompanyPeopleDuplicates[], total: number }>({
		url: `${UrlAPI.duplicatePeople}/search`,
		params,
		payload,
		thunkAPI,
		signal
	});
});

const autoMergePeopleDuplicates = createAsyncThunk(`${NAME}/autoMergePeopleDuplicates`, async (args: { companyId: number, personKey: string, pagination: Omit<PaginationI, 'pageCount'>, search?: BooleanRequest }, thunkAPI) => {
	const {companyId, pagination, search, personKey} = args;
	const {signal, dispatch} = thunkAPI;

	await rtkApiRequest.rtkPOSTRequest({
		url: `${UrlAPI.duplicatePeople}/autoMerge`,
		params: {
			company_id: companyId,
			person_key: encodeURI(personKey)
		},
		thunkAPI,
		signal
	});

	dispatch(callPeopleDuplicates({
		params: {
			page_size: pagination.pageSize,
			page_index: pagination.pageIndex
		},
		payload: {
			search
		}
	}))
});

interface State {
	companies: CompanyPeopleDuplicates[] | null;
	total: number | null;
	status: RequestStatus;
}

export const initialState: State = {
	companies: null,
	total: null,
	status: RequestStatus.still
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(callPeopleDuplicates.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(callPeopleDuplicates.fulfilled, (state, action) => {
			const {companies, total} = action.payload;

			state.status = RequestStatus.still;
			state.companies = companies;
			state.total = total;
		});
		builder.addCase(callPeopleDuplicates.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(autoMergePeopleDuplicates.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(autoMergePeopleDuplicates.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(autoMergePeopleDuplicates.rejected, state => {
			state.status = RequestStatus.failed;
		});
	}
});

export const actionsPeopleDuplicates = {
	...slice.actions,
	callPeopleDuplicates,
	autoMergePeopleDuplicates
};
