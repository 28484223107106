import React, { ComponentPropsWithoutRef, FC, useState } from 'react';
import s from './TableSticky.module.scss';
import cn from 'classnames';
import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import ArrowUpSVG from 'src/shared/assets/svg/arrows/arrow-up.svg';
import ArrowDownSVG from 'src/shared/assets/svg/arrows/arrow-down.svg';
import { getStickyStyles } from './lib/getStickyStyles';

interface Props extends ComponentPropsWithoutRef<'div'> {
	columns: ColumnDef<any, any>[];
	data: any[];
	sticky?: string[]; // Array of widths for sticky columns from left. Each array item make the column sticky
	onRowClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, object: any) => void;
}
/** Copy of the Gold Table with an update. */
export const TableSticky: FC<Props> = props => {
	const {
		columns, //
		data,
		sticky,
		onRowClick,
		className,
	} = props;

	const [sorting, setSorting] = useState<SortingState>([]);

	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	// * Render
	return (
		<div className={cn(s.container, className)}>
			<table>
				<thead>
					{table.getHeaderGroups().map(headerGroup => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header, index) => {
								return (
									<th
										key={header.id}
										colSpan={header.colSpan}
										style={getStickyStyles(index, sticky)}
									>
										{header.isPlaceholder ? null : (
											<div
												className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
												onClick={header.column.getToggleSortingHandler()}
											>
												{flexRender(header.column.columnDef.header, header.getContext())}

												{{
													asc: <ArrowUpSVG className={s.arrow} />,
													desc: <ArrowDownSVG className={s.arrow} />,
												}[header.column.getIsSorted() as string] ?? null}
											</div>
										)}
									</th>
								);
							})}
						</tr>
					))}
				</thead>

				<tbody data-test="table_sticky_body">
					{table.getRowModel().rows.map(row => {
						return (
							<tr
								data-test="table_sticky_row"
								key={row.id}
								className={cn(onRowClick && s.clickable)}
								onClick={event => {
									onRowClick && onRowClick(event, row.original);
								}}
								onMouseDown={event => {
									onRowClick && onRowClick(event, row.original);
								}}
							>
								{row.getVisibleCells().map((cell, index) => {
									return (
										<td
											key={cell.id}
											style={getStickyStyles(index, sticky)}
										>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};
