import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { actionsMainSearch } from 'src/pages/MainPage/_BLL/main_search/slice';
import { SearchBarResult } from 'src/pages/MainPage/ui/SearchBar/ui/SearchBarResult/SearchBarResult';
import { RequestStatus } from 'src/shared/api/types';
import { useOnclickAwayHandler } from 'src/shared/hooks/useOnclickAwayHandler';
import { useDebauncedRequest } from 'src/shared/lib/api';
import { ButtonSecondary } from 'src/shared/ui/_buttons/ButtonSecondary';
import { StringInput } from 'src/shared/ui/_inputs/StringInput';
import { Preloader } from 'src/shared/ui/_loaders/Preloader';
import s from './SearchBar.module.scss';

export const SearchBar = () => {
	// * Selectors
	const { search, companies, status } = useAppSelector(state => state.mainSearch);

	// * Actions
	const dispatch = useAppDispatch();
	const { saveSearch, searchRequest, clearResults, clearAll } = actionsMainSearch;

	// * History API
	const navigate = useNavigate();

	const showAllResultsButtonHandler = () => {
		navigate('/results', { state: { requestParams: encodeURIComponent(search) } });
		dispatch(clearAll());
	};

	const searchZone = useRef(null);
	useOnclickAwayHandler(searchZone, () => dispatch(clearAll()));

	useEffect(() => {
		dispatch(clearAll());
	}, [clearAll]);

	useEffect(() => {
		if (!search) dispatch(clearResults());
	}, [search]);

	useDebauncedRequest({
		action: searchRequest({ search: encodeURIComponent(search) }),
		dependencies: [search],
	});

	// * Render
	return (
		<div
			className={s.container}
			ref={searchZone}
		>
			{/* Search bar */}
			<StringInput
				placeholder="search..."
				value={search ?? undefined}
				onChange={e => dispatch(saveSearch(e.currentTarget.value))}
				suffix={status === RequestStatus.loading ? <Preloader size="small" /> : <></>}
			/>

			{/* Search results */}
			{companies.length > 0 ? (
				<div
					key={companies.length}
					className={s.searchResults}
				>
					<FixedSizeList
						className={s.fixedSizeList}
						itemData={companies}
						height={300}
						width={'100%'}
						itemSize={30}
						itemCount={companies.length}
					>
						{props => (
							<SearchBarResult
								{...props}
								data={companies}
							/>
						)}
					</FixedSizeList>

					<ButtonSecondary
						className={s.button}
						onClick={showAllResultsButtonHandler}
					>
						Show all results
					</ButtonSecondary>
				</div>
			) : null}
		</div>
	);
};
