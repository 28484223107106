import { FC, useCallback, useMemo, useState } from 'react';
import { KpiTotalBy } from 'src/pages/MainPage/_BLL/kpi/types';
import { createRandomColorMap } from 'src/shared/styles/colorMaps';
import { PieChart } from 'src/shared/ui/_charts/PieChart/ui/PieChart/PieChart';
import s from './KpiTotal.module.scss';
import { Checkbox } from 'src/shared/ui/_inputs/Checkbox';

interface Props {
	title: string;
	data: KpiTotalBy;
	colorMap?: Map<string, string>;
}

export const KpiTotal: FC<Props> = props => {
	const {
		title, //
		data,
		colorMap,
	} = props;

	const colorMapPie = useMemo(() => (colorMap ? colorMap : createRandomColorMap(data.total)), [colorMap, data.total]);

	const [association, setAssociation] = useState(true);

	const chooseData = useCallback(() => {
		return association ? data.total : data.totalNoAssociation;
	}, [association]);

	// * Render
	return (
		<>
			<div className={s.title}>{title}</div>

			<Checkbox
				label="With association"
				checked={association}
				onChange={e => setAssociation(e.target.checked)}
			/>

			<PieChart
				data={chooseData()}
				colorMap={colorMapPie}
			/>
		</>
	);
};
