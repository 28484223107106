import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { rtkApiRequest } from 'src/shared/api/api';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { DocumentInfo, IDocumentType, Pagination } from './types/types';
import { DocumentsLookup_REQ, DocumentUpdate_REQ, DocumentUpload_REQ } from './types/types_REQ';
import { DocumentsLookup_RES, DocumentsTypes_RES, DocumentsYears_RES, DocumentUpdate_RES, DocumentUpload_RES } from './types/types_RES';
import { actionsNotifications } from 'src/app/providers/NotificationsProvider/_BLL/notifications/slice';

const NAME = 'dashboard_documents';

// * Thunks
const getDocumentsYears = createAsyncThunk(`${NAME}/getDocumentsYears`, async (_: void, thunkAPI) => {
	return await rtkApiRequest.rtkGETRequest<DocumentsYears_RES>({
		url: UrlAPI.documentsYears,
		thunkAPI,
	});
});

const getDocumentsTypes = createAsyncThunk(`${NAME}/getDocumentsTypes`, async (_: void, thunkAPI) => {
	return await rtkApiRequest.rtkGETRequest<DocumentsTypes_RES>({
		url: UrlAPI.documentsTypes,
		thunkAPI,
	});
});

const documentsLookup = createAsyncThunk(`${NAME}/documentsLookup`, async (arg: DocumentsLookup_REQ, thunkAPI) => {
	const { payload } = arg;

	return await rtkApiRequest.rtkPOSTRequest<DocumentsLookup_RES>({
		url: UrlAPI.documentsLookup,
		payload,
		thunkAPI,
	});
});

const documentUpload = createAsyncThunk(`${NAME}/documentUpload`, async (arg: DocumentUpload_REQ, thunkAPI) => {
	const { payload } = arg;
	const { dispatch } = thunkAPI;

	const res = await rtkApiRequest.rtkPOSTRequest<DocumentUpload_RES>({
		url: UrlAPI.documentsUpload,
		payload,
		thunkAPI,
	});

	dispatch(
		actionsNotifications.addNotification({
			type: 'success',
			message: `Document ${res.name} uploaded successfully`,
		}),
	);

	return res;
});

const documentUpdate = createAsyncThunk(`${NAME}/documentUpdate`, async (arg: DocumentUpdate_REQ, thunkAPI) => {
	const { payload } = arg;
	const { dispatch } = thunkAPI;

	const res = await rtkApiRequest.rtkPUTRequest<DocumentUpdate_RES>({
		url: UrlAPI.documentsUpdate,
		payload,
		thunkAPI,
	});

	dispatch(
		actionsNotifications.addNotification({
			type: 'success',
			message: `Document ${res.name} updated successfully`,
		}),
	);

	return res;
});

// * Reducer
interface State {
	pagination: Pagination;
	documents: DocumentInfo[];
	years: number[];
	types: IDocumentType[];
	status: RequestStatus;
}

export const initialState: State = {
	pagination: {
		pageSize: 0,
		pageIndex: 0,
		pageCount: 0,
		count: 0,
	},
	documents: [],
	years: [],
	types: [],
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getDocumentsYears.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getDocumentsYears.fulfilled, (state, action) => {
			state.years = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getDocumentsYears.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getDocumentsTypes.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getDocumentsTypes.fulfilled, (state, action) => {
			state.types = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getDocumentsTypes.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(documentsLookup.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(documentsLookup.fulfilled, (state, action) => {
			state.pagination = action.payload.pagination;
			state.documents = action.payload.documents;
			state.status = RequestStatus.still;
		});
		builder.addCase(documentsLookup.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(documentUpload.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(documentUpload.fulfilled, (state, action) => {
			// TODO: Return or save depending on use case
			state.status = RequestStatus.still;
		});
		builder.addCase(documentUpload.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(documentUpdate.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(documentUpdate.fulfilled, (state, action) => {
			// TODO: Return or save depending on use case
			state.status = RequestStatus.still;
		});
		builder.addCase(documentUpdate.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsDocuments = {
	...slice.actions,
	getDocumentsYears,
	getDocumentsTypes,
	documentsLookup,
	documentUpload,
	documentUpdate,
};
