import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppSelector } from 'src/app/redux/createAction';

// const TRACKING_ID = 'UA-203891313-1'; // OUR_TRACKING_ID
const TRACKING_ID = 'G-FCJYZC65DN'; // OUR_TRACKING_ID

const getGAClientId = (): Promise<string | undefined> => new Promise((resolve) => {
	// @ts-ignore
	const globalGA: any = window.ga;
	if (!globalGA) {
		resolve(undefined);
	}

	globalGA((tracker: any) => {
		resolve(tracker.get('clientId'));
	});
});

const pad = (num: number, ms?: boolean) => {
	const norm = Math.floor(Math.abs(num));
	if (ms) return (norm < 10 ? '00' : norm < 100 ? '0' : '') + norm;
	return (norm < 10 ? '0' : '') + norm;
};

const getTimestamp = () => {
	const now = new Date();
	const tzo = -now.getTimezoneOffset();
	const dif = tzo >= 0 ? '+' : '-';

	return now.getFullYear()
		+ '-' + pad(now.getMonth() + 1)
		+ '-' + pad(now.getDate())
		+ 'T' + pad(now.getHours())
		+ ':' + pad(now.getMinutes())
		+ ':' + pad(now.getSeconds())
		+ '.' + pad(now.getMilliseconds(), true)
		+ dif + pad(tzo / 60)
		+ ':' + pad(tzo % 60);
};

export const GASendPageView = async (location: any, userId: number | undefined) => {
	const clientId = await getGAClientId();

	ReactGA.initialize(TRACKING_ID, {
		gaOptions: {
			userId: userId?.toString(),
			user_properties: {
				dnUserId: userId,
				dnClientId: clientId,
			},
			debug_mode: true,
		}
	});
	ReactGA.set({
		userId,
		page: location.pathname,
	});

	const timestamp = getTimestamp();

	ReactGA.event('dn_page_view', {
		dnTimestamp: timestamp,
		dnUrl: location.pathname,
	});
	ReactGA.send({hitType: 'pageview', page: location.pathname});
};

export const GASendApiCall = async (method: string, url: string, userId: number | undefined) => {
	const clientId = await getGAClientId();

	ReactGA.initialize(TRACKING_ID, {
		gaOptions: {
			userId: userId?.toString(),
			user_properties: {
				dnUserId: userId,
				dnClientId: clientId,
			},
			debug_mode: true,
		}
	});
	ReactGA.set({
		userId
	});

	const timestamp = getTimestamp();

	ReactGA.event('dn_api_call', {
		dnTimestamp: timestamp,
		dnUrl: location.pathname,
		dnMethod: method
	});
};

export const GAListener = (p: { children: any }) => {
	const location = useLocation();
	const {userData} = useAppSelector(state => state.auth);

	useEffect(() => {
		GASendPageView(location, userData?.id);
	}, [location, userData]);

	return p.children;
};
