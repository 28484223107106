import { rootReducer } from './rootReducer';
import { loadState, saveState } from './localStorage';
import { configureStore } from '@reduxjs/toolkit';
import { initialState as kpi } from 'src/pages/MainPage/_BLL/kpi/slice';

import { initialState as copperMenu } from '../../pages/MergeToolPage/_BLL/copper_menu/slice';
import { initialState as mergeToolDataPoints } from '../../pages/MergeToolPage/_BLL/data_points/slice';
import { initialState as mergeToolCompanySearch } from '../../pages/MergeToolPage/_BLL/silver_conflicts/slice';

import { initialState as qcAutomation } from 'src/pages/QcAutomationPage/_BLL/slice';

import { initialState as peopleDuplicates } from 'src/pages/PeopleDuplicatesPage/_BLL/slice';

import { initialState as qcDashboard } from 'src/pages/QcDashboardPage/_BLL/slice';

import { initialState as notifications } from '../providers/NotificationsProvider/_BLL/notifications/slice';

import { initialState as mainSearch } from '../../pages/MainPage/_BLL/main_search/slice';

import { initialState as dashboardMain } from '../../pages/DashboardPage/_BLL/dashboard/slice';
import { initialState as dashboardPeople } from '../../pages/DashboardPage/_BLL/people/slice';
import { initialState as dashboardCompany } from '../../pages/DashboardPage/_BLL/company/slice';
import { initialState as uploadToGold } from '../../pages/DashboardPage/_BLL/uploadToGold/slice';
import { initialState as admin } from 'src/pages/AdminPage/_BLL/slice';
import { initialState as dump } from 'src/features/DumpCompany/_BLL/slice';

import { initialState as documents } from 'src/pages/DashboardPage/_BLL/documents/slice';

const preloadedState = loadState();

export const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			immutableCheck: false,
			// serializableCheck: false,
		}),
	devTools: { trace: false },
	preloadedState,
});

store.subscribe(() => {
	saveState({
		kpi,

		copperMenu,
		mergeToolDataPoints,
		mergeToolCompanySearch,

		qcAutomation,

		peopleDuplicates,
		// Slices
		notifications, // Slice
		metaDictionaries: store.getState().metaDictionaries, // Slice
		auth: store.getState().auth, // Slice
		admin,
		dump,

		qcDashboard,

		mainSearch,
		mainSearchResults: store.getState().mainSearchResults,

		dashboardMain,
		dashboardPeople,
		dashboardCompany,
		uploadToGold,
		documents,
	});
});

export type AppDispatch = typeof store.dispatch;
