import React, { FC, useState } from 'react';
import s from './HeaderProfile.module.scss';
import { ButtonSecondary } from 'src/shared/ui/_buttons/ButtonSecondary';
import { useActions } from 'src/app/redux/createAction';
import { UserData } from 'src/pages/AuthPage/_BLL/types';
import { actionsAuth } from 'src/pages/AuthPage/_BLL/slice';

interface Props {
	userData: UserData;
}

export const HeaderProfile: FC<Props> = props => {
	const {
		userData, //
	} = props;

	// * Actions
	const { logout } = useActions(actionsAuth);

	const [open, setOpen] = useState<boolean>(false);

	// * Render
	return (
		<div className={s.container}>
			<div
				className={s.user}
				onClick={() => setOpen(!open)}
			>
				<span className={s.user__name}>{'displayName' in userData ? userData.displayName : 'User'}</span>
			</div>

			{open && (
				<div className={s.menu}>
					<ButtonSecondary onClick={() => logout()}>Logout</ButtonSecondary>
				</div>
			)}
		</div>
	);
};
