import cn from 'classnames';
import React, { FC } from 'react';
import s from './NivoChartWrapper.module.scss';

interface InnerProps {
	height?: string;
	width?: string;
}

interface Props extends InnerProps {
	className?: string;
	children?: React.ReactNode;
}

export const NivoChartWrapper: FC<Props> = props => {
	const {
		height = '400px', //
		width = '400px',
		children,
		className,
	} = props;

	// * Render
	return (
		<div className={cn(s.container, className)}>
			<div
				style={{
					height,
					width,
				}}
			>
				{children}
			</div>
		</div>
	);
};
