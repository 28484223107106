import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { actionsKPI } from 'src/pages/MainPage/_BLL/kpi/slice';
import { KpiProductivityTable } from '../cardsContent/table/KpiProductivityTable';
import { KpiTimeSpentTable } from '../cardsContent/table/KpiTimeSpentTable';
import { CompaniesDatapoints } from '../cardsContent/text/CompaniesDatapoints';
import { Database } from '../cardsContent/text/Database';
import { KpiTotal } from '../cardsContent/text/KpiTotal';
import { UrlKPI } from 'src/shared/api/types';
import { KpiContentCard } from 'src/shared/ui/KpiContentCard';
import s from './Kpi.module.scss';

export const Kpi = () => {
	// * Selectors
	const {
		kpiDatabase,
		kpiCompaniesDatapoints,
		kpiTotalByRegion,
		kpiTotalByIndustry,

		kpiProductivity24,
		kpiProductivityHistorical,

		kpiTimeSpent,

		kpiCompaniesRating,
		kpiPushedToGold,
		kpiAddedDatapoints,
		kpiNewCompanies,
		kpiLastEdited,
	} = useAppSelector(state => state.kpi);

	// * Actions
	const dispatch = useAppDispatch();
	const { initializeKpi } = actionsKPI;

	useEffect(() => {
		dispatch(initializeKpi());
	}, []);

	return (
		<div className={s.container}>
			<div className={s.row}>
				<KpiContentCard
					Content={<Database data={kpiDatabase.data} />}
					status={kpiDatabase.status}
				/>

				<KpiContentCard
					Content={<CompaniesDatapoints data={kpiCompaniesDatapoints.data} />}
					status={kpiCompaniesDatapoints.status}
				/>

				{kpiTotalByRegion.data && (
					<KpiContentCard
						Content={
							<KpiTotal
								title="Total by region"
								data={kpiTotalByRegion.data}
							/>
						}
						status={kpiTotalByRegion.status}
					/>
				)}

				{kpiTotalByIndustry.data && (
					<KpiContentCard
						Content={
							<KpiTotal
								title="Total by industry"
								data={kpiTotalByIndustry.data}
							/>
						}
						status={kpiTotalByIndustry.status}
					/>
				)}

				{/* <ContentCard
					Content={
						<CompaniesRatingContent
							data={kpiCompaniesRating.data}
						/>
					}
					status={kpiCompaniesRating.status}
				/> */}
			</div>

			<div className={s.row}>
				{kpiProductivity24.data && (
					<KpiContentCard
						Content={
							<KpiProductivityTable
								title="Data Board"
								data={kpiProductivity24.data}
								partUrl={UrlKPI.kpiProductivity24}
								dates={kpiProductivity24.dates}
							/>
						}
						status={kpiProductivity24.status}
					/>
				)}

				{kpiProductivityHistorical.data && (
					<KpiContentCard
						Content={
							<KpiProductivityTable
								title="Data Board Total"
								data={kpiProductivityHistorical.data}
							/>
						}
						status={kpiProductivityHistorical.status}
					/>
				)}
			</div>

			{kpiTimeSpent.data && (
				<div className={s.row}>
					<KpiContentCard
						Content={
							<KpiTimeSpentTable
								title="Time spent"
								data={kpiTimeSpent.data}
								partUrl={UrlKPI.kpiTimeSpent}
								dates={kpiTimeSpent.dates}
							/>
						}
						status={kpiTimeSpent.status}
					/>
				</div>
			)}
		</div>
	);
};
